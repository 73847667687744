import { Routes } from '@angular/router';

import { SbRootComponent } from '@valk-nx/storyblok/components/root/src/lib/root.component';

export const routes: Routes = [
	{
		path: '**',
		component: SbRootComponent,
	},
];
