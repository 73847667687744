import { CommonModule } from '@angular/common';
import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	inject,
	InjectionToken,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import { LetDirective } from '@ngrx/component';
import { DynamicModule } from 'ng-dynamic-component';
import {
	combineLatest,
	filter,
	map,
	Observable,
	of,
	shareReplay,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';

import { CookieManagerComponent } from '@valk-nx/compositions/ui-cookie-manager/src/lib/cookie-manager.component';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import {
	HOTEL_SLUG,
	LANGUAGE,
	Language,
	negativeHeader$,
} from '@valk-nx/core/lib/core';
import { BannerComponent as SbBannerComponent } from '@valk-nx/storyblok/components/banner/src/lib/banner';
import { FooterComponent as SbFooterComponent } from '@valk-nx/storyblok/components/footer/src/lib/footer';
import { HeaderComponent } from '@valk-nx/storyblok/components/header/src/lib/header';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { HotelStore } from '@valk-nx/storyblok-store/src/lib/store/hotel/hotel.store';
import { PageSettingsStore } from '@valk-nx/storyblok-store/src/lib/store/page-settings/page-settings.store';
import { StoryblokGlobals } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	banner: SbBannerComponent,
	footer: SbFooterComponent,
	header: HeaderComponent,
};

export const SHOW_COOKIE_BANNER = new InjectionToken<string>(
	'show-cookie-banner',
);

@Component({
	selector: 'sb-wrapper',
	templateUrl: './wrapper.component.html',
	imports: [
		CommonModule,
		DynamicModule,
		CookieManagerComponent,
		LetDirective,
	],
	providers: [HotelStore, PageSettingsStore],
})
export class SbWrapperComponent
	implements AfterViewInit, OnDestroy, OnInit, AfterViewChecked
{
	readonly hotelStore = inject(HotelStore);
	readonly pageSettingsStore = inject(PageSettingsStore);
	@ViewChild('headerRef', { static: false }) headerRef!: ElementRef;

	wrapper$!: Observable<{
		header: StoryblokGlobals['header'] & {
			translatedSlugs?: TranslatedSlug[];
		};
		banner: StoryblokGlobals['banner'];
		footer: StoryblokGlobals['footer'];
		language: Language;
	}>;
	components: typeof Components = Components;
	storyblokComponent = '';
	cookieTranslations = {
		nl: {
			extra: {
				url: '/cookies',
			},
		},
		ca: {
			extra: {
				url: '/cookies',
			},
		},
		en: {
			extra: {
				url: '/cookies',
			},
		},
		es: {
			extra: {
				url: '/cookies',
			},
		},
		de: {
			extra: {
				url: '/cookies',
			},
		},
		fr: {
			extra: {
				url: '/cookies',
			},
		},
	};

	isNegativeHeader = false;
	destroy$ = new Subject<void>();

	hideMenu: Observable<boolean>;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private readonly metadataService: MetadataService,
		private readonly router: Router,
		@Inject(HOTEL_SLUG)
		private readonly hotelSlug: string,
		private readonly renderer2: Renderer2,
		@Inject(SHOW_COOKIE_BANNER) readonly showCookieBanner: boolean,
		@Inject(LANGUAGE) private readonly language: Language,
	) {
		this.hideMenu = toObservable(this.pageSettingsStore.hideMenu);
	}

	ngOnInit() {
		this.wrapper$ = combineLatest([
			this.sbService.globals$,
			this.sbService.translatedSlugs$,
			this.hideMenu,
		]).pipe(
			takeUntil(this.destroy$),
			map(([sbGlobals, translatedSlugs, hideMenu]) => {
				const wrapper = {
					...sbGlobals,
				};
				wrapper.header['translatedSlugs'] = translatedSlugs;
				wrapper.header.hideMenu = hideMenu;

				wrapper.language = (
					translatedSlugs?.find((slug) => slug.selected)?.lang ||
					this.language
				).toLowerCase() as Language;
				return wrapper;
			}),
			switchMap((wrapper) => {
				if (this.hotelSlug) {
					return this.contentService
						.getHotelInfo(wrapper.language)
						.pipe(
							tap((data) => {
								const hotelData = data.body.data;
								this.metadataService.setSchemaOrgHotel(
									this.renderer2,
									hotelData,
								);

								this.hotelStore.updateState(hotelData);
							}),
							map(() => wrapper),
							shareReplay(1),
						);
				} else {
					return of(wrapper);
				}
			}),
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit() {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				const urlTree = this.router.parseUrl(this.router.url);
				const primarySegment =
					urlTree.root.children['primary']?.segments[0]?.path;

				if (primarySegment === this.language) {
					const newUrl = this.router.url.split(
						`/${this.language}`,
					)[1];
					this.router.navigate([newUrl]);
				}

				this.storyblokComponent = urlTree.queryParams['_storyblok_c'];
			});
	}

	ngAfterViewChecked() {
		negativeHeader$.pipe(take(1)).subscribe((isNegative) => {
			this.isNegativeHeader = isNegative;
			this.checkHeight();
		});
	}

	checkHeight() {
		if (this.isNegativeHeader) {
			this.headerRef?.nativeElement.classList.add(
				'md:absolute',
				'md:w-full',
			);
		} else {
			this.headerRef?.nativeElement.classList.remove(
				'md:absolute',
				'md:w-full',
			);
		}
	}
}
