<svg fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g transform="scale(2.5)">
		<text
			class="icon-fill-accent font-sans-serif font-light"
			fill="currentColor"
			font-size="100%"
			text-anchor="middle"
			x="20%"
			y="31%"
		>
			{{ score() | number: '1.1-1' : language }}
		</text>
	</g>
</svg>
