<div class="relative" data-test-id="mobile">
	<div
		#mobileHeaderEl
		[ngClass]="{ '!z-10': languageIsOpen }"
		class="relative z-40 flex flex-col border-b border-b-ui-100 bg-white px-4 py-3"
	>
		<div class="flex items-center justify-between sm:min-h-11">
			@if (!hideMenu) {
				<vp-link
					[link]="logoLink"
					(linkClick)="closeMobileMenu()"
					className="flex items-center transition-opacity duration-300 ease-out"
					data-test-id="header-logo"
					type="link-unstyled"
				>
					<ng-container [ngTemplateOutlet]="logo" />
				</vp-link>
				<div class="ml-auto flex gap-x-4">
					<button
						(click)="toggleMenu()"
						class="flex items-center text-sm uppercase"
						data-test-id="toggle-menu"
					>
						{{ 'compositions.header.menu' | translate }}
						<svg-icon
							[src]="
								menuIsOpen
									? 'assets/icons/icon-cross.svg'
									: 'assets/icons/icon-bars.svg'
							"
							class="ml-1 block size-4 text-dark-primary"
						/>
					</button>
				</div>
			} @else {
				<span class="flex items-center" data-test-id="header-logo">
					<ng-container [ngTemplateOutlet]="logo" />
				</span>
			}
		</div>
	</div>
	<div
		#mobileMenuEl
		[ngClass]="{
			block: menuIsOpen,
		}"
		class="fixed left-0 top-0 z-30 size-full -translate-y-full overflow-hidden overflow-y-auto bg-white md:left-1/2"
		data-test-id="mobile-menu"
	>
		<ul class="flex h-fit flex-col justify-center">
			@for (menuItem of menuItems; track menuItem) {
				<li
					class="flex size-full items-center gap-2 border-b border-ui-100 px-3 py-2.5 text-lg"
					data-test-class="menu-item"
				>
					@if (menuItem.icon) {
						<svg-icon
							[src]="menuItem.icon"
							class="ml-2 size-4 grow-0 cursor-pointer text-black"
						/>
					}
					<vp-link
						[link]="menuItem"
						(linkClick)="closeMobileMenu()"
						class="flex flex-1"
						className="w-full"
						type="link-unstyled"
					>
						{{ menuItem.title }}
					</vp-link>
				</li>
			}
		</ul>
	</div>
	@if (metaMenu) {
		<div
			#metaMenuEl
			class="fixed bottom-0 left-0 z-30 w-full translate-y-full overflow-hidden bg-white shadow-[rgba(0,0,0,0.1)_0px_0px_12px] md:left-[50%] md:w-1/2"
		>
			<div class="m-4">
				<div
					class="mb-4 flex w-full justify-center"
					data-test-id="meta-menu"
				>
					@if (metaMenu.logoutButton?.title) {
						<button
							class="flex w-full flex-1 flex-col items-center gap-1 p-0.5 text-sm leading-none"
						>
							<svg-icon
								[src]="metaMenu.logoutButton.icon"
								(click)="
									metaMenu.logoutButton.callback();
									closeMobileMenu()
								"
								class="block size-5 text-dark-primary"
							/>
							{{ metaMenu.logoutButton.title | translate }}
						</button>
					}
					@if (metaMenu.contactLink?.url) {
						<vp-link
							[link]="metaMenu.contactLink"
							(linkClick)="closeMobileMenu()"
							class="flex-1"
							className="flex w-full flex-col items-center gap-1 p-0.5 text-sm leading-none"
							type="link-unstyled"
						>
							<svg-icon
								class="block size-5 text-dark-primary"
								src="assets/icons/icon-phone-line.svg"
							/>
							{{ 'compositions.header.contact' | translate }}
						</vp-link>
					}
					@if (metaMenu.accountLink?.url) {
						<vp-link
							[link]="metaMenu.accountLink"
							(linkClick)="closeMobileMenu()"
							class="flex-1"
							className="flex w-full flex-col items-center gap-1 p-0.5 text-sm leading-none"
							type="link-unstyled"
						>
							<svg-icon
								class="block size-5 text-dark-primary"
								src="assets/icons/icon-person.svg"
							/>
							{{
								'compositions.header.account-mobile' | translate
							}}
						</vp-link>
					}
					@if (metaMenu.showSearch) {
						<button
							class="flex w-full flex-1 flex-col items-center gap-1 p-0.5 text-sm leading-none"
						>
							<svg-icon
								class="block size-5 text-dark-primary"
								src="assets/icons/icon-looking-glass.svg"
							/>
							{{ 'compositions.header.search' | translate }}
						</button>
					}
					@if (metaMenu.languages?.options) {
						<button
							(click)="toggleLanguageMenu()"
							class="flex w-full flex-1 flex-col items-center gap-1 p-0.5 text-sm leading-none"
							data-test-id="show-language-menu"
						>
							<svg-icon
								class="block size-5 text-dark-primary"
								src="assets/icons/icon-translate.svg"
							/>
							{{
								metaMenu.languages.selectedLanguageOption?.lang
							}}
						</button>
					}
				</div>
				@if (metaMenu.cta?.title && metaMenu.cta.url) {
					<vp-link
						[link]="metaMenu.cta"
						class="flex"
						className="button h-fit w-full justify-center"
						data-test-id="cta-button"
						type="button"
					>
						{{ metaMenu.cta.title }}
					</vp-link>
				}
			</div>
		</div>
	}
	@if (metaMenu?.languages) {
		<div
			#languageMenuEl
			[ngClass]="{
				hidden: !languageIsOpen,
				block: languageIsOpen,
			}"
			class="fixed bottom-0 left-0 z-30 w-full translate-y-full overflow-hidden rounded-t-md bg-white p-4 shadow-[rgba(0,0,0,0.1)_0px_0px_12px]"
			data-test-id="language-menu"
		>
			<div class="flex">
				<span class="text-xl font-semibold">
					{{ 'compositions.header.choose-language' | translate }}
				</span>
				<button
					(click)="toggleLanguageMenu()"
					class="ml-auto"
					data-test-id="close-language-menu"
				>
					<svg-icon
						class="block size-6 text-dark-primary"
						src="assets/icons/icon-cross.svg"
					/>
				</button>
			</div>
			<div class="mt-4 flex flex-col">
				@for (
					languageOption of metaMenu.languages.options;
					track languageOption
				) {
					<button
						(click)="switchLanguage(languageOption.value)"
						class="px-4 py-3 text-left text-dark-secondary active:bg-accent-50"
						data-test-class="language-option"
					>
						{{ languageOption.selectLabel }}
					</button>
				}
			</div>
		</div>
	}
</div>
<div
	[ngClass]="{ invisible: !menuIsOpen && !languageIsOpen }"
	class="overlay-dark-3 fixed left-0 top-0 z-20 size-full"
></div>

<ng-template #logo>
	<img
		alt="Van der Valk Logo"
		class="h-8 w-7"
		height="32"
		src="assets/icons/logo-valk-black.svg"
		width="28"
	/>
	<div
		class="ml-1 flex flex-col border-l pl-2 font-logo uppercase text-black"
	>
		<span
			[ngClass]="{
				'text-[8px] sm:text-base': title,
				'mt-1 text-base': !title,
			}"
			data-test-id="brand-name"
		>
			{{ 'global.brand-name' | translate }}
		</span>
		@if (title) {
			<span
				class="text-[15px] font-bold leading-4 sm:text-[22px]"
				data-test-id="title"
			>
				{{ title }}
			</span>
		}
	</div>
</ng-template>
