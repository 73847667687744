import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, switchMap } from 'rxjs';

import {
	AvailabilityWidgetComponent,
	WidgetDataActionInterface,
} from '@valk-nx/compositions/ui-availability-widget/src/lib/availability-widget.component';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import {
	fallbackLanguage,
	HOTEL_SLUG,
	LANGUAGE,
	Language,
} from '@valk-nx/core/lib/core';
import { ContentHotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';
import { HotelsService } from '@valk-nx/hotel-store/hotels.service';
import { CarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';
import { Carousel } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-carousel-with-availability-widget',
	templateUrl: './carousel-with-availability-widget.html',
	imports: [
		AsyncPipe,
		CarouselComponent,
		AvailabilityWidgetComponent,
		StoryblokDirective,
	],
})
export class CarouselWithAvailabilityWidgetComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() blocks: Carousel[] = [];
	@Input({ required: true }) hotelGUID!: string;

	carousel!: Carousel;
	hotel$: Observable<ContentHotelInterface>;
	translatedSlugs$: Observable<TranslatedSlug[]>;
	language: string = fallbackLanguage;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly hotelService: HotelsService,
		private readonly tagManagerFacade: TagManagerFacade,
		private readonly cd: ChangeDetectorRef,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
		@Inject(LANGUAGE) private readonly rootLanguage: Language,
	) {
		super();
		this.language = this.rootLanguage;
		this.translatedSlugs$ =
			this.sbService.translatedSlugs$.pipe(takeUntilDestroyed());
	}

	ngOnInit() {
		this.hotel$ = this.translatedSlugs$.pipe(
			switchMap((translatedSlug) => {
				this.language = (
					translatedSlug.find((x) => x.selected) || translatedSlug[0]
				).lang.toLowerCase();
				this.cd.detectChanges();
				return this.hotelService.getHotelBySlugData(
					this.language,
					this.hotelSlug,
				);
			}),
		);

		this.carousel = this.blocks.find((x) => x.component === 'carousel');
	}

	widgetCTA(eventData: {
		event: string;
		eventType: string;
		data: WidgetDataActionInterface;
	}) {
		this.tagManagerFacade.availabilityWidget({
			...eventData,
			data: { ...eventData.data, hotelSlug: this.hotelSlug },
		});
	}
}
