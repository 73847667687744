<div
	*ngrxLet="package$ | async as package"
	[editableInStoryblok]="_editable"
	class="size-full"
>
	@if (package) {
		<vp-persuade-card
			[content]="package?.bulletContent"
			[ctaText]="button[0].text"
			[ctaUrl]="button[0].link.cached_url"
			[currency]="package?.price?.currency || ''"
			[images]="package?.images || []"
			[overline]="'global.package-label' | translate"
			[price]="package?.price?.amount || 0"
			[pricingCondition]="
				(package?.price?.type === 'perPerson'
					? 'global.price-per-person'
					: 'global.total-price-label'
				) | translate
			"
			[title]="package?.name || ''"
			[useVerticalCard]="useVertical"
			imageVariant="mini-carousel"
		/>
	}
</div>
