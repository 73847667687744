import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[vc-clicked]',
	standalone: false,
})
export class ClickedDirective {
	@Output() clicked = new EventEmitter<void>();

	@HostListener('click')
	onClick() {
		this.clicked.emit();
		return false;
	}
}
