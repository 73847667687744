import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	InjectionToken,
	Optional,
	PLATFORM_ID,
	Renderer2,
} from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { ContentHotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { removeTrailingSlash } from '@valk-nx/storyblok-helpers/src/lib/general/sitemap.helper';
import {
	Link,
	PageMetaData,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

export interface MetaDataBatchInterface {
	meta: {
		description: string;
		title: string;
	};
	images: PhotoInterface[];
}

export const HOST: InjectionToken<string> = new InjectionToken<string>('host');

@Injectable({ providedIn: 'root' })
export class MetadataService {
	constructor(
		private readonly metaTagService: Meta,
		private readonly titleService: Title,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Optional() @Inject(HOST) readonly hostUrl: string,
		private readonly router: Router,
		@Inject(DOCUMENT) private readonly document: Document,
	) {
		if (isPlatformBrowser(this.platformId) && !this.hostUrl) {
			this.hostUrl = `${window.location.protocol}//${window.location.host}`;
		}
	}

	updateMetadata(metadata: PageMetaData): void {
		const metatags: MetaDefinition[] =
			this.generateMetaDefinitions(metadata);

		this.removeMetaDefinitions();
		this.metaTagService.addTags([
			...metatags,
			{
				property: 'og:url',
				content: `${this.hostUrl}${this.router.url}`,
			},
			{
				name: 'robots',
				content: this.generateRobots(
					metadata.robotsNoIndex,
					metadata.robotsNoFollow,
				),
			},
		]);

		this.titleService.setTitle(metadata.metaTitle);
	}

	removeMetaDefinitions(): void {
		this.metaTagService.removeTag("name='title'");
		this.metaTagService.removeTag("property='og:title'");
		this.metaTagService.removeTag("name='description'");
		this.metaTagService.removeTag("property='og:description'");
		this.metaTagService.removeTag("property='og:image'");
		this.metaTagService.removeTag("property='og:type'");
		this.metaTagService.removeTag("property='og:url'");
		this.metaTagService.removeTag("name='robots'");
	}

	generateMetaDefinitions(metadata: PageMetaData): MetaDefinition[] {
		return [
			{ name: 'title', content: metadata.metaTitle },
			{ property: 'og:title', content: metadata.openGraphTitle },
			{ name: 'description', content: metadata.metaDescription },
			{
				property: 'og:description',
				content: metadata.openGraphDescription,
			},
			{ name: 'og:image', content: metadata.openGraphImage?.file },
			{ property: 'og:type', content: metadata.openGraphType },
		];
	}

	generateRobots(noIndex: boolean, noFollow: boolean): string {
		let robots = noIndex ? 'noindex' : 'index';
		robots = noFollow ? `${robots}, nofollow` : `${robots}, follow`;
		return robots;
	}

	createLinkForCanonicalURL(canonical: Link | null) {
		let href = `${this.hostUrl}${this.router.url}`;

		if (canonical?.cached_url?.startsWith('http')) {
			href = canonical.cached_url;
		} else if (canonical?.cached_url && canonical?.id !== '') {
			const route = canonical.cached_url.startsWith('/')
				? canonical.cached_url
				: `/${canonical.cached_url}`;
			href = `${this.hostUrl}${route}`;
		}

		const existingLink: HTMLLinkElement | null =
			this.document.querySelector('link[rel="canonical"]');

		const link: HTMLLinkElement =
			existingLink || this.document.createElement('link');

		link.setAttribute('rel', 'canonical');

		this.document.head.appendChild(link);
		link.setAttribute('href', removeTrailingSlash(href));
	}

	setHrefLang(translatedSlugs: TranslatedSlug[], defaultLanguage: string) {
		const defaultSlug = {
			path: '',
			...translatedSlugs.find(
				(slug) =>
					slug.lang.toLowerCase() === defaultLanguage.toLowerCase(),
			),
			lang: 'x-default',
		};

		[...translatedSlugs, defaultSlug].forEach((translatedSlug) => {
			const path = translatedSlug.path.endsWith('home')
				? translatedSlug.path.replace(/\/?home$/, '')
				: translatedSlug.path;

			const headDomElements = Array.from(
				this.document.head.children,
			) as unknown as HTMLLinkElement[];
			const alternateLink = headDomElements.find(
				(child: HTMLLinkElement) =>
					child.rel == 'alternate' &&
					child.hreflang.toLowerCase() ==
						translatedSlug.lang.toLowerCase(),
			);

			const href = removeTrailingSlash(`${this.hostUrl}/${path}`);

			if (alternateLink) {
				alternateLink.setAttribute('href', href);
			} else {
				const link = this.document.createElement('link');
				link.setAttribute('rel', 'alternate');
				this.document.head.appendChild(link);
				link.setAttribute(
					'hreflang',
					translatedSlug.lang.toLowerCase(),
				);
				link.setAttribute('href', href);
			}
		});
	}

	updateMetaDataBatch(data: MetaDataBatchInterface) {
		this.updateMetadata({
			metaDescription: data.meta.description,
			metaTitle: data.meta.title,
			openGraphDescription: data.meta.description,
			openGraphTitle: data.meta.title,
			openGraphImage: {
				file: data.images[0]?.src,
				alt: data.images[0]?.alt || '',
			},
			openGraphType: 'website',
			robotsNoFollow: false,
			robotsNoIndex: false,
		});
	}

	setSchemaOrgHotel(renderer2: Renderer2, hotelData: ContentHotelInterface) {
		const homepage = ['/', '/en', '/de', '/es', '/fr', '/nl'];
		if (
			isPlatformServer(this.platformId) &&
			homepage.includes(this.router.url)
		) {
			const contact = hotelData.contacts.find(
				(contact) => contact.type === 'main',
			);

			const script = renderer2.createElement('script');
			script.type = `application/ld+json`;
			script.text = JSON.stringify({
				'@context': 'https://schema.org',
				'@type': ['Organization', 'LocalBusiness', 'Hotel'],
				'@id': `${this.hostUrl}/#organization`,
				name: hotelData.name,
				url: this.hostUrl,
				logo: `${this.hostUrl}/assets/images/application-icons/icon-512x512.png`,
				openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su 00:00 - 23:59',
				parentOrganization: {
					'@type': ['Brand', 'Organization'],
					'@id': 'https://www.valk.com/#organization',
					name: 'Van der Valk',
					url: 'https://www.valk.com',
					sameAs: [
						'https://g.co/kgs/kJaxLh',
						'https://www.wikidata.org/wiki/Q2802214',
						'https://nl.wikipedia.org/wiki/Van_der_Valk_(bedrijf)',
						'https://www.instagram.com/valkverrast',
						'https://www.facebook.com/VanderValkVerrast',
						'https://www.youtube.com/user/HotelsVanDerValk',
						'https://www.tiktok.com/@valkverrast',
						'https://nl.pinterest.com/vandervalkhotels/',
					],
					foundingDate: '1929',
				},
				contactPoint: {
					'@type': 'ContactPoint',
					contactType: 'Customer Service',
					telephone: contact?.phone,
					hoursAvailable: {
						'@type': 'OpeningHoursSpecification',
						opens: '00:00',
						closes: '23:59',
						dayOfWeek: [
							'Monday',
							'Tuesday',
							'Wednesday',
							'Thursday',
							'Friday',
							'Saturday',
							'Sunday',
						],
					},
				},
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${hotelData.address.street} ${hotelData.address.streetNumber}`,
					addressLocality: hotelData.address.city,
					postalCode: hotelData.address.postalCode,
					addressCountry: 'NL',
				},
				email: contact?.email,
				telephone: contact?.phone,
				brand: {
					'@id': 'https://www.valk.com/#organization',
				},
			});

			renderer2.appendChild(this.document.head, script);
		}
	}
}
