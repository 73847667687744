import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { Placement } from '@popperjs/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Observable } from 'rxjs';

import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget-input`,
	templateUrl: './availability-input.html',
	imports: [
		AngularSvgIconModule,
		ClickedModule,
		LetDirective,
		NgClass,
		PopoverModule,
	],
})
export class AvailabilityWidgetInputComponent {
	@Input() className = '';
	@Input() disabled = false;
	@Input() label = '';
	@Input() placeholder = '';
	@Input() value = '';
	@Input() iconName = '';
	@Input() isOpen = false;
	@Input() popoverAlignment: Placement = 'bottom-start';
	@Input() popoverOffset: [number, number] = [-0, 10];
	@Input() popoverPadding = false;
	@Input() inputName = '';
	@Input() header = '';
	@Input() excludeFromClosingRegex: RegExp | string;

	@Output() emitIsOpen = new EventEmitter<boolean>();

	isSmallTablet$: Observable<boolean>;

	constructor(private readonly viewport: ViewPortService) {
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
	}

	popoverStateChanged(isOpen: boolean) {
		this.isOpen = isOpen;
		this.emitIsOpen.emit(this.isOpen);
	}

	handleKeyDown(event: KeyboardEvent) {
		if (event.code === 'Space') {
			this.popoverStateChanged(!this.isOpen);
		}
	}
}
