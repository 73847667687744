<vp-newsletter
	[editableInStoryblok]="_editable"
	[newsletterSubmitError]="newsletterSubmitError"
	[newsletterSubmitting]="newsletterSubmitting"
	(formSubmit)="onSubmit($event)"
>
	<header class="text-center">
		<h3
			class="heading-group heading-group-section heading-group-lg font-display"
		>
			{{ title }}
		</h3>
		<p class="heading-group-overline" role="doc-subtitle">{{ overline }}</p>
	</header>
</vp-newsletter>
