<div
	[editableInStoryblok]="_editable"
	class="mx-auto flex max-w-screen-md flex-col gap-4"
>
	@for (item of items; track item) {
		<vp-accordion [title]="item.title" [variant]="'accent'" class="block">
			<sb-richtext [content]="item.content" />
		</vp-accordion>
	}
</div>
