import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { VideoComponent } from '@valk-nx/components/ui-video/src/lib/video';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-storytelling',
	templateUrl: './storytelling.html',
	imports: [CommonModule, SharedModule, ImageComponent, VideoComponent],
})
export class StorytellingComponent extends BaseDirective {
	@Input() image: PhotoInterface = { src: '' };
	@Input() video: { vimeoId: number; showControls: boolean };
	@Input() extraImage: PhotoInterface = { src: '' };
	@Input() position: 'left' | 'right' = 'left';
	@Input() isFullscreen = false;

	sizes = Sizes;
}
