import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { map, Observable, of, tap } from 'rxjs';

import { CookieManagerResponse, Status } from './cookie-manager.interface';

const COOKIE_MANAGER_SERVICE_URL =
	'https://cookiesmanager.vandervalkonline.com/cookiemanager.cfm?';

@Injectable({ providedIn: 'root' })
export class CookieManagerService {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly http: HttpClient,
	) {}

	setCookie(
		cookieName: string,
		cookieValue: Status,
	): Observable<CookieManagerResponse> {
		if (isPlatformBrowser(this.platformId)) {
			const url = `${COOKIE_MANAGER_SERVICE_URL}setCookieName=${cookieName}&setCookieValue=${cookieValue}`;
			this.setLocalCookie(cookieName, cookieValue);

			return this.http.jsonp<CookieManagerResponse>(url, 'callback');
		}
		return of({ succes: true, cookiestatus: Status.ACCEPTED });
	}

	getCookie(cookieName: string): Observable<Status> {
		if (isPlatformBrowser(this.platformId)) {
			const url = `${COOKIE_MANAGER_SERVICE_URL}getCookie=${cookieName}`;
			return this.http.jsonp<CookieManagerResponse>(url, 'callback').pipe(
				tap((response) => {
					if (response.cookiestatus !== Status.EMTPY) {
						this.setLocalCookie(cookieName, response.cookiestatus);
					}
				}),
				map((response) => {
					return response.cookiestatus;
				}),
			);
		}
		return of(Status.ACCEPTED);
	}

	getLocalCookie(name: string): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const regex = new RegExp(`(^| )${name}=([^;]+)`);
			const match = RegExp(regex).exec(this.document.cookie);
			return !!match;
		}
		return false;
	}

	getLocalCookieValueAsBoolean(name: string): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const regex = new RegExp(`(^| )${name}=([^;]+)`);
			const match = RegExp(regex).exec(this.document.cookie);
			if (match) return match[2] === Status.ACCEPTED;
		}
		return false;
	}

	private setLocalCookie(cookieName: string, cookieValue: Status): void {
		if (isPlatformBrowser(this.platformId)) {
			this.document.cookie = `${cookieName}=${cookieValue}`;
		}
	}
}
