import { DOCUMENT, isPlatformBrowser, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	PLATFORM_ID,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest, Observable } from 'rxjs';

import { CheckboxComponent } from '@valk-nx/components/ui-checkbox/src/lib/checkbox';
import { ModalComponent } from '@valk-nx/components/ui-modal/src/lib/modal';
import { LANGUAGE, Language } from '@valk-nx/core/lib/core';
import { LanguageSwitchHelper } from '@valk-nx/helpers/lib/language/language-switch';
import { CookieManagerModule } from '@valk-nx/services/cookie-manager/src';
import { Status } from '@valk-nx/services/cookie-manager/src/lib/cookie-manager.interface';
import { CookieManagerService } from '@valk-nx/services/cookie-manager/src/lib/cookie-manager.service';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

import {
	CookieManagerTranslation,
	defaultTranslations,
} from './default-translations';

interface EmittedValuesInterface {
	functional: boolean;
	analytics: boolean;
	marketing: boolean;
}

interface Cookie {
	cookieName: string;
	cookieValue: Status;
	dataLayerName: string;
}

const Functional = {
	COOKIE: 'valk-functional-cookie',
	DATA_LAYER: 'functionalCookies',
} as const;

const Analytics = {
	COOKIE: 'valk-analytics-cookie',
	DATA_LAYER: 'analyticsCookies',
} as const;

const Marketing = {
	COOKIE: 'valk-marketing-cookie',
	DATA_LAYER: 'marketingCookies',
} as const;

const cookies = (cookieValues: object): Cookie[] => [
	{
		cookieName: Functional.COOKIE,
		cookieValue: Status.ACCEPTED,
		dataLayerName: Functional.DATA_LAYER,
	},
	{
		cookieName: Analytics.COOKIE,
		cookieValue: cookieValues['analytics']
			? Status.ACCEPTED
			: Status.DECLINED,
		dataLayerName: Analytics.DATA_LAYER,
	},
	{
		cookieName: Marketing.COOKIE,
		cookieValue: cookieValues['marketing']
			? Status.ACCEPTED
			: Status.DECLINED,
		dataLayerName: Marketing.DATA_LAYER,
	},
];

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'vp-cookie-manager',
	imports: [
		CheckboxComponent,
		NgTemplateOutlet,
		CookieManagerModule,
		ModalComponent,
	],
	providers: [CookieManagerService],
	templateUrl: './cookie-manager.component.html',
	styleUrls: ['./cookie-manager.component.scss'],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class CookieManagerComponent implements OnChanges, OnInit {
	@Input() translations = {};
	@Input({ required: true }) language: Language;

	@Output() cookiesAccept = new EventEmitter<EmittedValuesInterface>();
	@Output() cookiesAcceptAll = new EventEmitter<EmittedValuesInterface>();
	@Output() cookiesSave = new EventEmitter<EmittedValuesInterface>();
	showModal = false;
	showMoreInfo = false;
	cookieValues = {
		analytics: true,
		functional: true,
		marketing: false,
	};
	cookieDetails = {
		analytics: false,
		functional: false,
		marketing: false,
	};
	mergedTranslations: CookieManagerTranslation;

	private readonly window?: Window;

	activateRouteSubscription: Observable<unknown>;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Inject(LANGUAGE) private readonly rootLanguage: Language,
		private readonly cookieManagerService: CookieManagerService,
		public readonly cd: ChangeDetectorRef,
		private readonly viewport: ViewPortService,
	) {
		this.mergedTranslations = defaultTranslations[this.rootLanguage];
		if (isPlatformBrowser(this.platformId)) {
			this.window = this.document.defaultView;
		}

		this.viewport.isMobile$
			.pipe(takeUntilDestroyed())
			.subscribe((isMobile) => {
				if (!isMobile) {
					this.cookieDetails = {
						analytics: true,
						functional: true,
						marketing: true,
					};
				} else {
					this.cookieDetails = {
						analytics: false,
						functional: false,
						marketing: false,
					};
				}
			});

		if (this.cookieManagerService.getLocalCookie(Functional.COOKIE)) {
			this.setCookieValue(
				'marketing',
				this.cookieManagerService.getLocalCookieValueAsBoolean(
					Marketing.COOKIE,
				),
			);
			this.setCookieValue(
				'analytics',
				this.cookieManagerService.getLocalCookieValueAsBoolean(
					Analytics.COOKIE,
				),
			);
		} else {
			this.initializeCookies();
		}
	}

	ngOnChanges() {
		const language = this.language?.toLowerCase();

		if (LanguageSwitchHelper.isLanguageCode(language)) {
			this.mergedTranslations = {
				...defaultTranslations[language],
				...this.translations[language],
				extra: {
					...defaultTranslations[language]?.extra,
					...this.translations[language]?.extra,
				},
				buttons: {
					...defaultTranslations[language]?.buttons,
					...this.translations[language]?.buttons,
				},
				links: {
					...defaultTranslations[language]?.links,
					...this.translations[language]?.links,
				},
				cookies: {
					...defaultTranslations[language]?.cookies,
					...this.translations[language]?.cookies,
					analytics: {
						...defaultTranslations[language]?.cookies?.analytics,
						...this.translations[language]?.cookies?.analytics,
					},
					functional: {
						...defaultTranslations[language]?.cookies?.functional,
						...this.translations[language]?.cookies?.functional,
					},
					marketing: {
						...defaultTranslations[language]?.cookies?.marketing,
						...this.translations[language]?.cookies?.marketing,
					},
				},
			};
			this.cd.detectChanges();
		}
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.locationHashChanged();
			this.window.addEventListener(
				'hashchange',
				// istanbul ignore next
				() => this.locationHashChanged(),
			);
		}
	}

	locationHashChanged() {
		if (this.window.location.hash === '#showcookies') {
			this.showModal = true;
			this.showMoreInfo = true;
			this.cd.detectChanges();
		}
	}

	initializeCookies(): void {
		if (isPlatformBrowser(this.platformId)) {
			combineLatest([
				this.cookieManagerService.getCookie(Functional.COOKIE),
				this.cookieManagerService.getCookie(Analytics.COOKIE),
				this.cookieManagerService.getCookie(Marketing.COOKIE),
			]).subscribe({
				next: ([
					functionalCookieStatus,
					analyticsCookieStatus,
					marketingCookieStatus,
				]) => {
					this.showModal =
						functionalCookieStatus === Status.EMTPY ||
						analyticsCookieStatus === Status.EMTPY ||
						marketingCookieStatus === Status.EMTPY;

					this.determineAcceptedOrNot(
						Functional.DATA_LAYER,
						functionalCookieStatus,
					);
					this.determineAcceptedOrNot(
						Analytics.DATA_LAYER,
						analyticsCookieStatus,
					);
					this.determineAcceptedOrNot(
						Marketing.DATA_LAYER,
						marketingCookieStatus,
					);

					this.cd.detectChanges();
				},
				error: () => {
					this.showModal = true;
					this.cd.detectChanges();
				},
			});
		}
	}

	toggleMoreInfo(): void {
		this.showMoreInfo = !this.showMoreInfo;
	}

	setCookieValue(cookie: string, value: boolean): void {
		this.cookieValues[cookie] = value;
	}

	toggleCookieDetails(type: keyof EmittedValuesInterface): void {
		this.cookieDetails[type] = !this.cookieDetails[type];
	}

	accept(): void {
		this.cookieValues['analytics'] = true;
		this.cookieValues['marketing'] = true;
		this.cookiesAccept.emit({
			functional: true,
			analytics: this.cookieValues['analytics'],
			marketing: this.cookieValues['marketing'],
		});
		this.showModal = false;

		this.setCookies(cookies(this.cookieValues));
	}

	acceptAll(): void {
		this.cookieValues['analytics'] = true;
		this.cookieValues['marketing'] = true;
		this.cookiesAcceptAll.emit({
			functional: true,
			analytics: this.cookieValues['analytics'],
			marketing: this.cookieValues['marketing'],
		});
		this.showModal = false;

		this.setCookies(cookies(this.cookieValues));
	}

	save(): void {
		this.cookiesSave.emit({
			functional: this.cookieValues['functional'],
			analytics: this.cookieValues['analytics'],
			marketing: this.cookieValues['marketing'],
		});
		this.showModal = false;

		this.setCookies(cookies(this.cookieValues));
	}

	modalClosed(): void {
		if (this.showModal) {
			this.showModal = false;
			this.removeHash();
		}
		this.cd.detectChanges();
	}

	private addValueToDataLayer(value: string): void {
		if (
			this.window !== undefined &&
			isPlatformBrowser(this.platformId) &&
			this.window['dataLayer'] &&
			Array.isArray(this.window['dataLayer'])
		) {
			this.window['dataLayer'].push({ event: value });
		}
	}

	private setCookies(cookies: Cookie[]): void {
		if (isPlatformBrowser(this.platformId)) {
			combineLatest(
				cookies.map((cookie) =>
					this.cookieManagerService.setCookie(
						cookie.cookieName,
						cookie.cookieValue,
					),
				),
			).subscribe({
				next: (resultCookies) => {
					resultCookies.forEach((result, i) => {
						this.determineAcceptedOrNot(
							cookies[i].dataLayerName,
							result.cookiestatus,
						);
					});
				},
			});
			this.removeHash();
		}
	}

	private determineAcceptedOrNot(
		cookieDataLayerName: string,
		cookieStatus: Status,
	): void {
		if (cookieStatus === Status.ACCEPTED) {
			this.addValueToDataLayer(`${cookieDataLayerName}Accepted`);
		} else if (cookieStatus === Status.DECLINED) {
			this.addValueToDataLayer(`${cookieDataLayerName}NotAccepted`);
		}
	}

	removeHash() {
		this.window.location.hash = '';
	}
}
