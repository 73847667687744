<div class="flex flex-wrap gap-x-2">
	@if (maxNumberOfGuests) {
		<div class="flex items-center sm:text-lg" data-test-id="max-persons">
			<svg-icon
				[svgAriaLabel]="
					'components.room-card.max-persons'
						| translate: { amount: maxNumberOfGuests }
				"
				class="mr-1 size-3 grow-0 text-dark-primary sm:size-4"
				src="assets/icons/icon-person.svg"
			/>
			{{
				'components.room-card.max-persons'
					| translate: { amount: maxNumberOfGuests }
			}}
		</div>
	}
	@if (roomSurface) {
		<div class="flex items-center sm:text-lg" data-test-id="surface">
			<svg-icon
				[svgAriaLabel]="roomSurface / 10000 + 'm²'"
				class="mr-1 size-3 grow-0 text-dark-primary sm:size-4"
				src="assets/icons/icon-surface-area.svg"
			/>
			{{ roomSurface / 10000 }}m²
		</div>
	}
	@if (
		facilities?.sleepingComfort && facilities.sleepingComfort[0];
		as sleepingComfort
	) {
		<div
			class="flex items-center sm:text-lg"
			data-test-id="sleeping-comfort"
		>
			<svg-icon
				[svgAriaLabel]="sleepingComfort"
				class="mr-1 size-3 grow-0 text-dark-primary sm:size-4"
				src="assets/icons/icon-bed-double.svg"
			/>
			{{ sleepingComfort }}
		</div>
	}
	@if (facilities?.bathroom && facilities.bathroom[0]; as bathroom) {
		<div
			class="flex items-center text-dark-primary sm:text-lg"
			data-test-id="bathroom"
		>
			<svg-icon
				[svgAriaLabel]="bathroom"
				class="mr-1 size-3 grow-0 text-dark-primary sm:size-4"
				src="assets/icons/icon-shower-bath.svg"
			/>
			{{ bathroom }}
		</div>
	}
</div>

@if (
	showMoreInformationAccordion && facilities && facilities.misc.length >= 1
) {
	<button
		[attr.aria-controls]="'more-facilities-' + roomIndex"
		[attr.aria-expanded]="showAdditonalInformation"
		[attr.data-test-id]="'more-information-button-' + roomIndex"
		(click)="toggleAdditionalInformation()"
		class="text-link mt-2 flex items-center text-base no-underline sm:text-lg"
	>
		@if (!showAdditonalInformation) {
			{{ 'global.more-information' | translate }}
		} @else {
			{{ 'components.room-card.hide-information' | translate }}
		}
		<svg-icon
			[src]="
				showAdditonalInformation
					? 'assets/icons/icon-chevron-up.svg'
					: 'assets/icons/icon-chevron-down.svg'
			"
			class="text-link ml-1 inline-block size-5"
		/>
	</button>
	@if (showAdditonalInformation && allFacilities) {
		<div
			[attr.data-test-id]="'more-facilities-' + roomIndex"
			[id]="'more-facilities-' + roomIndex"
			class="mt-3"
		>
			<ul>
				@for (item of allFacilities; track item) {
					<li
						class="inline-block text-base text-dark-secondary after:mr-1 after:inline-block after:content-['•'] last:after:hidden sm:text-lg"
					>
						{{ item }}
					</li>
				}
			</ul>
		</div>
	}
}
