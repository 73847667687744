<header
	[class]="alignment() === 'center' ? 'text-center' : 'text-left'"
	[editableInStoryblok]="_editable"
	class="mx-auto max-w-screen-md"
>
	<div
		[class]="headingSize"
		[heading]="headingType"
		[ngClass]="{ 'heading-group-section font-display': isSection }"
		vc-heading
	>
		<sb-richtext
			[content]="title"
			[richTextAlignment]="
				alignment() === 'center' ? 'text-center' : 'text-left'
			"
		/>
	</div>
	<p class="heading-group-overline" role="doc-subtitle">
		{{ overline }}
	</p>
</header>
