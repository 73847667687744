import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import { SheetModule } from '@valk-nx/components/ui-sheet/src';
import { TooltipModule } from '@valk-nx/components/ui-tooltip/src';
import { ReceiptSummaryItemComponent } from '@valk-nx/compositions/ui-receipt-summary/src/lib/components/item/item.component';
import {
	Item,
	ItemWithExtras,
	SpecifiedTaxes,
} from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.interface';
import {
	isItemWithExtras,
	isTaxSpecified,
} from '@valk-nx/compositions/ui-receipt-summary/src/lib/type-guards/type-guards';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';
import { PricePipe } from '@valk-nx/core/lib/pipes/price/price.pipe';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-receipt-summary`,
	templateUrl: './receipt-summary.component.html',
	imports: [
		AngularSvgIconModule,
		CommonModule,
		SharedModule,
		TooltipModule,
		SheetModule,
		ReceiptSummaryItemComponent,
		PricePipe,
		MarkdownModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReceiptSummaryComponent implements OnChanges {
	@Input({ required: true }) receiptDescription: string;
	@Input({ required: true }) currency: string;
	@Input() itemsDescription = '';
	@Input() items: (Item | ItemWithExtras)[] = [];
	@Input() tax: SpecifiedTaxes | number = 0;
	@Input() subTotal = 0;
	@Input() alreadyPaid = 0;
	@Input() loyalToPay = 0;
	@Input() total: number | null = null;
	@Input() totalVat = 0;
	@Input() amountToPay: number | null = null;
	@Input() toComply: number | null = null;
	@Input() hasConditions = false;
	@Input() conditions = '';
	@Input() loyalToEarn = 0;
	@Input() payOnArrival = false;
	@Input() paymentOption: undefined | string;
	@Input() showLoyal = true;

	@Output() getConditionsEmit = new EventEmitter<boolean>();

	hasExtras = false;
	specifiedTaxes?: SpecifiedTaxes;
	unspecifiedTaxes?: number;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['items']) {
			this.hasExtras = this.items.some(isItemWithExtras);
		}
		if (changes['tax']) {
			if (isTaxSpecified(this.tax)) {
				this.specifiedTaxes = this.tax;
				this.unspecifiedTaxes = undefined;
			} else {
				this.specifiedTaxes = undefined;
				this.unspecifiedTaxes = this.tax;
			}
		}
	}

	getConditions() {
		this.getConditionsEmit.emit(true);
	}
}
