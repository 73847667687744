import { Directive, Input } from '@angular/core';

import { cn } from '@valk-nx/core/lib/core';

@Directive({
	selector: '[sbRoot]',
	standalone: false,
})
export class StoryblokRootDirective {
	@Input() _editable!: string;
	@Input() _uid!: string;
	@Input() component!: string;

	cn = cn;
}
