import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-masonry`,
	templateUrl: './masonry.component.html',
	imports: [CommonModule, ImageComponent],
})
export class MasonryComponent {
	@Input() images: PhotoInterface[];

	sizes = Sizes;
}
