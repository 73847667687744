import { InjectionToken } from '@angular/core';

export interface Config {
	bookingToolUrl: string;
	defaultLanguage?: string;
	environment: string;
	gtmCode: string;
	host: string;
	hotelSlug: string;
	mapsKey: string;
	pollingTime: number;
	recaptchaKey: string;
	recaptchaV2Key: string;
	storyblokAccessToken: string;
	storyblokEndpoint?: string;
	storyblokSpaceId: number;
	theme: string;
}

export const APP_CONFIG: InjectionToken<Config> = new InjectionToken<Config>(
	'Application Config',
);
