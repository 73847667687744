<dialog
	#dialog
	[ngClass]="{
		hidden: !showingModal,
		'max-w-3xl md:mx-auto': !isMaxWScreenLG,
		'max-w-screen-lg lg:mx-auto': isMaxWScreenLG,
	}"
	class="card mx-3 max-h-[calc(100vh-80px)] p-0 text-dark-primary backdrop:overlay-dark-3 open:animate-fade-in md:max-h-[calc(100vh-120px)] 2xl:max-h-[calc(100vh-160px)]"
>
	<div class="flex flex-col gap-4 p-4 md:gap-6 md:p-6">
		@if (title) {
			<div class="flex items-start">
				<span
					class="w-full text-xl md:text-2xl"
					data-test-id="modal-title"
					>{{ title }}</span
				>
				@if (closeButton) {
					<svg-icon
						(click)="closeModal()"
						class="size-6 grow-0 cursor-pointer text-black"
						src="assets/icons/icon-cross.svg"
					/>
				}
			</div>
			<hr class="divider -mx-4 md:-mx-6" />
		}
		<div class="overflow-auto children:m-0">
			<ng-content />
		</div>
		<div class="flex flex-col-reverse">
			<div #footer class="peer mt-4 empty:hidden md:mt-6">
				<ng-content select="[footer]" />
			</div>
			<hr class="divider -mx-4 peer-empty:hidden md:-mx-6" />
		</div>
	</div>
</dialog>
