import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

import { ISbRichtext } from '@storyblok/js';

import { BannerComponent as UIBanner } from '@valk-nx/components/ui-banner/src/lib/banner';
import { BannerVariants } from '@valk-nx/components/ui-banner/src/lib/banner.interface';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-banner',
	templateUrl: './banner.html',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [UIBanner, RichTextComponent, StoryblokDirective],
})
export class BannerComponent extends StoryblokRootDirective {
	@Input() variant: BannerVariants = 'accent';
	@Input() content!: ISbRichtext | string;
	@Input() title!: string;
}
