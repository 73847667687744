<section [editableInStoryblok]="_editable" data-test-class="masonry-section">
	<div class="section-spacing">
		<div class="section-inner section-gap-tight">
			<sb-columns [colOne]="titleWithFigures" component="columnOne" />
			@if (button?.length > 0) {
				<sb-anchor
					[link]="button[0].link"
					[text]="button[0].text"
					class="mx-auto"
					type="secondary"
				/>
			}
		</div>
	</div>
	<div class="section-spacing pt-0">
		<div class="section-inner section-gap">
			<vp-masonry [images]="vpImages" />
		</div>
	</div>
</section>
