import { bootstrapApplication } from '@angular/platform-browser';

import { APP_CONFIG } from '@valk-nx/content/app/config/config';
import { environment } from '@valk-nx/content/environments/environment';
import { getContentConfigurationKey } from '@valk-nx/core/lib/ssr/ssr.helper';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

const key = getContentConfigurationKey(window.location.host);
const apiUrl = environment.apiUrl;

fetch(`${apiUrl}content-config?host=${key}`)
	.then((resp) => resp.json())
	.then((config) => {
		const clientAppConfig = appConfig;
		clientAppConfig.providers.push({
			provide: APP_CONFIG,
			useValue: config.data,
		});

		return bootstrapApplication(AppComponent, clientAppConfig);
	})
	.catch((err) => console.error(err));
