import {
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	input,
} from '@angular/core';

import { MapComponent as UIMap } from '@valk-nx/components/ui-map/src/lib/map';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { HotelStore } from '@valk-nx/storyblok-store/src/lib/store/hotel/hotel.store';

@Component({
	selector: 'sb-map',
	templateUrl: './map.html',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [UIMap, StoryblokDirective],
	host: { class: 'size-full' },
})
export class MapComponent extends StoryblokRootDirective {
	readonly hotelStore = inject(HotelStore);

	long = input<number | undefined>(undefined);
	lat = input<number | undefined>(undefined);

	longitude = computed(
		() => this.long() || this.hotelStore.address().longitude,
	);
	latitude = computed(() => this.lat() || this.hotelStore.address().latitude);
}
