import { CommonModule } from '@angular/common';
import { Component, Input, numberAttribute, OnInit } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import dayjs from 'dayjs';
import { map, Observable } from 'rxjs';

import {
	BookingWidgetComponent as vpBookingWidgetComponent,
	WidgetData,
} from '@valk-nx/compositions/ui-booking-widget/src/lib/booking-widget.component';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokParamsService } from '@valk-nx/storyblok-services/src/lib/services/storyblok-params.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

@Component({
	selector: 'sb-booking-widget',
	templateUrl: './booking-widget.html',
	imports: [vpBookingWidgetComponent, CommonModule, LetDirective],
})
export class BookingWidgetComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() calendarType: 'stepper' | 'range' = 'stepper';
	@Input({ required: true }) hotelGUID!: string;
	@Input() dealGUID!: string;
	@Input({ required: true, transform: numberAttribute })
	defaultAdults!: number;
	@Input({ required: true, transform: numberAttribute }) minAdults!: number;
	@Input({ required: true, transform: numberAttribute }) maxAdults!: number;
	@Input({ required: true, transform: numberAttribute }) minChildren!: number;
	@Input({ required: true, transform: numberAttribute }) maxChildren!: number;
	@Input({ required: true, transform: numberAttribute }) minInfants!: number;
	@Input({ required: true, transform: numberAttribute }) maxInfants!: number;
	@Input({ required: true, transform: numberAttribute }) minNights!: number;
	@Input({ required: true, transform: numberAttribute }) maxNights!: number;
	@Input() showPrices = true;
	@Input() currency = 'EUR';
	@Input() bookingtoolUrl!: string;
	@Input() hotelCode!: string;
	@Input() rateCode!: string;

	@Input({ transform: toDate }) maxDate = dayjs().add(1, 'year').toDate();

	@Input({ required: true, transform: toDate }) minDate = dayjs().toDate();
	@Input({ required: true, transform: toDate }) validFrom!: Date;
	@Input({ required: true, transform: toDate }) validUntil!: Date;

	initialNumberOfNights$!: Observable<string>;
	initialArrivalDate$!: Observable<string | undefined>;
	initialOccupancy$!: Observable<OccupancyInterface[] | undefined>;
	isMobile$: Observable<boolean>;

	constructor(
		private readonly tagManagerFacade: TagManagerFacade,
		private readonly storyblokParamsService: StoryblokParamsService,
		private readonly viewport: ViewPortService,
	) {
		super();
		this.isMobile$ = this.viewport.isMobile$;
	}

	ngOnInit() {
		this.initialNumberOfNights$ =
			this.storyblokParamsService.getFromUrlParam('nights');
		this.initialArrivalDate$ =
			this.storyblokParamsService.getFromUrlParam('startDate');
		this.initialOccupancy$ = this.storyblokParamsService
			.getFromUrlParam('occupancy')
			.pipe(
				map((occupancy) => {
					return GeneralHelper.parseJsonObject<OccupancyInterface[]>(
						occupancy,
					);
				}),
			);
	}

	onBookingWidgetButtonClicked(event: WidgetData) {
		this.tagManagerFacade.dealsShowAvailability(
			event,
			this.hotelCode,
			this.rateCode,
		);
	}
}
