import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ApmModule } from '@elastic/apm-rum-angular';
import { TranslateService } from '@ngx-translate/core';

import { LANGUAGE } from '@valk-nx/core/lib/core';
import { SbWrapperComponent } from '@valk-nx/storyblok/components/wrapper/src/lib/wrapper.component';

@Component({
	selector: 'valk-content-root',
	templateUrl: './app.component.html',
	imports: [RouterOutlet, ApmModule, CommonModule, SbWrapperComponent],
	providers: [],
})
export class AppComponent {
	constructor(
		private readonly translate: TranslateService,
		@Inject(LANGUAGE) private readonly language: string,
	) {
		this.translate.setDefaultLang(this.language);
		this.translate.use(this.language);
	}
}
