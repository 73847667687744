/* istanbul ignore file */
import {
	ChangeDetectionStrategy,
	Component,
	input,
	ViewEncapsulation,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';

import { ItemSlide } from '../items-slider.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [AngularSvgIconModule, ImageComponent, LinkComponent],
	selector: 'vp-item-slide',
	templateUrl: './item-slide.html',
})
export class ItemSlideComponent {
	item = input<ItemSlide>({
		title: '',
		image: { src: '', alt: '' },
		link: {
			url: '',
			title: '',
			target: '',
		},
	});

	priority = input<boolean>(false);

	sizes = Sizes;
}
