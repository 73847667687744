import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

import { AccordionComponent as UIAccordion } from '@valk-nx/components/ui-accordion/src/lib/accordion';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-accordion',
	templateUrl: './accordion.html',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [UIAccordion, RichTextComponent, StoryblokDirective],
	host: { class: 'w-full' },
})
export class AccordionComponent extends StoryblokRootDirective {
	@Input() items: { title: string; content: string }[] = [];
}
