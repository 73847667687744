/* stylelint-disable selector-max-compound-selectors */
@use 'flatpickr/dist/flatpickr.css';

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.flatpickr-calendar {
  @apply shadow-none min-w-full max-w-full m-0 mx-auto;

  @media (width >= 768px) {
    width: min-content !important;
    min-width: min-content !important;
    max-width: min-content !important;
  }

  /* stylelint-disable selector-class-pattern */
  .flatpickr-innerContainer {
    @apply relative;

    .loading-container {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 bg-white border border-ui-100 rounded-full shadow-lg;

      .loading-svg {
        @apply w-full h-full content-empty pointer-events-none bg-no-repeat bg-center bg-[length:2.5rem_2.5rem] bg-select-100;

        animation: 1350ms loading-spinner infinite linear;
        mask-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-spinner.svg');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 2.5rem 2.5rem;
      }
    }
  }

  .flatpickr-rContainer {
    @apply max-w-full;
  }

  .flatpickr-rContainer,
  .flatpickr-days {
    @apply min-w-full w-full #{!important};
  }

  .flatpickr-months {
    @apply h-10 mb-4;

    .flatpickr-month {
      @apply h-10;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      @apply p-0 flex flex-wrap w-10 h-10 content-center justify-center;
    }

    .flatpickr-current-month {
      @apply h-10 flex flex-wrap content-center justify-center p-0;

      .numInputWrapper,
      .cur-month {
        @apply font-sans-serif font-normal text-lg text-dark-primary capitalize;

        .cur-year {
          @apply font-sans-serif font-normal text-lg text-dark-primary;
        }

        .arrowUp,
        .arrowDown {
          @apply hidden;
        }
      }

      .numInputWrapper:hover {
        @apply bg-white;
      }
    }
  }

  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    @apply rounded-none;
  }


  .dayContainer {
    @apply w-1/2 max-w-1/2 min-w-[244px];

    @media (width >= 334px) {
      @apply min-w-[288px]; }

    &:only-of-type {
      @apply w-full max-w-full;
    }
  }

  .flatpickr-weekdays,
  .flatpickr-days {
    @apply gap-8;

    .flatpickr-weekdaycontainer {
      .flatpickr-weekday {
        @apply h-6 text-dark-primary text-xs font-normal capitalize;
      }
    }
  }

  &.is-loading {
    @apply relative;

    .flatpickr-days {
      @apply opacity-30;

      &::before {
        @apply absolute w-full h-full top-0 left-0 content-empty z-50;
      }
    }
  }

  .flatpickr-day.flatpickr-disabled {
    &:not(.endRange) {
      @apply bg-white text-dark-tertiary text-opacity-[50] opacity-50;
    }

    &.startRange {
      @apply opacity-100;
    }

  }

  .flatpickr-day {
    @apply flex flex-wrap items-center h-10 max-w-full bg-ui-25 rounded-none basis-[calc(14.2857%)] border-white border m-0;

    .flatpickr-day__date {
      @apply text-dark-tertiary text-xs;
    }

    .flatpickr-day__price {
      @apply text-dark-primary text-xs md:text-sm;
    }
  }

  .endRange,
  .inRange:not(.startRange) {
    .flatpickr-day__price {
      @apply invisible;
    }
  }

  .flatpickr-day.inRange,
  .flatpickr-day.selected.startRange + .endRange,
  .dayContainer + .dayContainer {
    @apply shadow-none;
  }

  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay {
    @apply bg-[#FCFCFC] text-dark-tertiary;

    .flatpickr-day__price {
      @apply text-dark-tertiary;
    }
  }

  .flatpickr-day:hover,
  .flatpickr-day.inRange,
  .flatpickr-day.inRange.flatpickr-disabled,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.prevMonthDay.inRange.flatpickr-disabled,
  .flatpickr-day.prevMonthDay:hover:not(.flatpickr-disabled),
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange.flatpickr-disabled,
  .flatpickr-day.nextMonthDay:hover:not(.flatpickr-disabled) {
    @apply bg-select-50 opacity-100 border-white;
  }


  .flatpickr-day.selected.startRange,
  .flatpickr-day.inRange,
  .flatpickr-day.inRange.flatpickr-disabled {
    @apply basis-[14.2857%];
  }

  .flatpickr-day.selected,
  .flatpickr-day.selected.flatpickr-day.flatpickr-disabled,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.selected.startRange:hover,
  .flatpickr-day.selected.endRange,
  .flatpickr-day.selected.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    @apply bg-select-75 rounded-none opacity-100;
  }

  .flatpickr-day:hover,
  .flatpickr-day.selected,
  .flatpickr-day.selected.flatpickr-disabled,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    @apply text-dark-primary;
  }

  .flatpickr-day.startRange,
  .flatpickr-day.inRange {
    @apply border-r-0;
  }

  .flatpickr-day.endRange,
  .flatpickr-day.inRange {
    @apply border-l-0;
  }

  .flatpickr-day.selected,
  .flatpickr-day.inRange,
  .flatpickr-day.selected.flatpickr-disabled,
  .flatpickr-day.inRange.flatpickr-disabled {
    @apply text-dark-primary;

    .flatpickr-day__date {
      @apply text-dark-primary;
    }
  }

  // Calendar with prices enabled
  &.flatpickr-calendar--prices {
    .flatpickr-day {
      @apply flex flex-col justify-center gap-1 text-xs h-12;

      &.flatpickr-disabled {
        @apply leading-none justify-start pt-[8px];
      }

      &__date {
        @apply leading-none;
      }

      &__price {
        @apply leading-none;
      }
    }
  }

  .flatpickr-day:nth-of-type(7n + 1) {
    @apply border-l border-white;
  }

  .flatpickr-day:nth-of-type(7n),
  .flatpickr-day:nth-of-type(7n):focus,
  .flatpickr-day:nth-of-type(7n):hover,
  .flatpickr-day:nth-of-type(7n).inRange,
  .flatpickr-day:nth-of-type(7n).selected,
  .flatpickr-day.endRange,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    @apply border-r border-white;
  }

  .flatpickr-day:nth-of-type(7n + 1):focus,
  .flatpickr-day:nth-of-type(7n + 1):hover,
  .flatpickr-day:nth-of-type(7n + 1).inRange,
  .flatpickr-day:nth-of-type(7n + 1).selected,
  .flatpickr-day.startRange,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.startRange.nextMonthDay {
    @apply border-l border-white;
  }

  .arrowTop {
    @apply before:content-unset;
  }

  // By default, in multi month view, in the second month container, the first day of every week has a box-shadow when inRange. We don't want this
  &.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    @apply shadow-none;
  }
}

.datepicker--hidden .flatpickr-calendar {
  @apply invisible;
}

// Overwrite when using full boundary width
.boundary-width {
  .flatpickr-calendar {
    @media (width >= 768px) {
      width: fit-content !important;
      min-width: fit-content !important;
      max-width: fit-content !important;
    }
  }
}
