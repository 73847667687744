<section class="section-spacing" data-test-class="newsletter">
	<div class="section-inner section-gap">
		<ng-content />
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="relative">
			<div class="mx-auto flex flex-col gap-2 md:max-w-lg md:flex-row">
				<formly-form
					[fields]="formFields"
					[form]="formGroup"
					class="w-full"
				/>
				<button
					[ngClass]="{
						'btn-is-loading': newsletterSubmitting,
					}"
					class="btn btn-primary w-full md:w-fit"
					data-test-id="newsletter-button"
					id="newsletter-submit"
					type="submit"
				>
					{{ 'components.newsletter.sign-up' | translate }}
				</button>
			</div>
			@if (newsletterSubmitted && !newsletterSubmitError) {
				<span
					class="block leading-loose text-dark-secondary md:absolute md:-right-1/2 md:bottom-10 md:left-1/2 md:max-w-lg md:translate-x-[-50%]"
					data-test-id="subscribe-confirmation"
				>
					{{ 'components.newsletter.confirmation' | translate }}
				</span>
			}
			<vp-recaptcha-notification
				[currentLanguage]="language"
				className="mx-auto mt-6 md:max-w-lg"
			/>
		</form>
	</div>
</section>
