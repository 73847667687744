import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LetDirective } from '@ngrx/component';
import { TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import dayjs from 'dayjs';
import { BaseOptions as FlatPickrBaseOptions } from 'flatpickr/dist/types/options';
import {
	combineLatest,
	distinctUntilChanged,
	map,
	Observable,
	Subject,
	tap,
} from 'rxjs';

import { AvailabilityDatesInterface } from '@valk-nx/availability-dates-store/availability-dates.interface';
import {
	CalendarComponent,
	FlatPickrEvent,
	FlatPickrEventType,
} from '@valk-nx/components/ui-calendar/src/lib/calendar';
import { DatePickerInterface } from '@valk-nx/components/ui-date-picker/src/lib/date-picker.interface';
import { FlatpickrFacade } from '@valk-nx/flatpickr-store/flatpickr.facade';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

import { AvailabilityWidgetInputComponent } from '../availability-input/availability-input';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget-input-date-range`,
	templateUrl: './availability-input-date-range.html',
	imports: [
		AngularSvgIconModule,
		AvailabilityWidgetInputComponent,
		CalendarComponent,
		CommonModule,
		LetDirective,
		TranslatePipe,
	],
	providers: [FlatpickrFacade],
})
export class AvailabilityWidgetInputDateRangeComponent implements OnChanges {
	@Input() className = '';
	@Input({ required: true }) availability: AvailabilityDatesInterface;
	@Input({ required: true }) config: Partial<FlatPickrBaseOptions>;
	@Input() initialArrivalDate: Date;
	@Input() prettyDateFormat = 'dd D MMM';
	@Input() isLoading = false;
	@Input() jumpToFirstAvailableDate = false;

	@Input()
	set isOpen(value: boolean) {
		this._isOpen = value;
	}

	@Output() datePickerModelChanged = new EventEmitter<DatePickerInterface>();
	@Output() emitHasError = new EventEmitter<boolean>();
	@Output() emitMonthChange = new EventEmitter<string>();
	@Output() emitShowAvailabilityButton = new EventEmitter<Event>();
	@Output() initialDatesNotAvailable = new EventEmitter<void>();
	@Output() emitIsPopoverOpen = new EventEmitter<boolean>();

	config$: Observable<Partial<FlatPickrBaseOptions>>;
	filteredCalendarData$: Observable<string[]>;
	selectedDates$: Observable<Date[]>;
	numberOfNights$: Observable<number>;
	prettyStartDate$: Observable<string>;
	prettyEndDate$: Observable<string>;
	prettyCombinedDates$: Observable<string>;

	recalculateCalendarData$ = new Subject<void>();
	isSmallTablet$: Observable<boolean>;
	_isOpen = false;

	constructor(
		private readonly viewport: ViewPortService,
		private readonly flatpickrFacade: FlatpickrFacade,
	) {
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
		this.config$ = this.adjustConfigToScreenSize();

		this.numberOfNights$ = this.flatpickrFacade.selectNights$;
		this.selectedDates$ = this.flatpickrFacade.selectSelectedDates$;

		this.prettyStartDate$ = this.flatpickrFacade.selectStartDate$.pipe(
			map((startDate) => this.formatDate(startDate)),
		);

		this.prettyEndDate$ = this.flatpickrFacade.selectEndDate$.pipe(
			map((endDate) => this.formatDate(endDate)),
		);

		this.prettyCombinedDates$ = combineLatest([
			this.prettyStartDate$,
			this.prettyEndDate$,
		]).pipe(
			map(([prettyStartDate, prettyEndDate]) => {
				if (!prettyStartDate) {
					return '';
				}
				if (!prettyEndDate) {
					return prettyStartDate;
				}
				return `${prettyStartDate} - ${prettyEndDate}`;
			}),
		);

		this.filteredCalendarData$ = this.filterCalendarData();

		combineLatest([
			this.flatpickrFacade.selectStartDate$,
			this.flatpickrFacade.selectEndDate$,
		])
			.pipe(
				takeUntilDestroyed(),
				tap(([arrivalDate, departureDate]) => {
					this.datePickerModelChanged.emit({
						arrivalDate,
						departureDate,
					});
				}),
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['availability']) {
			this.recalculateCalendarData$.next();
		}
	}

	popoverStateChanged(isOpen: boolean) {
		this._isOpen = isOpen;

		this.emitIsPopoverOpen.emit(this._isOpen);
	}

	isArrivalDateAvailable(arrivalDate: Date): boolean {
		const arrivalDateKey = this.formatDate(arrivalDate, 'YYYY-MM-DD');
		return !!this.availability[arrivalDateKey];
	}

	isDepartureDateAvailable(arrivalDate: Date, departureDate: Date): boolean {
		const arrivalDateKey = this.formatDate(arrivalDate, 'YYYY-MM-DD');
		const departureDateKey = this.formatDate(departureDate, 'YYYY-MM-DD');
		return !!this.availability[arrivalDateKey][departureDateKey];
	}

	formatDate(
		date: string | Date | dayjs.Dayjs | undefined,
		format = this.prettyDateFormat,
	): string {
		return date ? `${dayjs(date).format(format)}` : '';
	}

	adjustConfigToScreenSize(): Observable<Partial<FlatPickrBaseOptions>> {
		return this.isSmallTablet$.pipe(
			distinctUntilChanged(),
			map((isSmallTablet) => {
				return {
					...this.config,
					showMonths: isSmallTablet ? 1 : this.config.showMonths,
				};
			}),
			tap((config) => {
				this.flatpickrFacade.updateShowMonths(config.showMonths);
			}),
		);
	}

	filterCalendarData(): Observable<string[]> {
		return combineLatest([
			this.recalculateCalendarData$,
			this.selectedDates$,
		]).pipe(
			map(([_, selectedDates]) => {
				this.checkForInvalidDates(selectedDates);

				if (selectedDates.length === 1) {
					const arrivalDateKey = this.formatDate(
						selectedDates[0],
						'YYYY-MM-DD',
					);
					const checkoutDates = Object.keys(
						this.availability[arrivalDateKey],
					);
					return checkoutDates;
				} else if (this.availability) {
					return Object.keys(this.availability);
				} else {
					return [];
				}
			}),
		);
	}

	checkForInvalidDates(selectedDates: Date[]) {
		if (
			(selectedDates.length > 0 &&
				!this.isArrivalDateAvailable(selectedDates[0])) ||
			(selectedDates.length > 1 &&
				!this.isDepartureDateAvailable(
					selectedDates[0],
					selectedDates[1],
				))
		) {
			this.flatpickrFacade.clearFlatpickrInstance();
		}
	}

	onDatePickerEmitter(event: FlatPickrEvent): void {
		if (event.type === FlatPickrEventType.OnValueUpdate) {
			this.flatpickrFacade.setDatesAndUpdateNights(event.selectedDates);
			this.recalculateCalendarData$.next();

			if (event.selectedDates.length > 1) {
				this.popoverStateChanged(false);
			}
		}
	}

	onEmitMonthChange(newDate: string) {
		this.emitMonthChange.emit(newDate);
	}

	onEmitHasError(hasError: boolean) {
		this.emitHasError.emit(hasError);
	}
}
