<vp-availability-widget-input
	*ngrxLet="isSmallTablet$ as isSmallTablet"
	[className]="className"
	[excludeFromClosingRegex]="excludeFromClosingRegex"
	[header]="'global.room-layout' | translate"
	[iconName]="_isOpen ? 'icon-chevron-up' : 'icon-chevron-down'"
	[isOpen]="_isOpen"
	[label]="'global.room-layout' | translate"
	[popoverPadding]="true"
	[value]="(roomLabel | lowercase) + ', ' + personLabel"
	(emitIsOpen)="popoverStateChanged($event)"
	inputName="availability-occupancy"
>
	<div class="flex flex-col gap-4 pb-4 md:py-4">
		@for (occ of occupancy; track occ; let i = $index) {
			@if (occupancy.length > 1) {
				<div class="flex justify-between px-4">
					<p class="m-0 text-lg font-semibold">
						{{ 'global.room' | translate | titlecase }}
						{{ i + 1 }}
					</p>
					<button
						(click)="onRemoveRoom($event, i)"
						data-test-class="remove-row-button"
					>
						<span class="sr-only">{{
							'global.remove' | translate
						}}</span>
						<svg-icon
							class="block size-6 text-dark-secondary"
							src="assets/icons/icon-delete.svg"
						/>
					</button>
				</div>
			}
			@if (position$ | async; as position) {
				<div
					class="flex flex-col justify-end gap-2 px-4 sm:flex-row"
					data-test-class="occupancy-row"
				>
					<vp-label
						[inputName]="'adults-stepper-' + i"
						[labelText]="
							'components.occupancy.adults.label' | translate
						"
						[mobilePosition]="position"
						[position]="position"
						data-test-id="adults-stepper"
					>
						<vp-stepper
							[ariaDecreaseLabel]="
								'components.occupancy.adults.decrease-button'
									| translate
							"
							[ariaIncreaseLabel]="
								'components.occupancy.adults.increase-button'
									| translate
							"
							[inputId]="'adults-stepper-' + i"
							[max]="maxOccupancy(occ, 'adults')"
							[min]="1"
							[value]="occ.adults"
							(valueChange)="
								occupancyValueChanged($event, 'adults', i)
							"
						/>
					</vp-label>
					<vp-label
						[inputName]="'children-stepper-' + i"
						[labelText]="
							'components.occupancy.children.label' | translate
						"
						[mobilePosition]="position"
						[position]="position"
						[requiredHelperText]="
							'components.occupancy.children.helper-text'
								| translate
						"
						data-test-id="children-stepper"
					>
						<vp-stepper
							[ariaDecreaseLabel]="
								'components.occupancy.children.decrease-button'
									| translate
							"
							[ariaIncreaseLabel]="
								'components.occupancy.children.increase-button'
									| translate
							"
							[inputId]="'children-stepper-' + i"
							[max]="maxOccupancy(occ, 'children')"
							[min]="0"
							[value]="occ.children"
							(valueChange)="
								occupancyValueChanged($event, 'children', i)
							"
						/>
					</vp-label>
					<vp-label
						[inputName]="'infants-stepper-' + i"
						[labelText]="
							'components.occupancy.infants.label' | translate
						"
						[mobilePosition]="position"
						[position]="position"
						[requiredHelperText]="
							'components.occupancy.infants.helper-text'
								| translate
						"
						data-test-id="infants-stepper"
					>
						<vp-stepper
							[ariaDecreaseLabel]="
								'components.occupancy.infants.decrease-button'
									| translate
							"
							[ariaIncreaseLabel]="
								'components.occupancy.infants.increase-button'
									| translate
							"
							[inputId]="'infants-stepper-' + i"
							[max]="maxOccupancy(occ, 'infants')"
							[min]="0"
							[value]="occ.infants"
							(valueChange)="
								occupancyValueChanged($event, 'infants', i)
							"
						/>
					</vp-label>
				</div>
			}
			@if (occupancy.length > 1 && !$last) {
				<hr class="text-ui-100" />
			}
		}
	</div>

	@if (occupancy.length < maxRooms) {
		<hr class="text-ui-100" />
		<div class="card-md pb-0 leading-[0]">
			<button
				(click)="onAddRoom($event)"
				class="inline-flex items-center gap-2 text-lg font-semibold no-underline"
				data-test-id="occupancy-add-room"
			>
				<svg-icon
					class="size-6"
					src="assets/icons/icon-plus-circle-open.svg"
				/>
				{{ 'global.add-room' | translate }}
			</button>
		</div>
	}
	<div class="card-md mt-2 flex flex-col gap-2 pt-0" widget-input-footer>
		<div class="text-center">
			<div class="justify-center text-center">
				<span class="text-sm text-dark-tertiary">{{
					roomLabel + ', ' + personLabel
				}}</span>
			</div>
		</div>

		<button
			(click)="emitChooseDates.emit($event)"
			class="btn btn-primary mt-auto w-full"
			data-test-id="choose-dates-button"
		>
			{{ 'global.choose-dates' | translate }}
		</button>
	</div>
</vp-availability-widget-input>
