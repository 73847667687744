<div
	[ngClass]="{
		'bg-accent-100 text-white': variant === 'accent',
		'bg-accent-50 text-dark-primary': variant === 'accent-muted',
		'bg-primary-50 text-dark-primary': variant === 'primary-muted',
		'bg-info-50 text-dark-primary': variant === 'info',
	}"
	class="relative z-50 p-2 text-center"
	data-test-id="top-banner"
	id="top-banner"
>
	<ng-content />
</div>
