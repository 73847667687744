export function getContentConfigurationKey(key: string): string {
	if (key == null) {
		return 'fallback';
	}
	return stripBrowser(key.toString().split(':')[0].toLowerCase());
}

export function stripBrowser(value: string) {
	const prefix = 'browser-';
	if (value.startsWith(prefix)) {
		return value.substring(prefix.length);
	}
	return value;
}
