<div class="aspect-video md:aspect-anamorphic">
	<google-map
		[center]="coordinates()"
		[options]="mapOptions"
		[zoom]="zoom()"
		height="100%"
		width="100%"
	>
		<map-advanced-marker [position]="coordinates()" />
	</google-map>
</div>
