<div class="mx-auto max-w-screen-md">
	<vp-deal-card
		[content]="content"
		[dealUrl]="flatUrl"
		[editableInStoryblok]="_editable"
		[images]="flatImages || []"
		[overline]="overline"
		[price]="price"
		[ribbonText]="ribbonText"
		[size]="size"
		[sticker]="flatSticker"
		[tags]="flatTags"
		[title]="title"
		class="h-full"
	/>
</div>
