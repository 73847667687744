import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withFetch,
	withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {
	APP_ID,
	ApplicationConfig,
	importProvidersFrom,
	inject,
	provideAppInitializer,
	provideZoneChangeDetection,
} from '@angular/core';
import {
	provideClientHydration,
	withEventReplay,
	withIncrementalHydration,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	provideRouter,
	withInMemoryScrolling,
	withRouterConfig,
} from '@angular/router';

import { ApmModule } from '@elastic/apm-rum-angular';
import { provideStore } from '@ngrx/store';
import {
	provideTranslateService,
	TranslateLoader,
	TranslateService,
} from '@ngx-translate/core';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { MarkdownService, provideMarkdown } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';

import { APM_ENVIRONMENT, provideApmHandling } from '@valk-nx/apm/index';
import {
	ConfigService,
	provideConfiguration,
} from '@valk-nx/content/app/config/config.service';
import {
	ReducerProvider,
	reducerToken,
} from '@valk-nx/content/app/root.reducer';
import { environment } from '@valk-nx/content/environments/environment';
import {
	ASSETS_ROOT,
	createTranslateLoader,
	fallbackLanguage,
} from '@valk-nx/core/lib/core';
import { AppHttpInterceptor } from '@valk-nx/core/lib/ssr/app.interceptor';
import { PostalCodeFacade } from '@valk-nx/postal-code-store/lib/postal-code.facade';
import { GoogleTagManagerService } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';
import { SESSION_STORAGE } from '@valk-nx/storyblok/components/order-giftcard/src/lib/order-giftcard';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

import { routes } from './app.routes';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function initializeGTM(gtmInitService: GoogleTagManagerService) {
	return (): void => gtmInitService.init();
}

export const appConfig: ApplicationConfig = {
	providers: [
		provideAppInitializer(() => {
			const initializerFn = initializeGTM(
				inject(GoogleTagManagerService),
			);
			return initializerFn();
		}),
		provideAngularSvgIcon(),
		provideAnimations(),
		provideApmHandling(),
		provideClientHydration(withEventReplay(), withIncrementalHydration()),
		provideConfiguration(),
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		provideMarkdown(),
		provideRouter(
			routes,
			withRouterConfig({ onSameUrlNavigation: 'reload' }),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
		),
		provideStore(reducerToken),
		provideToastr(),
		provideTranslateService({
			defaultLanguage: fallbackLanguage,
			loader: {
				deps: [HttpClient, ASSETS_ROOT],
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
			},
		}),
		provideZoneChangeDetection({ eventCoalescing: true }),
		importProvidersFrom(ApmModule, RecaptchaV3Module),
		CurrencyPipe,
		MarkdownService,
		PostalCodeFacade,
		ReCaptchaV3Service,
		ReducerProvider,
		TagManagerFacade,
		TranslateService,
		{
			provide: APP_ID,
			useValue: 'valkContentApp',
		},
		{
			provide: ASSETS_ROOT,
			useValue: `assets`,
		},
		{
			provide: APM_ENVIRONMENT,
			useFactory: () => ({
				environment: inject(ConfigService).environment,
				name: environment.name,
				origin: inject(ConfigService).host,
				production: environment.production,
				version: environment.version,
			}),
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpInterceptor,
		},
		{
			provide: SESSION_STORAGE,
			useValue: globalThis.sessionStorage,
		},
	],
};
