<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="static-section"
>
	<div class="section-inner section-gap">
		@if (content.length > 0) {
			<sb-columns [colOne]="staticData$ | async" component="columnOne" />
		}
	</div>
</section>
