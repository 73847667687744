<div class="mx-auto max-w-screen-md">
	<div
		[editableInStoryblok]="_editable"
		class="card card-form"
		data-test-id="order-giftcard"
	>
		<div #orderGiftcardContainer>
			<form
				[formGroup]="form"
				[ngClass]="{
					hidden: orderStep !== orderSteps.Form,
				}"
				(ngSubmit)="onSubmit()"
				data-test-id="order-form"
			>
				<formly-form
					[fields]="formFields"
					[form]="form"
					[model]="model"
					[options]="options"
				/>
				<button
					[ngClass]="{
						'btn-is-loading': isLoading,
					}"
					class="btn btn-primary mt-6 w-full md:mt-8"
					data-test-id="summary-button"
					type="submit"
				>
					{{
						'storyblok.giftcard.order-action-costs-overview'
							| translate
					}}
				</button>
				<div class="mt-3 md:mt-4">
					<vp-recaptcha-notification [currentLanguage]="language" />
				</div>
			</form>

			@switch (orderStep) {
				@case (orderSteps.Summary) {
					<div data-test-id="order-summary">
						<sb-order-giftcard-summary
							[currency]="currency"
							[language]="language"
							[orderStatus]="orderStatus"
							[orderStep]="orderStep"
							[orderSummary]="orderSummary"
							[showAlert]="false"
						/>
						<div
							class="mt-6 flex w-full items-center justify-center gap-x-6 md:mt-8"
						>
							<button
								(click)="toFormStep()"
								class="text-link w-full"
								data-test-id="form-button"
							>
								{{ 'storyblok.giftcard.change' | translate }}
							</button>
							<button
								[ngClass]="{
									'btn-is-loading': isLoading,
								}"
								(click)="onCreateOrder()"
								class="btn btn-primary w-full"
								data-test-id="order-button"
							>
								{{
									'storyblok.giftcard.order-and-pay'
										| translate
								}}
							</button>
						</div>
					</div>
				}
				@case (orderSteps.Confirmation) {
					<div data-test-id="order-confirmation">
						<sb-order-giftcard-summary
							[currency]="currency"
							[language]="language"
							[orderStatus]="orderStatus"
							[orderStep]="orderStep"
							[orderSummary]="orderSummary"
							[showAlert]="true"
						/>
					</div>
				}
				@case (orderSteps.Processing) {
					<div data-test-id="order-processing">
						<sb-order-giftcard-processing />
					</div>
				}
			}
		</div>
	</div>
</div>
