import { Component, Input, numberAttribute } from '@angular/core';

import { CtaCardModule } from '@valk-nx/compositions/ui-cta-card/src/lib/cta-card.module';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { Link } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-cta-card',
	templateUrl: './cta-card.html',
	imports: [CtaCardModule],
})
export class CtaCardComponent extends StoryblokRootDirective {
	@Input({ required: true }) title!: string;
	@Input({ required: true }) bulletList!: string;
	@Input({ transform: numberAttribute }) price!: number;
	@Input() currency = 'EUR';
	@Input({ required: true }) ctaLabel!: string;
	@Input({ transform: toDate }) validFrom!: Date;
	@Input({ transform: toDate }) validUntil!: Date;

	@Input() set ctaUrl(ctaUrl: Link) {
		this.flatLink = {
			href: ctaUrl.cached_url,
			target: ctaUrl.target as string,
		};
	}

	flatLink: { href: string; target: string } = { href: '', target: '' };
}
