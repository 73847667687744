import { CommonModule } from '@angular/common';
import { Component, Input, numberAttribute } from '@angular/core';

import { MarkdownModule } from 'ngx-markdown';

import { RibbonColor } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { HotelByDealCardModule } from '@valk-nx/compositions/ui-hotel-by-deal-card/src/lib/hotel-by-deal-card.component.module';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	CardImages,
	Text,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-hotel-by-deal-card',
	templateUrl: './hotel-by-deal-card.html',
	imports: [
		HotelByDealCardModule,
		CommonModule,
		MarkdownModule,
		StoryblokDirective,
	],
})
export class HotelByDealCardComponent extends StoryblokRootDirective {
	@Input({ required: true }) hotelName!: string;

	@Input() set image(images: CardImages[]) {
		if (images.length && images[0].bynder.length > 0) {
			this.flatImage = StoryblokHelper.mapBynderToPhotoInterface(
				images[0].bynder[0],
				images[0].imageAlt,
			);
		} else {
			this.flatImage = {
				alt: images[0]?.imageAlt || '',
				src: '',
			};
		}
	}

	@Input() ribbon!: string;
	@Input() ribbonText!: string;
	@Input() ribbonColor: RibbonColor = 'yellow';

	@Input() set usps(usps: Text[]) {
		this.flatUsps = usps.map((usp) => usp.text);
	}

	@Input() set tags(tags: Text[]) {
		this.flatTags = tags.map((tag) => tag.text);
	}

	@Input({ transform: numberAttribute }) price!: number;

	@Input({ transform: numberAttribute }) rating!: number;
	@Input({ transform: numberAttribute }) numberOfRatings!: number;

	@Input({ transform: numberAttribute }) numberOfStars!: number;

	@Input() ctaUrl!: string;
	@Input() currency!: string;

	@Input() extras!: [];
	@Input() disabled!: string;

	@Input({ transform: toDate }) validFrom!: Date;

	@Input({ transform: toDate }) validUntil!: Date;

	flatImage!: PhotoInterface;
	flatUsps: string[] = [];
	flatTags: string[] = [];
}
