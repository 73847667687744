<ng-container [editableInStoryblok]="_editable">
	<vp-banner [variant]="variant">
		<span class="block font-bold">{{ title }}</span>
		<sb-richtext
			[content]="content"
			class="text-sm"
			maxWidth="section-inner"
			richTextAlignment="text-center"
		/>
	</vp-banner>
</ng-container>
