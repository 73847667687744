@if (images.length === 3) {
	<div class="grid w-full grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-6 md:gap-8">
		<div
			class="relative col-span-2 h-[19rem] bg-accent-50 sm:row-span-2 sm:h-[25rem] md:h-[30rem] lg:h-[35rem]"
		>
			<vp-image
				[image]="{
					src: images[0].src,
					width: 639,
					height: 304,
				}"
				[sources]="[
					{ min: null, max: sizes.sm, width: 639, height: 304 },
					{
						min: sizes.sm,
						max: sizes.md,
						width: 502,
						height: 400,
					},
					{
						min: sizes.md,
						max: sizes.lg,
						width: 670,
						height: 480,
					},
					{
						min: sizes.lg,
						max: null,
						width: 825,
						height: 560,
					},
				]"
				className="size-full object-cover"
			/>
		</div>
		@for (image of images | slice: 1; track $index) {
			<div
				class="h-40 bg-accent-50 sm:h-[11.75rem] md:h-[14rem] lg:h-[16.5rem]"
			>
				<vp-image
					[image]="{
						src: image.src,
						width: 242,
						height: 160,
					}"
					[sources]="[
						{
							min: null,
							max: sizes.sm,
							width: 310,
							height: 160,
						},
						{
							min: sizes.sm,
							max: sizes.md,
							width: 239,
							height: 188,
						},
						{
							min: sizes.md,
							max: sizes.lg,
							width: 320,
							height: 224,
						},
						{
							min: sizes.lg,
							max: null,
							width: 395,
							height: 264,
						},
					]"
					className="size-full object-cover"
				/>
			</div>
		}
	</div>
}
@if (images.length === 4) {
	<div class="grid w-full grid-cols-3 gap-4 sm:grid-cols-3 sm:gap-6 md:gap-8">
		<div
			class="col-span-3 h-[19rem] bg-accent-50 sm:col-span-1 sm:row-span-2 sm:h-[25rem] md:h-[30rem] lg:h-[35rem]"
		>
			<vp-image
				[image]="{
					src: images[0].src,
					width: 639,
					height: 304,
				}"
				[sources]="[
					{ min: null, max: sizes.sm, width: 639, height: 304 },
					{
						min: sizes.sm,
						max: sizes.md,
						width: 239,
						height: 400,
					},
					{
						min: sizes.md,
						max: sizes.lg,
						width: 320,
						height: 480,
					},
					{
						min: sizes.lg,
						max: null,
						width: 395,
						height: 560,
					},
				]"
				className="size-full object-cover"
			/>
		</div>
		<div
			class="h-[8.5rem] bg-accent-50 sm:col-span-1 sm:row-span-2 sm:h-[25rem] md:h-[30rem] lg:h-[35rem]"
		>
			<vp-image
				[image]="{
					src: images[1].src,
					width: 200,
					height: 136,
				}"
				[sources]="[
					{ min: null, max: sizes.sm, width: 200, height: 136 },
					{
						min: sizes.sm,
						max: sizes.md,
						width: 239,
						height: 400,
					},
					{
						min: sizes.md,
						max: sizes.lg,
						width: 320,
						height: 480,
					},
					{
						min: sizes.lg,
						max: null,
						width: 395,
						height: 560,
					},
				]"
				className="size-full object-cover"
			/>
		</div>
		@for (image of images | slice: 2; track $index) {
			<div
				class="h-[8.5rem] bg-accent-50 sm:h-[11.75rem] md:h-[14rem] lg:h-[16.5rem]"
			>
				<vp-image
					[image]="{
						src: image.src,
						width: 639,
						height: 304,
					}"
					[sources]="[
						{
							min: null,
							max: sizes.sm,
							width: 639,
							height: 304,
						},
						{
							min: sizes.sm,
							max: sizes.md,
							width: 240,
							height: 188,
						},
						{
							min: sizes.md,
							max: sizes.lg,
							width: 320,
							height: 224,
						},
						{
							min: sizes.lg,
							max: null,
							width: 396,
							height: 264,
						},
					]"
					className="size-full object-cover"
				/>
			</div>
		}
	</div>
}
@if (images.length === 5) {
	<div class="grid w-full grid-cols-3 gap-4 sm:grid-cols-6 sm:gap-6 md:gap-8">
		@for (image of images | slice: 0 : 2; track $index) {
			<div
				class="col-span-3 h-[9.75rem] bg-accent-50 sm:col-span-3 sm:h-60 md:h-80"
			>
				<vp-image
					[image]="{
						src: image.src,
						width: 639,
						height: 156,
					}"
					[sources]="[
						{
							min: null,
							max: sizes.sm,
							width: 639,
							height: 156,
						},
						{
							min: sizes.sm,
							max: sizes.md,
							width: 372,
							height: 340,
						},
						{
							min: sizes.md,
							max: null,
							width: 608,
							height: 320,
						},
					]"
					className="size-full object-cover"
				/>
			</div>
		}
		@for (image of images | slice: 2; track $index) {
			<div class="h-[8.5rem] bg-accent-50 sm:col-span-2 md:h-[13rem]">
				<vp-image
					[image]="{
						src: image.src,
						width: 202,
						height: 136,
					}"
					[sources]="[
						{
							min: null,
							max: sizes.sm,
							width: 202,
							height: 136,
						},
						{
							min: sizes.sm,
							max: sizes.md,
							width: 240,
							height: 136,
						},
						{
							min: sizes.md,
							max: null,
							width: 395,
							height: 208,
						},
					]"
					className="size-full object-cover"
				/>
			</div>
		}
	</div>
}
