<div data-test-id="package-overview">
	@for (blok of contentInputs; track $index) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
</div>
<section class="section-spacing bg-accent-25">
	<div class="section-inner section-gap children:w-full children:md:mx-auto">
		<h3 class="running-head" data-test-class="running-head">
			{{ 'global.packages-label' | translate }}
		</h3>
		@if (packages$ | async; as packages) {
			@if (packages.length === 0) {
				<vp-alert
					[closeable]="false"
					[content]="'global.no-results-found' | translate"
					[type]="alertType"
				/>
			}
			@for (package of packages; track $index) {
				<vp-persuade-card
					[cardUrl]="currentUrl + '/' + package.slug"
					[content]="package.bulletContent"
					[ctaText]="'storyblok.pages.package-card.view' | translate"
					[ctaUrl]="currentUrl + '/' + package.slug"
					[currency]="package.price.currency"
					[images]="package.images"
					[price]="package.price.amount"
					[pricingCondition]="
						(package?.price?.type === 'perPerson'
							? 'global.price-per-person'
							: 'global.total-price-label'
						) | translate
					"
					[title]="package.name"
					imageVariant="mini-carousel"
				/>
			}
		}
	</div>
</section>
