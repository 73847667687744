<div class="mx-auto max-w-screen-md">
	<vp-read-more [isDisabled]="!isReadMore">
		<markdown
			[class]="listStyle"
			[data]="content"
			[editableInStoryblok]="_editable"
			class="markdown"
			data-test-class="markdown"
		/>
	</vp-read-more>
</div>
