<div [editableInStoryblok]="_editable">
	@if (!colTwo) {
		<div class="mx-auto">
			<ng-container
				[ngTemplateOutlet]="colInfo"
				[ngTemplateOutletContext]="{ colInfo: colOne }"
			/>
		</div>
	}
	@if (colTwo) {
		<div class="column-gap flex-col children:w-full md:flex-row">
			<div
				[ngClass]="{
					'md:w-2/3':
						component === 'columnTwo' &&
						colAlignment === 'asymmetricalLeft',
					'md:w-1/3':
						component === 'columnTwo' &&
						colAlignment === 'asymmetricalRight',
					'md:w-1/2':
						component === 'columnTwo' &&
						!(
							colAlignment === 'asymmetricalLeft' ||
							colAlignment === 'asymmetricalRight'
						),
				}"
			>
				<ng-container
					[ngTemplateOutlet]="colInfo"
					[ngTemplateOutletContext]="{ colInfo: colOne }"
				/>
			</div>
			<div
				[ngClass]="{
					'md:w-1/3':
						component === 'columnTwo' &&
						colAlignment === 'asymmetricalLeft',
					'md:w-2/3':
						component === 'columnTwo' &&
						colAlignment === 'asymmetricalRight',
					'md:w-1/2':
						component === 'columnTwo' &&
						!(
							colAlignment === 'asymmetricalLeft' ||
							colAlignment === 'asymmetricalRight'
						),
				}"
			>
				<ng-container
					[ngTemplateOutlet]="colInfo"
					[ngTemplateOutletContext]="{ colInfo: colTwo }"
				/>
			</div>
			@if (colThree) {
				<div>
					<ng-container
						[ngTemplateOutlet]="colInfo"
						[ngTemplateOutletContext]="{ colInfo: colThree }"
					/>
				</div>
			}
		</div>
	}
</div>

<ng-template #colInfo let-colInfo="colInfo">
	<div class="flex w-full flex-col gap-4 md:gap-6 2xl:gap-8">
		@if (components) {
			@for (blok of colInfo; track $index) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok.component]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				/>
			}
		}
	</div>
</ng-template>
