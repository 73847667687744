<section
	class="flex flex-col gap-2 rounded bg-ui-25 p-4 text-base md:text-lg"
	data-test-id="receipt-summary"
>
	@if (receiptDescription) {
		<h2 class="sr-only" data-test-id="receipt-title">
			{{ receiptDescription }}
		</h2>
	}
	<ul class="flex flex-col gap-2">
		@if (items.length) {
			<li>
				@if (itemsDescription) {
					<span class="sr-only">
						{{ itemsDescription }}
					</span>
				}
				<ul class="flex flex-col gap-2">
					@for (item of items; track item; let i = $index) {
						<li>
							<vp-receipt-summary-item
								[currency]="currency"
								[index]="i"
								[item]="item"
								[someItemHasExtras]="hasExtras"
								[totalNumberOfItems]="items.length"
							/>
						</li>
					}
				</ul>
			</li>
		}

		@if (tax) {
			@if (specifiedTaxes?.municipal || specifiedTaxes?.tourist) {
				<li>
					<hr class="divider mb-2 h-0.5" />
					<span class="sr-only">{{
						'global.taxes' | translate
					}}</span>
					<ul class="flex flex-col gap-2">
						@if (specifiedTaxes?.municipal > 0) {
							<li class="flex justify-between gap-4">
								<span class="my-auto">
									{{ 'global.general-taxes' | translate }}
								</span>
								<span
									[ngClass]="{ 'font-bold': hasExtras }"
									class="my-auto shrink-0"
									data-test-id="municipal-tax-price"
								>
									{{
										specifiedTaxes.municipal
											| price: currency
									}}
								</span>
							</li>
						}
						@if (specifiedTaxes?.tourist > 0) {
							<li class="flex justify-between gap-4">
								<span class="my-auto">
									{{ 'global.tourist-taxes' | translate }}
								</span>
								<span
									[ngClass]="{ 'font-bold': hasExtras }"
									class="my-auto shrink-0"
									data-test-id="tourist-tax-price"
								>
									{{
										specifiedTaxes.tourist
											| price: currency
									}}
								</span>
							</li>
						}
					</ul>
				</li>
			}
			@if (unspecifiedTaxes) {
				<li>
					<hr class="divider mb-2 h-0.5" />
					<div class="flex justify-between gap-4">
						<span class="my-auto">
							{{ 'global.taxes' | translate }}
						</span>
						<span
							[ngClass]="{ 'font-bold': hasExtras }"
							class="my-auto shrink-0"
							data-test-id="total-tax-price"
						>
							{{ unspecifiedTaxes | price: currency }}
						</span>
					</div>
				</li>
			}
		}

		@if (subTotal) {
			<li>
				@if (items.length || tax) {
					<hr class="divider mb-2 border-t-2" />
				}
				<div
					class="flex justify-between gap-4 text-lg font-bold md:text-xl"
				>
					<span class="my-auto">{{
						'global.subtotal' | translate
					}}</span>
					<span
						class="my-auto shrink-0"
						data-test-id="subtotal-price"
					>
						{{ subTotal | price: currency }}
					</span>
				</div>
			</li>
		}

		@if (alreadyPaid) {
			<li class="flex flex-col gap-2">
				<hr class="divider" />
				<div class="flex justify-between gap-4">
					<span class="my-auto flex gap-1.5">
						{{ 'global.already-paid' | translate }}
					</span>
					<span class="my-auto shrink-0" data-test-id="already-paid">
						{{ -alreadyPaid | price: currency }}
					</span>
				</div>
			</li>
		}

		@if (loyalToPay && showLoyal) {
			<li class="flex flex-col gap-2">
				<hr class="divider" />
				<div
					[ngClass]="{ 'text-success-150': !loyalToEarn }"
					class="flex justify-between gap-4"
				>
					<span class="my-auto flex gap-1.5">
						@if (!loyalToEarn) {
							<svg-icon
								class="my-auto size-4 shrink-0"
								src="assets/icons/icon-giftcard.svg"
								svgClass="h-4 w-4 text-success-150"
							/>
						}
						<span class="my-auto">
							{{ 'components.summary-pricing.loyal' | translate }}
						</span>
					</span>
					<span class="my-auto shrink-0" data-test-id="loyal-price">
						{{ -loyalToPay | price: currency }}
					</span>
				</div>
			</li>
		}

		@if (total !== null && total !== amountToPay) {
			<li class="flex flex-col gap-2">
				@if (items.length || subTotal) {
					<hr class="divider border-t-2" />
				}
				<div
					class="flex justify-between gap-4 text-lg font-bold md:text-xl"
				>
					<span class="my-auto">
						{{ 'global.total' | translate }}
					</span>
					<span class="my-auto shrink-0" data-test-id="total-price">
						{{ total | price: currency }}
					</span>
				</div>
			</li>
		}

		@if (amountToPay !== null) {
			<li class="flex flex-col gap-2">
				@if (
					total !== null ||
					loyalToPay ||
					alreadyPaid ||
					subTotal ||
					unspecifiedTaxes
				) {
					<hr class="divider border-t-2" />
				}
				<div
					class="flex justify-between gap-4 text-xl font-bold md:text-2xl"
				>
					<span class="my-auto">
						{{ 'global.amount-to-pay-label' | translate }}
					</span>
					<span class="my-auto shrink-0" data-test-id="amount-to-pay">
						{{ amountToPay | price: currency }}
					</span>
				</div>
			</li>
		}
		@if (toComply !== null && toComply !== 0) {
			<li class="flex flex-col gap-2">
				@if (total !== null || loyalToPay || alreadyPaid || subTotal) {
					<hr class="divider border-t-2" />
				}
				<div
					class="flex justify-between gap-4 text-xl font-bold md:text-2xl"
				>
					<span class="my-auto">
						{{ 'global.comply-now-rest' | translate }}
					</span>
					<span class="my-auto shrink-0" data-test-id="to-comply">
						{{ toComply | price: currency }}
					</span>
				</div>
			</li>
		}

		@if (totalVat) {
			<li class="flex justify-between gap-4 text-dark-tertiary">
				<span class="my-auto">
					{{
						'compositions.receipt-summary.of-which-vat' | translate
					}}
				</span>
				<span class="my-auto shrink-0">
					{{ totalVat | price: currency }}
				</span>
			</li>
		}
		@if (paymentOption) {
			<li
				class="mt-0 text-sm text-dark-tertiary"
				data-test-id="payment-option"
			>
				{{ 'global.payment-method' | translate }}:
				{{ 'global.payment-options.' + paymentOption | translate }}
			</li>
		}
	</ul>

	@if (hasConditions) {
		<vp-sheet
			[isLoading]="!conditions"
			data-test-id="terms-sheet"
			triggerId="terms-trigger-sheet"
		>
			<p #sheetTrigger class="mb-0">
				{{
					'compositions.receipt-summary.conditions.start-text'
						| translate
				}}
				<button
					(click)="getConditions()"
					class="text-link inline-block cursor-pointer"
					data-test-id="terms-trigger-sheet"
					id="terms-trigger-sheet"
					vp-sheet-trigger
				>
					{{
						'compositions.receipt-summary.conditions.link'
							| translate
					}}
				</button>
				{{
					'compositions.receipt-summary.conditions.end-text'
						| translate
				}}
			</p>
			<vp-sheet-header>
				<h2 data-test-id="terms-sheet-content-title">
					{{ 'global.terms-conditions.title' | translate }}
				</h2>
			</vp-sheet-header>
			<vp-sheet-content
				data-test-id="terms-sheet-content"
				id="sheet-content"
				tabindex="0"
			>
				<markdown [data]="conditions" />
			</vp-sheet-content>
		</vp-sheet>
	}

	@if (payOnArrival) {
		<p class="mb-0" data-test-id="reception-info">
			{{ 'global.pay-on-arrival-text' | translate }}
		</p>
	}

	@if (loyalToEarn && showLoyal) {
		<p
			class="mb-0 mt-2 flex gap-1.5 text-success-150"
			data-test-id="receipt-saved-loyal"
		>
			<svg-icon
				class="mb-auto mt-[0.2em] size-4 shrink-0"
				src="assets/icons/icon-giftcard.svg"
				svgClass="h-4 w-4 text-success-150"
			/>

			<span class="my-auto block">
				<span data-test-id="receipt-saved-loyal-label">
					{{
						'bookingtool.saved-loyal.label'
							| translate
								: {
										amount: loyalToEarn | price: currency,
								  }
					}}
				</span>
				<vp-tooltip
					alignment="top-start"
					class="inline-block pb-[0.2em] align-middle"
					iconSize="w-4"
					tooltipColor="text-success-150"
				>
					<vp-tooltip-content>
						{{ 'bookingtool.saved-loyal.tooltip' | translate }}
					</vp-tooltip-content>
				</vp-tooltip>
			</span>
		</p>
	}
</section>
