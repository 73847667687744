import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'whatsapp',
	pure: true,
	standalone: false,
})
export class WhatsappPipe implements PipeTransform {
	transform(value: string) {
		const match = RegExp(/^\+(\d+)/).exec(value);
		if (!match) return value.replace(/\D/g, '');

		const countryCode = match[1];
		let formattedCode = countryCode;

		while (formattedCode.length > 1 && formattedCode.startsWith('0')) {
			formattedCode = formattedCode.substring(1);
		}

		return (
			'https://wa.me/' +
			formattedCode +
			value.substring(countryCode.length + 1).replace(/\D/g, '')
		);
	}
}
