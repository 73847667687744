<section
	[editableInStoryblok]="_editable"
	[ngClass]="{
		'bg-accent-25': sectionColor === 'accent',
		'bg-white': sectionColor !== 'accent',
	}"
	class="section-spacing"
>
	<div class="section-inner section-gap">
		@if (components) {
			@for (titleBlok of titleInput; track $index) {
				<div
					[ngClass]="{
						'mb-4 md:mb-6 lg:mb-10':
							titleBlok['component'] === 'headingGroupSection',
					}"
					class="w-full"
				>
					<ndc-dynamic
						[ndcDynamicComponent]="
							components[titleBlok['component']]
						"
						[ndcDynamicInputs]="titleBlok"
						class="hidden"
					/>
				</div>
			}
			@for (blok of columnsInput; track $index) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				/>
			}
		}
	</div>
</section>
