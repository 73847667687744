import { CommonModule } from '@angular/common';
import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
} from '@angular/core';

import { SwiperOptions } from 'swiper/types/swiper-options';

import { ImageInterface } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MiniCarouselComponent as UIMiniCarousel } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	ImageAspectRatio,
	StoryblokImage,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-mini-carousel',
	templateUrl: './mini-carousel.html',
	imports: [CommonModule, StoryblokDirective, UIMiniCarousel],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	host: { class: 'flex w-full' },
})
export class MiniCarouselComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() config: Partial<SwiperOptions> = {};
	@Input({ required: true }) items: StoryblokImage[] = [];
	@Input({ required: true }) aspectRatio: ImageAspectRatio = '16:9';

	carouselItems: ImageInterface[] = [];

	ngOnInit() {
		this.carouselItems = this.items
			.filter(
				(item) =>
					item.bynder?.length > 0 &&
					item.bynder[0].files?.transformBaseUrl?.url,
			)
			.map((item) => {
				return {
					src: item.bynder[0].files.transformBaseUrl.url,
					width: 0,
					height: 0,
				};
			});
	}
}
