/**
 * name: @storyblok/js
 * (c) 2025
 * description: SDK to integrate Storyblok into your project using JavaScript.
 * author: undefined
 */
let H = !1;
const U = [],
  F = i => new Promise((e, t) => {
    if (typeof window > "u" || (window.storyblokRegisterEvent = r => {
      if (window.location === window.parent.location) {
        console.warn("You are not in Draft Mode or in the Visual Editor.");
        return;
      }
      H ? r() : U.push(r);
    }, document.getElementById("storyblok-javascript-bridge"))) return;
    const s = document.createElement("script");
    s.async = !0, s.src = i, s.id = "storyblok-javascript-bridge", s.onerror = r => t(r), s.onload = r => {
      U.forEach(o => o()), H = !0, e(r);
    }, document.getElementsByTagName("head")[0].appendChild(s);
  });
var Q = Object.defineProperty,
  Z = (i, e, t) => e in i ? Q(i, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : i[e] = t,
  p = (i, e, t) => Z(i, typeof e != "symbol" ? e + "" : e, t);
class ee extends Error {
  constructor(e) {
    super(e), this.name = "AbortError";
  }
}
function te(i, e, t) {
  if (!Number.isFinite(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!Number.isFinite(t)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    o = 0,
    n = !1;
  const l = async () => {
      o++;
      const c = s.shift();
      if (c) try {
        const d = await i(...c.args);
        c.resolve(d);
      } catch (d) {
        c.reject(d);
      }
      const u = setTimeout(() => {
        o--, s.length > 0 && l(), r = r.filter(d => d !== u);
      }, t);
      r.includes(u) || r.push(u);
    },
    a = (...c) => n ? Promise.reject(new Error("Throttled function is already aborted and not accepting new promises")) : new Promise((u, d) => {
      s.push({
        resolve: u,
        reject: d,
        args: c
      }), o < e && l();
    });
  return a.abort = () => {
    n = !0, r.forEach(clearTimeout), r = [], s.forEach(c => c.reject(() => new ee("Throttle function aborted"))), s.length = 0;
  }, a;
}
class A {
  constructor() {
    p(this, "isCDNUrl", (e = "") => e.includes("/cdn/")), p(this, "getOptionsPage", (e, t = 25, s = 1) => ({
      ...e,
      per_page: t,
      page: s
    })), p(this, "delay", e => new Promise(t => setTimeout(t, e))), p(this, "arrayFrom", (e = 0, t) => Array.from({
      length: e
    }, t)), p(this, "range", (e = 0, t = e) => {
      const s = Math.abs(t - e) || 0,
        r = e < t ? 1 : -1;
      return this.arrayFrom(s, (o, n) => n * r + e);
    }), p(this, "asyncMap", async (e, t) => Promise.all(e.map(t))), p(this, "flatMap", (e = [], t) => e.map(t).reduce((s, r) => [...s, ...r], [])), p(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        s = /[&<>"']/g,
        r = new RegExp(s.source);
      return e && r.test(e) ? e.replace(s, o => t[o]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {object} params
   * @param  {string} prefix
   * @param  {boolean} isArray
   * @return {string} Stringified object
   */
  stringify(e, t, s) {
    const r = [];
    for (const o in e) {
      if (!Object.prototype.hasOwnProperty.call(e, o)) continue;
      const n = e[o],
        l = s ? "" : encodeURIComponent(o);
      let a;
      typeof n == "object" ? a = this.stringify(n, t ? t + encodeURIComponent(`[${l}]`) : l, Array.isArray(n)) : a = `${t ? t + encodeURIComponent(`[${l}]`) : l}=${encodeURIComponent(n)}`, r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {string} regionCode region code, could be eu, us, cn, ap or ca
   * @return {string} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn",
      o = "api-ap.storyblok.com",
      n = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return o;
      case "ca":
        return n;
      default:
        return t;
    }
  }
}
const se = function (i, e) {
    const t = {};
    for (const s in i) {
      const r = i[s];
      e.includes(s) && r !== null && (t[s] = r);
    }
    return t;
  },
  re = i => i === "email",
  ie = () => ({
    singleTag: "hr"
  }),
  oe = () => ({
    tag: "blockquote"
  }),
  ne = () => ({
    tag: "ul"
  }),
  ae = i => ({
    tag: ["pre", {
      tag: "code",
      attrs: i.attrs
    }]
  }),
  le = () => ({
    singleTag: "br"
  }),
  ce = i => ({
    tag: `h${i.attrs.level}`
  }),
  he = i => ({
    singleTag: [{
      tag: "img",
      attrs: se(i.attrs, ["src", "alt", "title"])
    }]
  }),
  ue = () => ({
    tag: "li"
  }),
  de = () => ({
    tag: "ol"
  }),
  pe = () => ({
    tag: "p"
  }),
  ge = i => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": i.attrs.name,
        emoji: i.attrs.emoji
      }
    }]
  }),
  fe = () => ({
    tag: "b"
  }),
  me = () => ({
    tag: "s"
  }),
  ye = () => ({
    tag: "u"
  }),
  be = () => ({
    tag: "strong"
  }),
  ke = () => ({
    tag: "code"
  }),
  ve = () => ({
    tag: "i"
  }),
  $e = i => {
    if (!i.attrs) return {
      tag: ""
    };
    const e = new A().escapeHTML,
      t = {
        ...i.attrs
      },
      {
        linktype: s = "url"
      } = i.attrs;
    if (delete t.linktype, t.href && (t.href = e(i.attrs.href || "")), re(s) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const r in t.custom) t[r] = t.custom[r];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  Te = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  we = () => ({
    tag: "sub"
  }),
  Re = () => ({
    tag: "sup"
  }),
  Se = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  _e = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${i.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  Ee = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${i.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  je = {
    nodes: {
      horizontal_rule: ie,
      blockquote: oe,
      bullet_list: ne,
      code_block: ae,
      hard_break: le,
      heading: ce,
      image: he,
      list_item: ue,
      ordered_list: de,
      paragraph: pe,
      emoji: ge
    },
    marks: {
      bold: fe,
      strike: me,
      underline: ye,
      strong: be,
      code: ke,
      italic: ve,
      link: $e,
      styled: Te,
      subscript: we,
      superscript: Re,
      anchor: Se,
      highlight: _e,
      textStyle: Ee
    }
  },
  Ie = function (i) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      s = new RegExp(t.source);
    return i && s.test(i) ? i.replace(t, r => e[r]) : i;
  };
let z = !1;
class xe {
  constructor(e) {
    p(this, "marks"), p(this, "nodes"), e || (e = je), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }, s = !0) {
    if (!z && s && (console.warn("Warning ⚠️: The RichTextResolver class is deprecated and will be removed in the next major release. Please use the `@storyblok/richtext` package instead. https://github.com/storyblok/richtext/"), z = !0), e && e.content && Array.isArray(e.content)) {
      let r = "";
      return e.content.forEach(o => {
        r += this.renderNode(o);
      }), t.optimizeImages ? this.optimizeImages(r, t.optimizeImages) : r;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
      The "content" field must be an array of nodes as the type ISbRichtext.
      ISbRichtext:
        content?: ISbRichtext[]
        marks?: ISbRichtext[]
        attrs?: any
        text?: string
        type: string
        
        Example:
        {
          content: [
            {
              content: [
                {
                  text: 'Hello World',
                  type: 'text'
                }
              ],
              type: 'paragraph'
            }
          ],
          type: 'doc'
        }`), "";
  }
  optimizeImages(e, t) {
    let s = 0,
      r = 0,
      o = "",
      n = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (o += `width="${t.width}" `, s = t.width), typeof t.height == "number" && t.height > 0 && (o += `height="${t.height}" `, r = t.height), (t.loading === "lazy" || t.loading === "eager") && (o += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (o += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (n += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (n += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-F]{6}/gi) || t.filters.fill === "transparent") && (n += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (n += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (n += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (n += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (n += `:rotate(${t.filters.rotate})`), n.length > 0 && (n = `/filters${n}`))), o.length > 0 && (e = e.replace(/<img/g, `<img ${o.trim()}`));
    const l = s > 0 || r > 0 || n.length > 0 ? `${s}x${r}${n}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${l}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var c, u;
      const d = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|bmp)/g);
      if (d && d.length > 0) {
        const m = {
          srcset: (c = t.srcset) == null ? void 0 : c.map(b => {
            if (typeof b == "number") return `//${d}/m/${b}x0${n} ${b}w`;
            if (typeof b == "object" && b.length === 2) {
              let S = 0,
                j = 0;
              return typeof b[0] == "number" && (S = b[0]), typeof b[1] == "number" && (j = b[1]), `//${d}/m/${S}x${j}${n} ${S}w`;
            }
            return "";
          }).join(", "),
          sizes: (u = t.sizes) == null ? void 0 : u.map(b => b).join(", ")
        };
        let y = "";
        return m.srcset && (y += `srcset="${m.srcset}" `), m.sizes && (y += `sizes="${m.sizes}" `), a.replace(/<img/g, `<img ${y.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(r => {
      const o = this.getMatchingMark(r);
      o && o.tag !== "" && t.push(this.renderOpeningTag(o.tag));
    });
    const s = this.getMatchingNode(e);
    return s && s.tag && t.push(this.renderOpeningTag(s.tag)), e.content ? e.content.forEach(r => {
      t.push(this.renderNode(r));
    }) : e.text ? t.push(Ie(e.text)) : s && s.singleTag ? t.push(this.renderTag(s.singleTag, " /")) : s && s.html ? t.push(s.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), s && s.tag && t.push(this.renderClosingTag(s.tag)), e.marks && e.marks.slice(0).reverse().forEach(r => {
      const o = this.getMatchingMark(r);
      o && o.tag !== "" && t.push(this.renderClosingTag(o.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let r = `<${s.tag}`;
        if (s.attrs) {
          for (const o in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, o)) {
            const n = s.attrs[o];
            n !== null && (r += ` ${o}="${n}"`);
          }
        }
        return `${r}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(t => t.constructor === String ? `</${t}>` : `</${t.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const L = xe;
class Oe {
  constructor(e) {
    p(this, "baseURL"), p(this, "timeout"), p(this, "headers"), p(this, "responseInterceptor"), p(this, "fetch"), p(this, "ejectInterceptor"), p(this, "url"), p(this, "parameters"), p(this, "fetchOptions"), this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t ?? {}, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(r => {
      s.data = r;
    }));
    for (const r of e.headers.entries()) t[r[0]] = r[1];
    return s.headers = {
      ...t
    }, s.status = e.status, s.statusText = e.statusText, s;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      s = null;
    if (e === "get") {
      const a = new A();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(t),
      o = new AbortController(),
      {
        signal: n
      } = o;
    let l;
    this.timeout && (l = setTimeout(() => o.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: e,
        headers: this.headers,
        body: s,
        signal: n,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const c = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(c)) : this._statusHandler(c);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((s, r) => {
      if (t.test(`${e.status}`)) return s(e);
      const o = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      r(o);
    });
  }
}
const Ae = Oe,
  D = "SB-Agent",
  P = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let O = {};
const E = {};
class Le {
  /**
   *
   * @param config ISbConfig interface
   * @param pEndpoint string, optional
   */
  constructor(e, t) {
    p(this, "client"), p(this, "maxRetries"), p(this, "retriesDelay"), p(this, "throttle"), p(this, "accessToken"), p(this, "cache"), p(this, "helpers"), p(this, "resolveCounter"), p(this, "relations"), p(this, "links"), p(this, "richTextResolver"), p(this, "resolveNestedRelations"), p(this, "stringifiedStoriesCache");
    let s = e.endpoint || t;
    if (!s) {
      const n = new A().getRegionURL,
        l = e.https === !1 ? "http" : "https";
      e.oauthToken ? s = `${l}://${n(e.region)}/v1` : s = `${l}://${n(e.region)}/v2`;
    }
    const r = new Headers();
    r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), e.headers && (e.headers.constructor.name === "Headers" ? e.headers.entries().toArray() : Object.entries(e.headers)).forEach(([n, l]) => {
      r.set(n, l);
    }), r.has(D) || (r.set(D, P.defaultAgentName), r.set(P.defaultAgentVersion, P.packageVersion));
    let o = 5;
    e.oauthToken && (r.set("Authorization", e.oauthToken), o = 3), e.rateLimit && (o = e.rateLimit), e.richTextSchema ? this.richTextResolver = new L(e.richTextSchema) : this.richTextResolver = new L(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = te(this.throttledRequest.bind(this), o, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new A(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new Ae({
      baseURL: s,
      timeout: e.timeout || 0,
      headers: r,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let s = "";
      return t.attrs.body && t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = E[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, s, r, o) {
    const n = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, s, r));
    return this.cacheResponse(e, n, void 0, o);
  }
  get(e, t, s) {
    t || (t = {});
    const r = `/${e}`,
      o = this.factoryParamOptions(r, t);
    return this.cacheResponse(r, o, void 0, s);
  }
  async getAll(e, t, s, r) {
    const o = (t == null ? void 0 : t.per_page) || 25,
      n = `/${e}`.replace(/\/$/, ""),
      l = s ?? n.substring(n.lastIndexOf("/") + 1),
      a = 1,
      c = await this.makeRequest(n, t, o, a, r),
      u = c.total ? Math.ceil(c.total / o) : 1,
      d = await this.helpers.asyncMap(this.helpers.range(a, u), m => this.makeRequest(n, t, o, m + 1, r));
    return this.helpers.flatMap([c, ...d], m => Object.values(m.data[l]));
  }
  post(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("post", r, t, s));
  }
  put(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("put", r, t, s));
  }
  delete(e, t, s) {
    t || (t = {});
    const r = `/${e}`;
    return Promise.resolve(this.throttle("delete", r, t, s));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, s) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, s);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, s) {
    const r = e[t];
    r && r.fieldtype === "multilink" && r.linktype === "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? JSON.parse(this.stringifiedStoriesCache[t] || JSON.stringify(this.relations[e][t])) : t;
  }
  /**
   * Resolves a field's value by replacing UUIDs with their corresponding story references
   * @param jtree - The JSON tree object containing the field to resolve
   * @param treeItem - The key of the field to resolve
   * @param resolveId - The unique identifier for the current resolution context
   *
   * This method handles both single string UUIDs and arrays of UUIDs:
   * - For single strings: directly replaces the UUID with the story reference
   * - For arrays: maps through each UUID and replaces with corresponding story references
   */
  _resolveField(e, t, s) {
    const r = e[t];
    typeof r == "string" ? e[t] = this.getStoryReference(s, r) : Array.isArray(r) && (e[t] = r.map(o => this.getStoryReference(s, o)).filter(Boolean));
  }
  /**
   * Inserts relations into the JSON tree by resolving references
   * @param jtree - The JSON tree object to process
   * @param treeItem - The current field being processed
   * @param fields - The relation patterns to resolve (string or array of strings)
   * @param resolveId - The unique identifier for the current resolution context
   *
   * This method handles two types of relation patterns:
   * 1. Nested relations: matches fields that end with the current field name
   *    Example: If treeItem is "event_type", it matches patterns like "*.event_type"
   *
   * 2. Direct component relations: matches exact component.field patterns
   *    Example: "event.event_type" for component "event" and field "event_type"
   *
   * The method supports both string and array formats for the fields parameter,
   * allowing flexible specification of relation patterns.
   */
  _insertRelations(e, t, s, r) {
    if (Array.isArray(s) ? s.find(n => n.endsWith(`.${t}`)) : s.endsWith(`.${t}`)) {
      this._resolveField(e, t, r);
      return;
    }
    const o = e.component ? `${e.component}.${t}` : t;
    (Array.isArray(s) ? s.includes(o) : s === o) && this._resolveField(e, t, r);
  }
  /**
   * Recursively traverses and resolves relations in the story content tree
   * @param story - The story object containing the content to process
   * @param fields - The relation patterns to resolve
   * @param resolveId - The unique identifier for the current resolution context
   */
  iterateTree(e, t, s) {
    const r = (o, n = "") => {
      if (!(!o || o._stopResolving)) {
        if (Array.isArray(o)) o.forEach((l, a) => r(l, `${n}[${a}]`));else if (typeof o == "object") for (const l in o) {
          const a = n ? `${n}.${l}` : l;
          (o.component && o._uid || o.type === "link") && (this._insertRelations(o, l, t, s), this._insertLinks(o, l, s)), r(o[l], a);
        }
      }
    };
    r(e.content);
  }
  async resolveLinks(e, t, s) {
    let r = [];
    if (e.link_uuids) {
      const o = e.link_uuids.length,
        n = [],
        l = 50;
      for (let a = 0; a < o; a += l) {
        const c = Math.min(o, a + l);
        n.push(e.link_uuids.slice(a, c));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(",")
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.links;
    r.forEach(o => {
      this.links[s][o.uuid] = {
        ...o,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, s) {
    let r = [];
    if (e.rel_uuids) {
      const o = e.rel_uuids.length,
        n = [],
        l = 50;
      for (let a = 0; a < o; a += l) {
        const c = Math.min(o, a + l);
        n.push(e.rel_uuids.slice(a, c));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.rels;
    r && r.length > 0 && r.forEach(o => {
      this.relations[s][o.uuid] = {
        ...o,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, s) {
    var r, o;
    let n = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (n = t.resolve_relations.split(",")), await this.resolveRelations(e, t, s)), t.resolve_links && ["1", "story", "url", "link"].includes(t.resolve_links) && ((r = e.links) != null && r.length || (o = e.link_uuids) != null && o.length) && (await this.resolveLinks(e, t, s)), this.resolveNestedRelations) for (const l in this.relations[s]) this.iterateTree(this.relations[s][l], n, s);
    e.story ? this.iterateTree(e.story, n, s) : e.stories.forEach(l => {
      this.iterateTree(l, n, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(e, t, s, r) {
    const o = this.helpers.stringify({
        url: e,
        params: t
      }),
      n = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e !== "/cdn/spaces/me") {
      const l = await n.get(o);
      if (l) return Promise.resolve(l);
    }
    return new Promise(async (l, a) => {
      var c;
      try {
        const u = await this.throttle("get", e, t, r);
        if (u.status !== 200) return a(u);
        let d = {
          data: u.data,
          headers: u.headers
        };
        if ((c = u.headers) != null && c["per-page"] && (d = Object.assign({}, d, {
          perPage: u.headers["per-page"] ? Number.parseInt(u.headers["per-page"]) : 0,
          total: u.headers["per-page"] ? Number.parseInt(u.headers.total) : 0
        })), d.data.story || d.data.stories) {
          const m = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(d.data, t, `${m}`);
        }
        return t.version === "published" && e !== "/cdn/spaces/me" && (await n.set(o, d)), d.data.cv && t.token && E[t.token] !== d.data.cv && (await this.flushCache(), E[t.token] = d.data.cv), l(d);
      } catch (u) {
        if (u.response && u.status === 429 && (s = typeof s > "u" ? 0 : s + 1, s < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, s).then(l).catch(a);
        a(u);
      }
    });
  }
  throttledRequest(e, t, s, r) {
    return this.client.setFetchOptions(r), this.client[e](t, s);
  }
  cacheVersions() {
    return E;
  }
  cacheVersion() {
    return E[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (E[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (E[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(O[e]);
          },
          getAll() {
            return Promise.resolve(O);
          },
          set(e, t) {
            return O[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return O = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
const ze = (i = {}) => {
    const {
      apiOptions: e
    } = i;
    if (!e || !e.accessToken) {
      console.error("You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication");
      return;
    }
    return {
      storyblokApi: new Le(e)
    };
  },
  De = i => {
    if (typeof i != "object" || typeof i._editable > "u") return {};
    try {
      const e = JSON.parse(i._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, ""));
      return e ? {
        "data-blok-c": JSON.stringify(e),
        "data-blok-uid": `${e.id}-${e.uid}`
      } : {};
    } catch {
      return {};
    }
  };
function Ce(i, e) {
  if (!e) return {
    src: i,
    attrs: {}
  };
  let t = 0,
    s = 0;
  const r = {},
    o = [];
  function n(a, c, u, d, m) {
    typeof a != "number" || a <= c || a >= u ? console.warn(`[StoryblokRichText] - ${d.charAt(0).toUpperCase() + d.slice(1)} value must be a number between ${c} and ${u} (inclusive)`) : m.push(`${d}(${a})`);
  }
  if (typeof e == "object") {
    if (typeof e.width == "number" && e.width > 0 ? (r.width = e.width, t = e.width) : console.warn("[StoryblokRichText] - Width value must be a number greater than 0"), e.height && typeof e.height == "number" && e.height > 0 ? (r.height = e.height, s = e.height) : console.warn("[StoryblokRichText] - Height value must be a number greater than 0"), e.loading && ["lazy", "eager"].includes(e.loading) && (r.loading = e.loading), e.class && (r.class = e.class), e.filters) {
      const {
          filters: a
        } = e || {},
        {
          blur: c,
          brightness: u,
          fill: d,
          format: m,
          grayscale: y,
          quality: b,
          rotate: S
        } = a || {};
      c && n(c, 0, 100, "blur", o), b && n(b, 0, 100, "quality", o), u && n(u, 0, 100, "brightness", o), d && o.push(`fill(${d})`), y && o.push("grayscale()"), S && [0, 90, 180, 270].includes(e.filters.rotate || 0) && o.push(`rotate(${S})`), m && ["webp", "png", "jpeg"].includes(m) && o.push(`format(${m})`);
    }
    e.srcset && (r.srcset = e.srcset.map(a => {
      if (typeof a == "number") return `${i}/m/${a}x0/${o.length > 0 ? `filters:${o.join(":")}` : ""} ${a}w`;
      if (Array.isArray(a) && a.length === 2) {
        const [c, u] = a;
        return `${i}/m/${c}x${u}/${o.length > 0 ? `filters:${o.join(":")}` : ""} ${c}w`;
      } else {
        console.warn("[StoryblokRichText] - srcset entry must be a number or a tuple of two numbers");
        return;
      }
    }).join(", ")), e.sizes && (r.sizes = e.sizes.join(", "));
  }
  let l = `${i}/m/`;
  return t > 0 && s > 0 && (l = `${l}${t}x${s}/`), o.length > 0 && (l = `${l}filters:${o.join(":")}`), {
    src: l,
    attrs: r
  };
}
var $ = /* @__PURE__ */(i => (i.DOCUMENT = "doc", i.HEADING = "heading", i.PARAGRAPH = "paragraph", i.QUOTE = "blockquote", i.OL_LIST = "ordered_list", i.UL_LIST = "bullet_list", i.LIST_ITEM = "list_item", i.CODE_BLOCK = "code_block", i.HR = "horizontal_rule", i.BR = "hard_break", i.IMAGE = "image", i.EMOJI = "emoji", i.COMPONENT = "blok", i))($ || {}),
  T = /* @__PURE__ */(i => (i.BOLD = "bold", i.STRONG = "strong", i.STRIKE = "strike", i.UNDERLINE = "underline", i.ITALIC = "italic", i.CODE = "code", i.LINK = "link", i.ANCHOR = "anchor", i.STYLED = "styled", i.SUPERSCRIPT = "superscript", i.SUBSCRIPT = "subscript", i.TEXT_STYLE = "textStyle", i.HIGHLIGHT = "highlight", i))(T || {}),
  G = /* @__PURE__ */(i => (i.TEXT = "text", i))(G || {}),
  I = /* @__PURE__ */(i => (i.URL = "url", i.STORY = "story", i.ASSET = "asset", i.EMAIL = "email", i))(I || {});
const Pe = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"],
  Ne = (i = {}) => Object.keys(i).map(e => `${e}="${i[e]}"`).join(" "),
  Me = (i = {}) => Object.keys(i).map(e => `${e}: ${i[e]}`).join("; ");
function He(i) {
  return i.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
const B = i => Object.fromEntries(Object.entries(i).filter(([e, t]) => t !== void 0));
function q(i, e = {}, t) {
  const s = Ne(e),
    r = s ? `${i} ${s}` : i,
    o = Array.isArray(t) ? t.join("") : t || "";
  if (i) {
    if (Pe.includes(i)) return `<${r}>`;
  } else return o;
  return `<${r}>${o}</${i}>`;
}
function Be(i = {}) {
  let e = 0;
  const {
      renderFn: t = q,
      textFn: s = He,
      resolvers: r = {},
      optimizeImages: o = !1,
      keyedResolvers: n = !1
    } = i,
    l = t !== q,
    a = h => g => {
      const f = g.attrs || {};
      return n && (f.key = `${h}-${e}`), t(h, f, g.children || null);
    },
    c = h => {
      const {
        src: g,
        alt: f,
        title: k,
        srcset: R,
        sizes: v
      } = h.attrs || {};
      let w = g,
        _ = {};
      if (o) {
        const {
          src: W,
          attrs: X
        } = Ce(g, o);
        w = W, _ = X;
      }
      n && (_ = {
        ..._,
        key: `img-${e}`
      });
      const Y = {
        src: w,
        alt: f,
        title: k,
        srcset: R,
        sizes: v,
        ..._
      };
      return t("img", B(Y));
    },
    u = h => {
      const {
          level: g,
          ...f
        } = h.attrs || {},
        k = {
          ...f
        };
      return n && (k.key = `h${g}-${e}`), t(`h${g}`, k, h.children);
    },
    d = h => {
      var g, f, k, R;
      const v = t("img", {
          src: (g = h.attrs) == null ? void 0 : g.fallbackImage,
          alt: (f = h.attrs) == null ? void 0 : f.alt,
          style: "width: 1.25em; height: 1.25em; vertical-align: text-top",
          draggable: "false",
          loading: "lazy"
        }),
        w = {
          "data-type": "emoji",
          "data-name": (k = h.attrs) == null ? void 0 : k.name,
          "data-emoji": (R = h.attrs) == null ? void 0 : R.emoji
        };
      return n && (w.key = `emoji-${e}`), t("span", w, v);
    },
    m = h => t("pre", {
      ...h.attrs,
      key: `code-${e}`
    }, t("code", {
      key: `code-${e}`
    }, h.children || "")),
    y = (h, g = !1) => ({
      text: f,
      attrs: k
    }) => {
      const {
          class: R,
          id: v,
          ...w
        } = k || {},
        _ = g ? {
          class: R,
          id: v,
          style: Me(w) || void 0
        } : k || {};
      return n && (_.key = `${h}-${e}`), t(h, B(_), f);
    },
    b = h => C(h),
    S = h => {
      const {
        marks: g,
        ...f
      } = h;
      return "text" in h ? g ? g.reduce((k, R) => b({
        ...R,
        text: k
      }),
      // Fix: Ensure render function returns a string
      b({
        ...f,
        children: f.children
      })
      // Fix: Cast children to string
      ) : s(f.text) : "";
    },
    j = h => {
      const {
        linktype: g,
        href: f,
        anchor: k,
        ...R
      } = h.attrs || {};
      let v = "";
      switch (g) {
        case I.ASSET:
        case I.URL:
          v = f;
          break;
        case I.EMAIL:
          v = `mailto:${f}`;
          break;
        case I.STORY:
          v = f, k && (v = `${v}#${k}`);
          break;
        default:
          v = f;
          break;
      }
      const w = {
        ...R
      };
      return v && (w.href = v), n && (w.key = `a-${e}`), t("a", w, h.text);
    },
    J = h => {
      var g, f;
      return console.warn("[StoryblokRichtText] - BLOK resolver is not available for vanilla usage"), t("span", {
        blok: (g = h == null ? void 0 : h.attrs) == null ? void 0 : g.body[0],
        id: (f = h.attrs) == null ? void 0 : f.id,
        key: `component-${e}`,
        style: "display: none"
      });
    },
    K = new Map([[$.DOCUMENT, a("")], [$.HEADING, u], [$.PARAGRAPH, a("p")], [$.UL_LIST, a("ul")], [$.OL_LIST, a("ol")], [$.LIST_ITEM, a("li")], [$.IMAGE, c], [$.EMOJI, d], [$.CODE_BLOCK, m], [$.HR, a("hr")], [$.BR, a("br")], [$.QUOTE, a("blockquote")], [$.COMPONENT, J], [G.TEXT, S], [T.LINK, j], [T.ANCHOR, j], [T.STYLED, y("span", !0)], [T.BOLD, y("strong")], [T.TEXT_STYLE, y("span", !0)], [T.ITALIC, y("em")], [T.UNDERLINE, y("u")], [T.STRIKE, y("s")], [T.CODE, y("code")], [T.SUPERSCRIPT, y("sup")], [T.SUBSCRIPT, y("sub")], [T.HIGHLIGHT, y("mark")], ...Object.entries(r).map(([h, g]) => [h, g])]);
  function x(h) {
    e += 1;
    const g = K.get(h.type);
    if (!g) return console.error("<Storyblok>", `No resolver found for node type ${h.type}`), "";
    if (h.type === "text") return g(h);
    const f = h.content ? h.content.map(C) : void 0;
    return g({
      ...h,
      children: f
      // Fix: Update the type of 'children' to Node[]
    });
  }
  function C(h) {
    return h.type === "doc" ? l ? h.content.map(x) : h.content.map(x).join("") : Array.isArray(h) ? h.map(x) : x(h);
  }
  return {
    render: C
  };
}
let N,
  M = "https://app.storyblok.com/f/storyblok-v2-latest.js";
const qe = (i, e, t = {}) => {
    var l;
    const r = !(typeof window > "u") && typeof window.storyblokRegisterEvent < "u",
      o = new URL((l = window.location) == null ? void 0 : l.href).searchParams.get("_storyblok"),
      n = o !== null && +o === i;
    if (!(!r || !n)) {
      if (!i) {
        console.warn("Story ID is not defined. Please provide a valid ID.");
        return;
      }
      window.storyblokRegisterEvent(() => {
        new window.StoryblokBridge(t).on(["input", "published", "change"], c => {
          var u;
          c && (c.action === "input" && ((u = c.story) == null ? void 0 : u.id) === i ? e(c.story) : (c.action === "change" || c.action === "published") && c.storyId === i && window.location.reload());
        });
      });
    }
  },
  V = (i, e) => {
    i.addNode("blok", t => {
      let s = "";
      return t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  },
  Fe = (i = {}) => {
    var d, m;
    const {
      bridge: e,
      accessToken: t,
      use: s = [],
      apiOptions: r = {},
      richText: o = {},
      bridgeUrl: n
    } = i;
    r.accessToken = r.accessToken || t;
    const l = {
      bridge: e,
      apiOptions: r
    };
    let a = {};
    s.forEach(y => {
      a = {
        ...a,
        ...y(l)
      };
    }), n && (M = n);
    const u = !(typeof window > "u") && ((m = (d = window.location) == null ? void 0 : d.search) == null ? void 0 : m.includes("_storyblok_tk"));
    return e !== !1 && u && F(M), N = new L(o.schema), o.resolver && V(N, o.resolver), a;
  },
  Ue = i => {
    var e;
    return !i || !((e = i == null ? void 0 : i.content) != null && e.some(t => t.content || t.type === "blok" || t.type === "horizontal_rule"));
  },
  Ge = (i, e, t) => {
    let s = t || N;
    if (!s) {
      console.error("Please initialize the Storyblok SDK before calling the renderRichText function");
      return;
    }
    return Ue(i) ? "" : (e && (s = new L(e.schema), e.resolver && V(s, e.resolver)), s.render(i, {}, !1));
  },
  Ve = () => F(M);
export { $ as BlockTypes, T as MarkTypes, L as RichTextResolver, je as RichTextSchema, G as TextTypes, ze as apiPlugin, Ue as isRichTextEmpty, Ve as loadStoryblokBridge, qe as registerStoryblokBridge, Ge as renderRichText, Be as richTextResolver, De as storyblokEditable, Fe as storyblokInit, qe as useStoryblokBridge };