/* istanbul ignore next */
/**
 * Class representing the route helper.
 */
export class RouteHelper {
	/**
	 * Redirects to external url by changing the window.location
	 * @param {string} url
	 */
	static redirectToExternalUrl(url: string): void {
		window.location.href = url;
	}

	/**
	 * Redirects to external url by opening a new tab
	 * @param {string} url
	 */
	static redirectToExternalUrlInNewTab(url: string): void {
		window.open(url, '_blank');
	}
}
