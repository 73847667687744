import { FormlyFieldConfig } from '@ngx-formly/core';

export const form: FormlyFieldConfig[] = [
	{
		id: 'email',
		key: 'email',
		props: {
			placeholder: 'global.email.label',
			labelExtras: {
				contentWidth: '',
				alignment: 'top-aligned-stacked',
				noPadding: true,
				errorClass: 'lg:absolute mt-0',
				width: '',
			},
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.email.required-text',
				},
			},
		},
		type: 'email',
	},
];
