<div class="flex flex-col px-8 pb-0 pt-3" data-test-id="desktop">
	<div class="section-inner flex w-full flex-col gap-4">
		<div class="flex flex-col-reverse">
			@if (!hideMenu()) {
				<vp-link
					[link]="logoLink"
					className="flex"
					data-test-id="header-logo"
					type="link-unstyled"
				>
					<ng-container [ngTemplateOutlet]="logo" />
				</vp-link>
			} @else {
				<span class="mt-[38px] flex" data-test-id="header-logo">
					<ng-container [ngTemplateOutlet]="logo" />
				</span>
			}
			@if (metaMenu() && !hideMenu()) {
				<div
					[ngClass]="isNegativeHeader() ? 'text-white' : 'text-black'"
					class="ml-auto flex gap-x-4"
					data-test-id="meta-menu"
				>
					@if (metaMenu().contactLink.url) {
						<vp-link
							[link]="metaMenu().contactLink"
							className="my-1.5 block h-fit"
							type="link-unstyled"
						>
							{{ 'compositions.header.contact' | translate }}
						</vp-link>
					}
					@if (metaMenu().accountLink.url) {
						<vp-link
							[link]="metaMenu().accountLink"
							className="my-1.5 block h-fit"
							type="link-unstyled"
						>
							{{ 'compositions.header.account' | translate }}
						</vp-link>
					}
					@if (metaMenu().showSearch) {
						<button class="my-1.5 h-fit">
							{{ 'compositions.header.search' | translate }}
						</button>
					}
					@if (metaMenu().languages?.options) {
						<vp-dropdown
							[centered]="true"
							[inputId]="'language'"
							[isWhite]="isNegativeHeader()"
							[label]="'global.language-using.label' | translate"
							[placeholder]="
								'global.dutch-abbreviation' | translate
							"
							[selectData]="metaMenu().languages.options"
							[useAsFormControl]="false"
							[value]="
								metaMenu().languages?.selectedLanguageOption
									.path
							"
							(selectedValue)="languageSwitch.emit($event)"
							className="my-1.5 h-fit"
							variant="detached"
						/>
					}
					@if (metaMenu().cta?.title && metaMenu().cta.url) {
						<vp-link
							[link]="metaMenu().cta"
							className="btn-sm h-fit"
							type="button"
						>
							{{ metaMenu().cta.title }}
						</vp-link>
					}
				</div>
			}
		</div>
		@if (!hideMenu()) {
			<div class="flex">
				<ul
					class="flex h-fit flex-wrap items-start gap-x-4"
					data-test-id="desktop-menu"
				>
					@for (
						menuItem of menuItems();
						track menuItem;
						let index = $index;
						let count = $count
					) {
						@if (
							(index < MAX_NUMBER_MENU_ITEMS - 1 &&
								count > MAX_NUMBER_MENU_ITEMS) ||
							(index < MAX_NUMBER_MENU_ITEMS &&
								count <= MAX_NUMBER_MENU_ITEMS)
						) {
							<li
								[ngClass]="
									isNegativeHeader()
										? 'text-shadow text-white hover:border-white'
										: 'text-black hover:border-black'
								"
								[routerLinkActive]="'active'"
								[routerLinkActiveOptions]="{
									exact:
										menuItem.title.toLowerCase() === 'home',
								}"
								class="flex h-full items-center border-b-2 border-transparent py-2 text-lg"
								data-test-class="desktop-menu-item"
							>
								<a
									[routerLink]="
										!menuItem.url.startsWith('http')
											? menuItem.url
											: null
									"
									type="link-unstyled"
								>
									{{ menuItem.title }}
								</a>
							</li>
						}
					}
					@if (menuItems().length > MAX_NUMBER_MENU_ITEMS) {
						<li data-test-id="more-menu-trigger">
							<vp-popover
								[closeable]="false"
								[isFullHeight]="true"
								[noPadding]="true"
								(isOpen)="showingMoreMenu = $event"
								alignment="bottom-end"
								popoverId="popover"
								triggerId="more-menu-trigger-button"
							>
								<button
									#popoverTrigger
									[ngClass]="
										isNegativeHeader()
											? 'text-shadow text-white'
											: 'text-black'
									"
									class="flex h-full cursor-pointer items-center border-b-2 border-transparent py-2 text-lg"
									id="more-menu-trigger-button"
									vc-clicked
								>
									{{ 'compositions.header.more' | translate }}
									<svg-icon
										[ngClass]="
											showingMoreMenu ? 'rotate-180' : ''
										"
										class="ml-2 size-5 cursor-pointer"
										src="assets/icons/icon-chevron-down.svg"
									/>
								</button>
								<vp-popover-content>
									<vp-link-list
										[linkListItems]="moreMenuItems()"
										[showIcons]="false"
									/>
								</vp-popover-content>
							</vp-popover>
						</li>
					}
				</ul>
			</div>
		}
	</div>
</div>

<ng-template #logo>
	<img
		[src]="
			isNegativeHeader()
				? 'assets/icons/logo-valk-white.svg'
				: 'assets/icons/logo-valk-black.svg'
		"
		alt="Van der Valk Logo"
		class="h-12 w-[42px]"
		height="48"
		width="42"
	/>
	<div
		[ngClass]="isNegativeHeader() ? 'text-white' : 'text-black'"
		class="ml-3 flex flex-col border-l pl-3 font-logo uppercase"
	>
		<span
			[ngClass]="{ 'mt-3': !title() }"
			class="mb-0.5 block text-2xl leading-none"
			data-test-id="brand-name"
		>
			{{ 'global.brand-name' | translate }}
		</span>
		@if (title()) {
			<span
				class="block text-[32px] font-semibold leading-none"
				data-test-id="title"
			>
				{{ title() }}
			</span>
		}
	</div>
</ng-template>
