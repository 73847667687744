<div class="mx-auto max-w-screen-md">
	<div
		[editableInStoryblok]="_editable"
		class="card card-lg"
		data-test-id="balance-giftcard"
	>
		@if (showSummaryBalance) {
			<vp-alert
				[content]="
					'storyblok.giftcard.total-balance'
						| translate
							: {
									amount:
										giftcardBalance / 100
										| currency
											: currency
											: 'symbol-narrow'
											: '1.2-2'
											: language,
							  }
				"
				[emphasized]="true"
				data-test-id="alert"
				type="dialog--success"
			/>
		}
		@if (showSummaryInactive) {
			<vp-alert
				[content]="'storyblok.giftcard.inactive-card-text' | translate"
				[emphasized]="true"
				[title]="'storyblok.giftcard.inactive-card-title' | translate"
				data-test-id="alert"
				type="dialog--warning"
			/>
		}
		@if (showSummaryWarning) {
			<vp-alert
				[content]="
					'storyblok.giftcard.balance-warning-text' | translate
				"
				[emphasized]="true"
				[title]="'storyblok.giftcard.balance-warning-title' | translate"
				data-test-id="alert"
				type="dialog--warning"
			/>
		}
		<form
			#balanceForm="ngForm"
			[formGroup]="form"
			[ngClass]="{
				hidden: giftcardSubmitted,
			}"
			(ngSubmit)="form.valid && onSubmit($any(form.value))"
		>
			<formly-form [fields]="formFields" [form]="form" />
			<button
				[ngClass]="{
					'btn-is-loading': giftcardSubmitting,
				}"
				class="btn btn-primary mt-6 w-full md:mt-8"
				data-test-id="balance-button"
				type="submit"
			>
				{{ 'storyblok.giftcard.check-balance' | translate }}
			</button>
			<div class="mt-3 md:mt-4">
				<vp-recaptcha-notification [currentLanguage]="language" />
			</div>
		</form>
		@if (showSummaryBalance) {
			<div class="mt-4 md:mt-6">
				<span class="block font-bold" data-test-id="card-number-label">
					{{ 'storyblok.giftcard.activated-card' | translate }}
				</span>
				<span class="block" data-test-id="card-number">
					{{ form.value['cardNumber'] }}
				</span>
				<button
					(click)="resetGiftcardForm()"
					class="text-link mt-4 md:mt-6"
					data-test-id="summary-action"
				>
					{{ 'storyblok.giftcard.return-balance-check' | translate }}
				</button>
			</div>
		}
		@if (showSummaryWarning || showSummaryInactive) {
			<div class="mt-4 md:mt-6">
				<span class="block font-bold" data-test-id="card-number-label">
					{{ 'storyblok.giftcard.card-number.label' | translate }}
				</span>
				<span class="block" data-test-id="card-number">
					{{ form.value['cardNumber'] }}
				</span>
				<span class="block font-bold" data-test-id="pincode-label">
					{{ 'storyblok.giftcard.pin-code.label' | translate }}
				</span>
				<span class="block" data-test-id="pincode">
					{{ form.value['pinCode'] }}
				</span>
				@if (showSummaryWarning) {
					<button
						(click)="changeGiftCardForm()"
						class="text-link mt-4 md:mt-6"
						data-test-id="summary-action"
					>
						{{ 'storyblok.giftcard.change-data' | translate }}
					</button>
				}
				@if (showSummaryInactive && activateGiftcardPage) {
					<button
						(click)="goToActivateGiftCardForm()"
						class="text-link mt-4 md:mt-6"
						data-test-id="summary-action"
					>
						{{ 'storyblok.giftcard.activate-card' | translate }}
					</button>
				}
			</div>
		}
	</div>
</div>
