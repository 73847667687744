import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';

import { filter, map, Observable, shareReplay } from 'rxjs';

import { LANGUAGE, Language } from '@valk-nx/core/lib/core';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { RootInterface } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Injectable({
	providedIn: 'root',
})
export class StoryblokParamsService {
	public readonly storyblokParams$: Observable<RootInterface>;
	private readonly storyblokQueryParams$: Observable<Params>;

	constructor(
		router: Router,
		@Inject(LANGUAGE) protected language: Language,
	) {
		const navigationEnd$ = router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			shareReplay(1),
		);

		this.storyblokParams$ = navigationEnd$.pipe(
			// NOTE: We use the first child of root to get the activated route, as for StoryBlok, this is always the right one
			map(() => router.routerState.root.firstChild),
			filter((activatedRoute) => !!activatedRoute),
			map((activatedRoute) =>
				StoryblokHelper.getStoryblokParameters(
					activatedRoute.snapshot.url,
					activatedRoute.snapshot.queryParams,
					this.language,
				),
			),
			shareReplay(1),
		);

		this.storyblokQueryParams$ = navigationEnd$.pipe(
			// NOTE: We use the first child of root to get the activated route, as for StoryBlok, this is always the right one
			map(() => router.routerState.root.firstChild),
			filter((activatedRoute) => !!activatedRoute),
			map((activatedRoute) => activatedRoute.snapshot.queryParams),
			shareReplay(1),
		);
	}

	getFromUrlParam(paramName: string): Observable<string> {
		return this.storyblokQueryParams$.pipe(
			filter((params) => params[paramName]),
			map((params) => params[paramName]),
		);
	}
}
