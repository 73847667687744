import { apiPlugin, StoryblokClient, storyblokInit } from '@storyblok/js';

import { languages } from '@valk-nx/core/lib/core';

import { StoryblokHelper } from './storyblok.helper';

export interface SiteMapEntry {
	loc: string;
	changefreq: string;
	priority: number;
	last_mod: undefined;
}

export interface StoryblokLink {
	real_path: string;
	alternates: { lang: string; path: string }[];
}
export interface StoryblokItems {
	items: { item: { cached_url: string } }[];
}

/* istanbul ignore next */
export async function getSitemap(
	sbToken: string,
	domain: string,
): Promise<SiteMapEntry[]> {
	try {
		const storyblokApi = storyblokInit({
			accessToken: sbToken,
			use: [apiPlugin],
		}).storyblokApi as StoryblokClient;

		const excludeUrls = await getExcludeList(storyblokApi, domain);

		const links = (
			await storyblokApi.getAll('cdn/links', {
				language: 'nl',
				per_page: 1000,
				resolve_links: 'link',
				version: 'published',
			})
		).filter(
			(link) =>
				!link.slug.startsWith('global') &&
				link.slug !== 'error-404' &&
				link.slug !== 'redirects',
		);

		const allSitemapData = parseSitemapData(links, domain);

		const filteredSitemapData = new Set();
		const sitemapData = allSitemapData.filter((obj) => {
			if (filteredSitemapData.has(obj.loc)) {
				return false;
			} else {
				filteredSitemapData.add(obj.loc);
				return true;
			}
		});

		return sitemapData.filter((data) => !excludeUrls.includes(data.loc));
	} catch (_error) {
		return [];
	}
}

export async function getExcludeList(
	api: StoryblokClient,
	domain: string,
): Promise<string[]> {
	const sitemapExcludes = languages.map(async (language) => {
		const global = await api.get('cdn/stories/globals', {
			language,
			resolve_links: 'link',
			version: 'published',
		});
		const { story, links } = global.data;
		const data = StoryblokHelper.mapLanguageUrlSlug(
			story,
			links,
			language,
			'item',
		);
		const { content } = data.content;
		const config = content.filter(
			(item: { component: string }) => item.component === 'sitemapConfig',
		);
		return getCachedUrls(config, domain);
	});

	return await Promise.all(sitemapExcludes).then((result) => result.flat());
}

export function getCachedUrls(
	inputArray: StoryblokItems[],
	domain: string,
): string[] {
	if (!Array.isArray(inputArray) || inputArray.length === 0) {
		return [];
	}

	const cachedUrls = inputArray.flatMap((obj) =>
		obj.items.map((item: { item: { cached_url: string } }) => {
			const route = removeTrailingSlash(item.item.cached_url).startsWith(
				'/',
			)
				? removeTrailingSlash(item.item.cached_url)
				: `/${removeTrailingSlash(item.item.cached_url)}`;
			return `${domain}${route}`;
		}),
	);

	return cachedUrls;
}

export function parseSitemapData(
	links: StoryblokLink[],
	domain: string,
): SiteMapEntry[] {
	return links
		.map((result) => {
			const baseConfig = {
				changefreq: 'weekly',
				priority: 0.5,
				last_mod: undefined,
			};
			const path = result.real_path.endsWith('/home')
				? ''
				: result.real_path;
			const baseData = {
				loc: `${domain}${removeTrailingSlash(path)}`,
				...baseConfig,
			};

			const alternates = result.alternates.map((alt) => {
				const altPath = result.real_path.endsWith('/home')
					? ''
					: `/${removeTrailingSlash(alt.path)}`;
				return {
					loc: `${domain}/${alt.lang}${altPath}`,
					...baseConfig,
				};
			});

			alternates.push(baseData);

			return alternates;
		})
		.flat();
}

export function removeTrailingSlash(value: string) {
	return value.endsWith('/') ? value.slice(0, -1) : value;
}
