import {
	i18nLanguageLabels,
	Language,
	languages,
} from '@valk-nx/core/lib/core';

/**
 * Class representing the Langauge Switcher helper.
 */
export class LanguageSwitchHelper {
	static showLanguageSwitch(path: string): boolean {
		return (
			LanguageSwitchHelper.isPage(path, 'configuration') ||
			LanguageSwitchHelper.isHomepage(path)
		);
	}

	static showLoginHeader(path: string): boolean {
		return (
			LanguageSwitchHelper.isPage(path, 'configuration') ||
			LanguageSwitchHelper.isPage(path, 'account') ||
			LanguageSwitchHelper.isPage(path, 'personal-info')
		);
	}

	static isPage(path: string, expectedInPath: string): boolean {
		if (typeof path !== 'string') return false;

		const splitQueryParamsPath = path.split('?');
		const splittedPaths = splitQueryParamsPath[0].split('/');
		return splittedPaths.includes(expectedInPath) && !path.endsWith('/');
	}

	static isHomepage(path: string): boolean {
		if (typeof path !== 'string') return false;

		return (
			path === '' ||
			path === '/' ||
			// deepcode ignore GlobalReplacementRegex
			LanguageSwitchHelper.isLanguageCode(path.replace('/', ''))
		);
	}

	static isLanguageCode(lang: string): lang is Language {
		return languages.includes(lang as Language);
	}

	static geti18nLanguageLabel(
		lang: Language,
		fallbackLabel: string | null = null,
	): string | null {
		return i18nLanguageLabels[lang]
			? i18nLanguageLabels[lang]
			: fallbackLabel;
	}
}
