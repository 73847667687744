import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { BannerVariants } from './banner.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [NgClass],
	selector: 'vp-banner',
	templateUrl: './banner.html',
})
export class BannerComponent {
	@Input() variant: BannerVariants = 'accent';
}
