import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

/**
 * Price component
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-price',
	templateUrl: './price.html',
	imports: [CommonModule],
})
export class PriceComponent extends BaseDirective {
	@Input() size: 'sm' | 'base' | 'lg' | 'xl' = 'base';
	@Input() striked = false;
	@Input({ required: true }) set price(price: number) {
		this._price = price / 100;
		this.formatPrice();
	}
	@Input({ required: true }) set locale(locale: string) {
		this._locale = locale;
		this.formatPrice();
	}
	@Input({ required: true }) set currency(currency: string) {
		this._currency = currency;
		this.formatPrice();
	}

	_price = 0;
	_locale = 'nl';
	_currency = 'EUR';

	priceFormatted: Intl.NumberFormatPart[] = [];

	formatPrice() {
		const formatter = new Intl.NumberFormat(this._locale, {
			style: 'currency',
			currency: this._currency,
			currencyDisplay: 'narrowSymbol',
		});
		this.priceFormatted = formatter.formatToParts(this._price);
	}
}
