import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	Inject,
	input,
	PLATFORM_ID,
	ViewEncapsulation,
} from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

import { TranslateService } from '@ngx-translate/core';

import { MAPS_SERVICE_KEY } from '@valk-nx/core/lib/core';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-map',
	templateUrl: './map.html',
	imports: [CommonModule, GoogleMapsModule, SharedModule],
})
export class MapComponent extends BaseDirective {
	long = input<number>();
	lat = input<number>();
	zoom = input<number>(14);

	coordinates = computed(() => {
		if (this.lat() && this.long()) {
			return { lat: +this.lat(), lng: +this.long() };
		}
		return null;
	});

	mapOptions: google.maps.MapOptions = {
		mapId: 'ab0a4b55dc014712',
		backgroundColor: 'white',
	};
	mapAdvancedMarkerOptions: google.maps.marker.AdvancedMarkerElementOptions =
		{ gmpClickable: true };

	constructor(
		@Inject(PLATFORM_ID) readonly platformId: string,
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(MAPS_SERVICE_KEY) private readonly serviceKey: string,
		override readonly translate: TranslateService,
	) {
		super(translate);
		this.initApi();
	}

	initApi() {
		if (
			isPlatformBrowser(this.platformId) &&
			!this.document['googleMapsApiLoaded']
		) {
			const script = this.document.createElement('script');
			const scriptString = `(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({key:'${this.serviceKey}',v:"weekly"});`;
			script.text = scriptString;
			script.type = 'text/javascript';

			this.document.head.appendChild(script);

			// Set a flag to indicate that the script already has been added
			this.document['googleMapsApiLoaded'] = true;
		}
	}
}
