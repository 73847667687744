<ng-container *ngrxLet="isSmallTablet$ as isSmallTablet">
	<div
		[excludeFromClosingRegex]="excludeFromClosingRegex"
		[ngClass]="{ 'flex h-full': fullHeightContainer }"
		(clickOutside)="hide()"
		vc-click-outside
	>
		<ng-content />
		<div
			[id]="popoverId"
			[ngClass]="[
				showPopover ? 'visible' : 'hidden',
				isSmallTablet
					? '!fixed !left-0 !w-full !transform-none transition-sheet duration-500'
					: '',
				showPopover && isSmallTablet
					? '!top-16'
					: !showPopover && isSmallTablet
						? '!top-[150%]'
						: '',
				isFullHeight && isSmallTablet ? '!h-[calc(100%-64px)]' : '',
				!isFullHeight && isSmallTablet
					? '!bottom-0 !top-[unset] h-auto'
					: '',
			]"
			class="!-bottom-0 !top-[unset] z-30 !h-fit max-h-screen w-fit overflow-visible md:!top-0 md:overflow-visible"
			data-test-id="popover"
		>
			<div
				#popoverContent
				[class]="className"
				[ngClass]="{
					hidden: isSSRMode,
					'w-full': isSmallTablet,
					'card-no-padding': noPadding,
				}"
				class="card h-fit w-full overflow-visible rounded-none md:card md:overflow-visible"
			>
				@if ((showHeader && closeable) || isSmallTablet) {
					<div
						[ngClass]="{
							'card-md mb-0 pb-0': noPadding,
							'mb-2': !noPadding,
						}"
						class="flex flex-col"
					>
						<div class="flex justify-between">
							<div #headerRef class="w-full">
								<ng-content select="vp-popover-header-title" />
							</div>

							@if (closeable || isSmallTablet) {
								<svg-icon
									(click)="toggle()"
									class="size-5 cursor-pointer"
									src="assets/icons/icon-cross.svg"
								/>
							}
						</div>
						@if (isSmallTablet) {
							<hr class="-mx-6 my-4 text-ui-100" />
						}
					</div>
				}
				<div
					[ngClass]="{ 'block overflow-y-auto': isSmallTablet }"
					[style.max-height]="
						isSmallTablet ? mainContentHeight : 'auto'
					"
				>
					<ng-content select="vp-popover-content" />
				</div>
				<div class="md:hidden">
					@if (
						isSmallTablet && footer.children[0]?.children?.length
					) {
						<hr class="-mx-6 my-4 text-ui-100 last:hidden" />
					}
					<div #footer>
						<ng-content select="vp-popover-footer" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		[ngClass]="{
			'overlay-dark-3 fixed left-0 top-0 z-20 size-full':
				showPopover && isSmallTablet,
		}"
		class="transition-colors"
	></div>
</ng-container>
