import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	ErrorHandler,
	inject,
	InjectionToken,
	PLATFORM_ID,
	provideAppInitializer,
	Provider,
	ValueProvider,
} from '@angular/core';

import { ApmErrorHandler, ApmService } from '@elastic/apm-rum-angular';

export const APM_ENVIRONMENT = new InjectionToken<ApmEnvironmentRequirements>(
	'Apm environment',
);
export interface ApmEnvironmentRequirements {
	production: boolean;
	origin: string;
	environment: string;
	name: string;
	version: string;
}

export function provideApmEnvironment(
	environment: ApmEnvironmentRequirements,
): ValueProvider {
	return { provide: APM_ENVIRONMENT, useValue: environment };
}
export function apmInitializerFactory(
	document: Document,
	platformId: Parameters<typeof isPlatformBrowser>[0],
	apmService: ApmService,
	environment: ApmEnvironmentRequirements,
) {
	return () => {
		if (isPlatformBrowser(platformId)) {
			if (environment.production) {
				const apm = apmService.init({
					environment: environment.environment,
					pageLoadTransactionName:
						document.defaultView?.location.href,
					serverUrl: 'https://apm.newstory.cloud',
					serviceName: environment.name,
					serviceVersion: environment.version,
				});

				apm.addLabels({ group: 'valk' });
			}
		}
	};
}
export function provideApmHandling(): Provider {
	return [
		ApmService,
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		},
		provideAppInitializer(
			/* istanbul ignore next */ () => {
				const initializerFn = apmInitializerFactory(
					inject(DOCUMENT),
					inject(PLATFORM_ID),
					inject(ApmService),
					inject(APM_ENVIRONMENT),
				);
				return initializerFn();
			},
		),
	];
}
