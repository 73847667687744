import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { IconGridItem } from './icon-grid.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [AngularSvgIconModule, NgClass],
	selector: 'vp-icon-grid',
	templateUrl: './icon-grid.html',
})
export class IconGridComponent {
	@Input() items: IconGridItem[] = [];
}
