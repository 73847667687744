<div class="mx-auto max-w-screen-md">
	<vp-link
		[className]="className"
		[editableInStoryblok]="_editable"
		[link]="anchorLink"
		buttonClassName=""
		type="button"
	>
		{{ text }}
	</vp-link>
</div>
