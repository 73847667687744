<div class="card card-sm relative flex h-full flex-col">
	<a [href]="dealUrl.href" [target]="dealUrl.target">
		<vp-image-grid
			[images]="images"
			[size]="size"
			[sticker]="sticker"
			className="card-image-border-radius-t"
		/>
	</a>
	@if (ribbonText) {
		<div class="absolute -left-2 top-8">
			<vp-ribbon [maxLength]="15" [text]="ribbonText" color="yellow" />
		</div>
	}
	<div
		[ngClass]="{
			'gap-6 sm:flex-row': size === 'large',
			'gap-0': size !== 'large',
		}"
		class="flex size-full flex-col justify-between p-3 pt-6"
	>
		<div
			[ngClass]="{
				'flex-col items-end justify-end sm:w-9/12': size === 'large',
				'flex-row': size !== 'large',
			}"
			class="w-full"
			data-test-id="content"
		>
			<a [href]="dealUrl.href" [target]="dealUrl.target">
				<header>
					@switch (headingType) {
						@case ('h2') {
							<h2 class="heading-group heading-group-sm mb-4">
								{{ title }}
							</h2>
						}
						@case ('h3') {
							<h3 class="heading-group heading-group-sm mb-4">
								{{ title }}
							</h3>
						}
						@case ('h4') {
							<h4 class="heading-group heading-group-sm mb-4">
								{{ title }}
							</h4>
						}
					}
					<p class="heading-group-overline" role="doc-subtitle">
						{{ overline }}
					</p>
				</header>
			</a>
			@if (size === 'large') {
				<div class="hidden list-disc sm:block">
					<markdown [data]="content" class="list-checkmarks" />
				</div>
			}
			@if (size === 'large') {
				<ng-container
					[ngTemplateOutlet]="cardTags"
					[ngTemplateOutletContext]="{ tags: tags }"
				/>
			}
		</div>
		<div [class]="containerClass" class="flex w-fit">
			<div class="flex w-full flex-col">
				@if (size !== 'large') {
					<ng-container
						[ngTemplateOutlet]="cardTags"
						[ngTemplateOutletContext]="{ tags: tags }"
					/>
				}
				<div
					[ngClass]="{
						'md:flex-row md:gap-9': size === 'base',
					}"
					class="flex w-full flex-col"
				>
					<div
						[ngClass]="{
							'flex-col sm:items-end': size === 'large',
							'w-full sm:w-1/2 sm:justify-start': size === 'base',
						}"
						class="flex"
					>
						<vp-pricing
							[alignment]="
								((isMobile$ | async) && size === 'large') ||
								size !== 'large'
									? 'self-start'
									: 'self-end'
							"
							[conditions]="
								'global.starting-price-excl-taxes' | translate
							"
							[currency]="currency"
							[indication]="false"
							[price]="price"
							size="base"
						/>
					</div>
					<div
						[ngClass]="{
							'justify-start': size === 'small',
							'md:justify-end': size !== 'small',
						}"
						class="flex w-full"
					>
						<a
							[href]="dealUrl.href"
							[target]="dealUrl.target"
							class="btn btn-primary mt-4"
						>
							{{ 'compositions.deal-card.show-deal' | translate }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #cardTags let-tags="tags">
	<div
		[ngClass]="{
			'mt-0 sm:mt-6': size === 'large',
			'mb-6': size !== 'large',
		}"
		class="inline-flex flex-wrap gap-2"
	>
		@for (tag of tags; track tag) {
			<vp-tag color="light-lime">
				{{ tag }}
			</vp-tag>
		}
	</div>
</ng-template>
