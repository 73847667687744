import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	EventEmitter,
	Inject,
	input,
	OnChanges,
	Output,
	Signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { Link } from '@valk-nx/components/ui-link/src/lib/link.interface';
import { LinkListComponent } from '@valk-nx/components/ui-link-list/src/lib/link-list.component';
import { LinkListItem } from '@valk-nx/components/ui-link-list/src/lib/link-list.interface';
import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { LANGUAGE, Language } from '@valk-nx/core/lib/core';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';

import { HeaderMenuItem, MetaMenu } from '../header.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-header-desktop`,
	templateUrl: './header-desktop.html',
	host: { ngSkipHydration: 'true' },
	styleUrls: ['header-desktop.scss'],
	imports: [
		AngularSvgIconModule,
		ClickedModule,
		CommonModule,
		DropdownComponent,
		LinkComponent,
		LinkListComponent,
		PopoverModule,
		RouterModule,
		TranslatePipe,
	],
})
export class HeaderDesktopComponent implements OnChanges {
	title = input.required<string>();
	menuItems = input.required<HeaderMenuItem[]>();
	metaMenu = input.required<MetaMenu>();
	language = input.required<string>();
	isNegativeHeader = input<boolean>();
	hideMenu = input<boolean>();

	moreMenuItems: Signal<LinkListItem[]> = computed(() => {
		if (this.menuItems().length <= this.MAX_NUMBER_MENU_ITEMS) {
			return [];
		}

		const items = this.menuItems().slice(this.MAX_NUMBER_MENU_ITEMS - 1);

		return items.map((item) => {
			return {
				url: item.url,
				icon: '',
				text: item.title,
				isExternal: true, // This makes the link list use href instead of routerLink
				targetSelf: true,
			};
		});
	});

	@Output() languageSwitch = new EventEmitter<string>();

	logoLink: Link;
	showingMoreMenu = false;
	MAX_NUMBER_MENU_ITEMS = 7;

	constructor(@Inject(LANGUAGE) private readonly rootLanguage: Language) {}

	ngOnChanges() {
		this.logoLink = {
			url:
				this.language() === this.rootLanguage
					? '/'
					: `/${this.language()}`,
			title: 'home',
		};
	}
}
