import { CommonModule, DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DynamicModule } from 'ng-dynamic-component';
import { MarkdownModule } from 'ngx-markdown';
import { combineLatest, map, Observable, switchMap, take, tap } from 'rxjs';

import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import type { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import { IconGridComponent } from '@valk-nx/components/ui-icon-grid/src/lib/icon-grid';
import type { IconGridItem } from '@valk-nx/components/ui-icon-grid/src/lib/icon-grid.interface';
import type { ImageInterface } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MapComponent } from '@valk-nx/components/ui-map/src/lib/map';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { RatingComponent } from '@valk-nx/components/ui-rating/src/lib/rating';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { ReadMoreHelper } from '@valk-nx/components/ui-read-more/src/lib/read-more.helper';
import { TabMenuComponent } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu';
import { TabMenuItemDirective } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu.directive';
import { Language, negativeHeader$ } from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import type { MeetingRoomResponseData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { HotelStore } from '@valk-nx/storyblok-store/src/lib/store/hotel/hotel.store';

import { MeetingRoomDetailRequestFormComponent } from './request-form/meeting-room-detail-request-form';

@Component({
	selector: 'sb-meeting-room-detail',
	templateUrl: './meeting-room-detail.component.html',
	imports: [
		AngularSvgIconModule,
		CarouselComponent,
		CommonModule,
		DynamicModule,
		IconGridComponent,
		MarkdownModule,
		MapComponent,
		MeetingRoomDetailRequestFormComponent,
		MiniCarouselComponent,
		RatingComponent,
		ReadMoreComponent,
		TabMenuComponent,
		TabMenuItemDirective,
		TranslatePipe,
	],
})
export class MeetingRoomDetailComponent
	extends StoryblokRootDirective
	implements OnInit, AfterViewInit
{
	@Input({ required: true }) canonical!: string;
	@ViewChild('contentRef', { read: ElementRef }) contentRef: ElementRef;

	readonly hotelStore = inject(HotelStore);

	showForm$: Observable<boolean>;
	meetingRoom$: Observable<MeetingRoomResponseData>;
	isSmallTablet$: Observable<boolean>;

	images: CarouselItem[] = [];
	hotelImages: ImageInterface[] = [];
	iconGridItems: IconGridItem[] = [];
	parentUrl = '';
	disabledReadmore = true;

	language: Language;

	constructor(
		private readonly contentService: ContentService,
		private readonly sbService: StoryblokService,
		private readonly metadataService: MetadataService,
		private readonly viewportService: ViewPortService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly cd: ChangeDetectorRef,
		@Inject(DOCUMENT) readonly document: Document,
	) {
		super();

		this.showForm$ = this.route.queryParamMap.pipe(
			map((params) => Boolean(params.get('form'))),
			tap((showForm) => {
				negativeHeader$.next(!showForm);
			}),
		);

		this.isSmallTablet$ = this.viewportService.isSmallTablet$;

		this.meetingRoom$ = combineLatest([
			this.sbService.translatedSlugs$,
			this.showForm$,
		]).pipe(
			takeUntilDestroyed(),
			map(([translatedSlugs, showForm]) => {
				const slug =
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0];
				this.language = slug.lang.toLowerCase() as Language;
				this.parentUrl = slug.path.substring(
					0,
					slug.path.lastIndexOf('/') + 1,
				);
				return { slug, showForm };
			}),
			switchMap(({ slug, showForm }) =>
				this.contentService
					.getMeetingRoom(
						slug.path.split('/').pop(),
						slug.lang.toLowerCase(),
					)
					.pipe(map((response) => ({ response, showForm }))),
			),
			map(({ response, showForm }) => {
				const meetingRoomData = response.body.data;
				this.metadataService.updateMetaDataBatch(meetingRoomData);

				this.images = meetingRoomData.images.map(
					(image: { src: string; alt: string }) => {
						return {
							image: {
								src: image.src,
								alt: image.alt,
							},
							heading: null,
							subHeading: null,
							link: null,
						};
					},
				);

				this.hotelImages = meetingRoomData.hotel.images?.map(
					(image: { src: string; alt: string }) => {
						return {
							src: image.src,
							altText: image.alt,
							width: 0,
							height: 0,
						};
					},
				);

				this.iconGridItems = meetingRoomData?.layouts.map((layout) => {
					return {
						icon: `assets/icons/layout-${layout?.icon}.svg`,
						title: layout.name,
						subtitle:
							layout.maxPersons === 0
								? undefined
								: layout.maxPersons.toString(),
					};
				});

				if (showForm) {
					negativeHeader$.next(false);
				} else if (this.images.length > 0) {
					negativeHeader$.next(true);
				}

				return meetingRoomData;
			}),
		);
	}

	ngOnInit() {
		negativeHeader$.next(false);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.determineReadMore();
		});
	}

	@HostListener('window:resize')
	@Debounce(300)
	determineReadMore() {
		this.viewportService.isSmallTablet$
			.pipe(take(1))
			.subscribe((isSmallTablet) => {
				this.disabledReadmore = ReadMoreHelper.disabledReadmore(
					this.contentRef?.nativeElement.offsetHeight,
					isSmallTablet,
				);
				this.cd.detectChanges();
			});
	}

	onFormClosed(closed: boolean): void {
		if (closed) {
			this.router.navigate([this.router.url.replace('?form=true', '')], {
				queryParams: {},
			});
		}
	}
}
