<div class="md:@container" data-test-id="availability-widget">
	<div class="card flex flex-col gap-4 @md:flex-row">
		<div class="flex size-full flex-col @md:flex-row children:flex-1">
			@if (showHotels() && hotels.length > 0) {
				<vp-availability-widget-input-hotels
					[className]="
						cn(
							'focus:relative focus:z-10',
							(this.showOccupancy() || this.showDates()) &&
								'@max-md:-mb-px @max-md:rounded-b-none @md:-mr-px @md:rounded-r-none max-md:-mb-px max-md:rounded-b-none'
						)
					"
					[hotels]="hotels"
					[(isOpen)]="isHotelsOpen"
					(hotelSelected)="onHotelSelect($event)"
					data-test-id="hotels-selector"
				/>
			}
			@if (showOccupancy() && occupancyRestrictions) {
				<vp-availability-widget-input-occupancy
					[className]="
						cn(
							'focus:relative focus:z-10',
							this.showHotels() &&
								'@max-md:rounded-t-none @md:rounded-l-none max-md:rounded-t-none',
							this.showDates() &&
								'@max-md:-mb-px @max-md:rounded-b-none @md:-mr-px @md:rounded-r-none max-md:-mb-px max-md:rounded-b-none'
						)
					"
					[defaultAdults]="defaultAdults"
					[initialOccupancy]="initialOccupancy"
					[isOpen]="isOccupancyOpen"
					[maxRooms]="occupancyRestrictions.maxOccupancies"
					[occupancyRestrictions]="occupancyRestrictions"
					(emitChooseDates)="onChooseDatesEmit($event)"
					(emitIsPopoverOpen)="onOccupancyOpenEmit($event)"
					(occupancyChanged)="onOccupancyEmit($event)"
					data-test-id="occupancy-selector"
				/>
			}
			@if (showDates()) {
				<vp-availability-widget-input-date-range
					[availability]="availability$ | async"
					[className]="
						cn(
							'focus:md:border-l',
							(this.showHotels() || this.showOccupancy()) &&
								'@max-md:rounded-t-none @md:rounded-l-none max-md:rounded-t-none'
						)
					"
					[config]="{
						mode: 'range',
						showMonths: 2,
						minDate: minDate,
						maxDate: maxDate,
					}"
					[initialArrivalDate]="initialArrivalDate"
					[isLoading]="isLoading"
					[isOpen]="isDatePickerOpen"
					[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
					(datePickerModelChanged)="updateModel($event)"
					(emitIsPopoverOpen)="onDatePickerOpened($event)"
					(emitMonthChange)="onMonthChange($event)"
					(emitShowAvailabilityButton)="goToBookingtool()"
					(initialDatesNotAvailable)="
						recalculateAvailabilityForToday()
					"
					data-test-id="dates-selector"
				/>
			}
		</div>
		<button
			[disabled]="showHotels() && !selectedHotel"
			(click)="goToBookingtool()"
			class="btn btn-primary w-full @md:w-fit md:h-auto"
			data-test-id="cta-button"
		>
			{{ 'global.show-availability' | translate }}
		</button>
	</div>
</div>
