import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';
import { map, Observable, switchMap } from 'rxjs';

import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { ISbStoryBlok } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-static-section',
	templateUrl: './static-section.html',
	imports: [
		CommonModule,
		ColumnsComponent,
		DynamicModule,
		StoryblokDirective,
	],
})
export class StoryblokStaticSectionComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: string;

	staticData$: Observable<ISbStoryBlok[]>;

	constructor(
		private readonly contentService: ContentService,
		private readonly sbService: StoryblokService,
	) {
		super();

		this.staticData$ = this.sbService.translatedSlugs$.pipe(
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) => {
				return this.contentService.getLegal(
					this.content,
					slug.lang.toLowerCase(),
				);
			}),
			map((response) => {
				return [
					{
						component: 'markdown',
						content: response.body?.data,
					} as unknown as ISbStoryBlok,
				];
			}),
		);
	}
}
