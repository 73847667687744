<div [editableInStoryblok]="_editable" class="flex flex-col">
	@if (carousel) {
		<sb-carousel [config]="carousel.config" [items]="carousel.items" />
	}
	@if (hotel$ | async; as hotel) {
		<div class="z-10 -mt-14 px-3 md:px-8">
			<div class="section-inner">
				<vp-availability-widget
					[bookingtoolUrl]="
						this.bookingtool + '?hotelSlug=' + this.hotelSlug
					"
					[hotelGUID]="hotel?.GUID || hotelGUID"
					[language]="this.language"
					[occupancyRestrictions]="hotel?.occupancyRestrictions"
					(actionClick)="widgetCTA($event)"
				/>
			</div>
		</div>
	}
</div>
