import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { EventPhase } from '@angular/core/primitives/event-dispatch';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-modal',
	templateUrl: './modal.html',
	imports: [AngularSvgIconModule, CommonModule],
})
export class ModalComponent implements OnChanges, AfterViewInit {
	@Input() closeButton = true;
	@Input() closeOnEscape = true;
	@Input() closeOnOutsideClick = true;
	@Input({ required: true }) title = '';
	@Input({ required: true }) showingModal = false;
	@Input() isMaxWScreenLG = false;
	@Input() showFooter = false;

	@Output() emitClose = new EventEmitter<void>();

	@ViewChild('dialog') modalRef!: ElementRef<HTMLDialogElement>;
	@ViewChild('footer') footerRef: ElementRef | undefined;

	scrollPosition = 0;

	constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {}

	ngAfterViewInit(): void {
		this.modalRef.nativeElement.addEventListener('cancel', (event) => {
			if (event.eventPhase !== EventPhase.REPLAY) {
				event.preventDefault();
			}
			if (this.closeOnEscape) {
				this.closeModal();
			}
		});

		this.modalRef.nativeElement.addEventListener('click', (event) => {
			if (this.closeOnOutsideClick) {
				if (event.target === this.modalRef.nativeElement) {
					this.closeModal();
				}
			}
		});

		this.modalRef.nativeElement.addEventListener('close', () => {
			this.emitClose.emit();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['showingModal']?.currentValue || this.showingModal) {
			if (changes['showingModal']?.firstChange) {
				setTimeout(() => {
					this.showModal();
				});
			} else {
				this.showModal();
			}
		} else if (!this.showingModal) {
			this.closeModal();
		}
	}

	showModal(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.modalRef?.nativeElement.showModal();
			this.scrollPosition = window.scrollY;
			GeneralHelper.bodyLock(true, this.scrollPosition);
		}
	}

	closeModal(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.modalRef?.nativeElement.close();
			this.showingModal = false;
			this.emitClose.emit();
			GeneralHelper.bodyLock(false, this.scrollPosition);
		}
	}
}
