import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import {
	HOTEL_SLUG,
	Language,
	RECAPTCHA_V2_SITE_KEY,
} from '@valk-nx/core/lib/core';
import { CheckedOption } from '@valk-nx/core/lib/interfaces/form.interface';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import {
	FormService,
	Recipients,
} from '@valk-nx/services/form/src/lib/form.service';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	FormInputInterface,
	FormSelectInterface,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-form',
	templateUrl: './form.html',
	imports: [
		AlertComponent,
		CommonModule,
		TranslatePipe,
		RecaptchaNotificationComponent,
		StoryblokDirective,
		SharedFormlyModule,
	],
	host: { class: 'w-full' },
})
export class FormComponent extends StoryblokRootDirective implements OnInit {
	@Input({ required: true }) subject!: string;
	@Input({ required: true }) recipient!: Recipients;
	@Input({ required: true }) blocks: (FormInputInterface &
		FormSelectInterface)[] = [];
	@Input({ required: true }) buttonText!: string;
	@Input({ required: true }) successTitle!: string;
	@Input({ required: true }) successContent!: string;

	@ViewChild('formRef') formRef!: NgForm;
	formFields: FormlyFieldConfig[] = [];
	formGroup = new FormGroup({});
	options: FormlyFormOptions = {};
	model = {};

	blocksWithId: (FormInputInterface &
		FormSelectInterface & { id: string })[] = [];
	parsedFormData: {
		id: string;
		label: string;
		value: string | CheckedOption[];
	}[] = [];
	requestSubmitted = false;
	requestSubmitting = false;
	language: Language;
	recaptchaVersion: 'v2' | 'v3' = 'v3';

	constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly formService: FormService,
		private readonly translate: TranslateService,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(RECAPTCHA_V2_SITE_KEY) readonly recaptchaV2Key: string,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;
	}

	ngOnInit() {
		this.blocksWithId = this.blocks.map((block) => ({
			...block,
			id: GeneralHelper.generateRandomId(),
		}));
		this.formFields = StoryblokHelper.buildFormlyData(this.blocksWithId);
	}

	async onSubmit(): Promise<void> {
		if (this.formGroup.valid) {
			this.requestSubmitting = true;

			this.parsedFormData = StoryblokHelper.parsedFormData(
				this.formGroup.value,
				this.blocksWithId,
			);

			return this.formService
				.submitCustomForm(
					this.subject,
					this.parsedFormData,
					this.recipient,
					this.hotelSlug,
					this.formGroup.get('recaptcha')?.value,
				)
				.then((response) => {
					this.requestSubmitting = false;
					this.requestSubmitted = response.success;
					this.useRecaptchaV2(response.status);
					this.cd.detectChanges();
				})
				.catch((err: HttpErrorResponse) => {
					this.requestSubmitting = false;
					this.useRecaptchaV2(err.status);
					this.cd.detectChanges();
				});
		} else {
			this.scrollToError();
		}
	}

	resetForm(): void {
		this.requestSubmitted = false;
		this.requestSubmitting = false;

		this.formGroup.reset();
		this.formRef.resetForm();
	}

	scrollToError() {
		setTimeout(() => {
			GeneralHelper.scrollToElementWithClass(
				'form-field-error',
				undefined,
				'input',
			);
		}, 100);
	}

	useRecaptchaV2(status: number | undefined) {
		if (
			status === 403 &&
			this.recaptchaVersion === 'v3' &&
			this.recaptchaV2Key
		) {
			this.recaptchaVersion = 'v2';
			this.formFields = [
				...this.formFields,
				{
					id: 'recaptcha',
					key: 'recaptcha',
					props: {
						errorMode: 'handled',
						required: true,
						siteKey: this.recaptchaV2Key,
						size: 'default',
					},
					type: 'recaptchaV2',
				},
			];
		}
	}
}
