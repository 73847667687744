<section
	[editableInStoryblok]="_editable"
	data-test-class="introduction-section"
>
	<div class="section-spacing">
		<div class="section-inner section-gap">
			<sb-columns [colOne]="title" component="columnOne" />
		</div>
	</div>
	<div class="section-spacing pt-0">
		<div class="section-inner section-gap">
			@if (uspWrapper.length > 0) {
				<sb-usp-wrapper [USP]="uspWrapper[0].USP" />
			}
		</div>
	</div>
</section>
