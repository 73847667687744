import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [AngularSvgIconModule, NgClass],
	selector: 'vp-accordion',
	templateUrl: './accordion.html',
})
export class AccordionComponent {
	@Input() variant: 'accent' | 'base' = 'base';
	@Input() title = '';
	@Input() isOpen = false;
}
