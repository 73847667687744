import { Component, input } from '@angular/core';

import { VideoComponent as UIVideo } from '@valk-nx/components/ui-video/src/lib/video';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-video',
	templateUrl: './video.html',
	imports: [UIVideo, StoryblokDirective],
})
export class VideoComponent extends StoryblokRootDirective {
	vimeoId = input.required<number>();
	showControls = input<boolean>(false);
}
