import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { CarouselModalComponent } from '@valk-nx/components/ui-carousel-modal/src/lib/carousel-modal';
import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-image-grid`,
	templateUrl: './image-grid.component.html',
	imports: [
		AngularSvgIconModule,
		CarouselModalComponent,
		CommonModule,
		ImageComponent,
	],
})
export class ImageGridComponent {
	@Input() images: PhotoInterface[];
	@Input() sticker: PhotoInterface;
	@Input() size: 'small' | 'base' | 'large' = 'small';
	@Input() useCarousel = false;
	@Input() className = '';
	sizes = Sizes;

	/**
	 * Returns the CSS class for the aspect ratio based on the size of the element.
	 * @returns {string} The CSS class for the aspect ratio.
	 */
	get aspectRatio(): string {
		switch (this.size) {
			case 'base':
				return 'aspect-square md:aspect-video';
			case 'large':
				return 'aspect-square md:aspect-video lg:aspect-anamorphic';
			default:
				return 'aspect-square'; // note: 'small' is the default
		}
	}
}
