import { Component, computed, Input, input, Signal } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';

import { ItemsSliderComponent as UIItemsSlider } from '@valk-nx/components/ui-items-slider/src/lib/items-slider';
import { ItemSlide } from '@valk-nx/components/ui-items-slider/src/lib/items-slider.interface';
import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	ISbStoryBlok,
	ItemSliderItem,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-items-slider-section',
	templateUrl: './items-slider-section.html',
	imports: [
		ColumnsComponent,
		DynamicModule,
		StoryblokDirective,
		UIItemsSlider,
	],
})
export class StoryblokItemsSliderComponent extends StoryblokRootDirective {
	@Input({ required: true }) title!: ISbStoryBlok[];
	items = input<ItemSliderItem[]>([]);

	itemSliderItems: Signal<ItemSlide[]>;

	constructor() {
		super();

		this.itemSliderItems = computed(() =>
			this.items().map((item) => {
				return {
					title: item.title,
					image: {
						src: item.bynder[0].files.transformBaseUrl.url,
						alt: item.imageAlt,
					},
					link: {
						url: item.link.cached_url,
						title: item.title,
						target: item.link.target || '_self',
					},
				};
			}),
		);
	}
}
