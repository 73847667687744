import { Component } from '@angular/core';

import { TranslatePipe } from '@ngx-translate/core';

import { StatusHeaderModule } from '@valk-nx/components/ui-status-header/src/lib/status-header.module';

@Component({
	imports: [StatusHeaderModule, TranslatePipe],
	selector: 'sb-order-giftcard-processing',
	templateUrl: 'order-giftcard-processing.html',
})
export class OrderGiftcardProcessingComponent {}
