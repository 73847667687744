<div
	[ngClass]="{
		'md:flex-row': !isVertical,
	}"
	class="flex flex-col flex-wrap items-start md:flex-row xl:flex-nowrap"
	data-test-id="usp-bar"
>
	@for (usp of usps; track usp) {
		<div
			[ngClass]="{
				'md:w-1/2 lg:w-1/3 xl:w-full': !isVertical,
			}"
			class="flex w-full flex-col p-2 xl:self-stretch"
			data-test-class="usp"
		>
			@if (usp.link) {
				<a
					[href]="usp.link"
					[ngClass]="{
						'gap-1 md:gap-2 xl:flex-col xl:items-center xl:text-center':
							isStacked,
					}"
					[target]="usp.target || '_self'"
					class="flex flex-row gap-4 no-underline outline-black"
					rel="noopener noreferrer"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp, rating: false }"
					/>
				</a>
			} @else {
				<div
					[ngClass]="{
						'gap-1 md:gap-2 xl:flex-col xl:items-center xl:text-center':
							isStacked,
					}"
					class="flex gap-4"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp, rating: false }"
					/>
				</div>
			}
		</div>
	}
	@if (rating?.score && rating?.numberOfRatings) {
		<div
			[ngClass]="{
				'md:w-1/2 lg:w-1/3 xl:w-full': !isVertical,
			}"
			class="flex w-full flex-col p-2 xl:self-stretch"
			data-test-class="usp"
		>
			<div
				[ngClass]="{
					'gap-1 md:gap-2 xl:flex-col xl:items-center xl:text-center':
						isStacked,
				}"
				class="flex gap-4"
			>
				<ng-container
					[ngTemplateOutlet]="uspTemplate"
					[ngTemplateOutletContext]="{
						usp: {
							description: 'components.usp-bar.reviews',
							icon: rating.score,
							link: null,
							title: 'components.usp-bar.ratings.' + ratingKey,
						},
						rating: rating,
					}"
				/>
			</div>
		</div>
	}
</div>

<ng-template #uspTemplate let-rating="rating" let-usp="usp">
	@if (!rating) {
		<svg-icon
			[class]="iconVariant + ' ' + iconType"
			[ngClass]="{
				'icon-container-elevated': isElevatedIcon,
				'icon-container-md icon-container-md-full sm:icon-container-lg sm:icon-container-lg-full':
					isStacked,
				'md:icon-container-lg': !isStacked,
			}"
			[src]="usp.icon"
			class="icon-container shrink-0"
		/>
	} @else {
		<vp-rating-svg
			[class]="iconVariant + ' ' + iconType"
			[ngClass]="{
				'icon-container-elevated': isElevatedIcon,
				'icon-container-md icon-container-md-full sm:icon-container-lg sm:icon-container-lg-full':
					isStacked,
				'md:icon-container-lg': !isStacked,
			}"
			[score]="usp.icon"
			class="icon-container shrink-0"
		/>
	}
	<div class="my-auto flex flex-grow flex-col">
		<span
			[ngClass]="{
				'text-dark-tertiary': iconVariant === 'default',
				'text-dark-primary': iconVariant !== 'default',
				'md:items-center md:justify-center': isStacked,
			}"
			class="flex flex-row font-sans-serif text-sm font-semibold md:text-base"
			data-test-id="usp-bar-title"
		>
			<span class="flex-grow">
				{{ usp.title | translate }}
			</span>
			@if (usp.link) {
				<svg-icon
					class="icon-container icon-container-sm ml-0 mt-0.5 flex-none md:mt-1 md:hidden"
					src="assets/icons/icon-chevron-right.svg"
				/>
			}
		</span>
		<span
			[ngClass]="
				iconVariant === 'default'
					? 'text-dark-tertiary'
					: 'text-dark-primary'
			"
			class="font-sans-serif text-sm"
			data-test-id="usp-bar-description"
		>
			{{
				usp.description
					| translate: { numberOfRatings: rating.numberOfRatings }
			}}
		</span>
	</div>
</ng-template>
