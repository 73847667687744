import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RatingSVGComponent } from '@valk-nx/components/ui-rating/src/lib/rating-svg/rating-svg';

import { IconVariantType, USPInterface } from './usp-bar.interface';

@Component({
	selector: 'vp-usp-bar',
	imports: [
		CommonModule,
		AngularSvgIconModule,
		TranslatePipe,
		RatingSVGComponent,
	],
	templateUrl: './usp-bar.component.html',
})
export class UspBarComponent {
	@Input({ required: true }) usps: USPInterface[] | undefined;
	@Input() isElevatedIcon = true;
	@Input() isStacked = false;
	@Input() isVertical = false;
	@Input({ transform: iconTypeClass }) iconType: '' | 'solid' | 'shaded' = '';
	@Input({ transform: iconVariantClass }) iconVariant: IconVariantType =
		'default';
	@Input() rating: { numberOfRatings: number; score: number } = null;

	get ratingKey() {
		if (this.rating) {
			const score = this.rating.score * 10;
			if (score >= 88) {
				return 'amazing';
			} else if (score >= 84) {
				return 'fantastic';
			}
		}
		return 'great';
	}
}

function iconVariantClass(variant: string): string {
	switch (variant) {
		case 'accent':
			return 'icon-container-accent';
		case 'success':
			return 'icon-container-success';
		case 'warning':
			return 'icon-container-warning';
		case 'error':
			return 'icon-container-error';
		case 'info':
			return 'icon-container-info';
		default:
			return 'default';
	}
}

function iconTypeClass(iconType: string): string {
	switch (iconType) {
		case 'shaded':
			return 'icon-container-shaded';
		case 'solid':
			return 'icon-container-solid';
		default:
			return '';
	}
}
