<div data-test-id="meeting-room-detail" id="meeting-room-detail">
	@if (meetingRoom$ | async; as meetingRoom) {
		@if ((showForm$ | async) === false) {
			<vp-carousel [items]="images" data-test-id="carousel" />
			<section class="section-spacing bg-white">
				<div class="section-inner section-gap flex-col">
					<div class="column-gap lg:flex-row">
						<div class="column-gap flex-col lg:w-2/3">
							<div>
								<header data-test-class="intro-header">
									<h1
										class="heading-group heading-group-lg"
										data-test-class="title"
									>
										{{ meetingRoom.name }}
									</h1>
									<p
										class="heading-group-overline"
										data-test-class="overline"
										role="doc-subtitle"
									>
										{{
											'global.meeting-room-label'
												| translate
										}}
									</p>
								</header>
							</div>
							<vp-read-more [isDisabled]="disabledReadmore">
								<markdown
									#contentRef
									[data]="meetingRoom.description"
									class="markdown block"
									data-test-class="package-detail-description"
								/>
							</vp-read-more>
							@if (iconGridItems.length > 0) {
								<vp-icon-grid
									[items]="iconGridItems"
									class="block"
									data-test-id="layouts"
								/>
							}
						</div>
						<div class="flex-col lg:w-1/3">
							<div
								class="card card-lg flex flex-col gap-4"
								data-test-id="mini-booking-widget"
							>
								<header>
									<div
										class="heading-group"
										data-test-id="title"
									>
										<h2 class="heading-group">
											{{
												'storyblok.page.meeting-room.mini-booking-widget-title'
													| translate
											}}
										</h2>
									</div>
								</header>
							</div>
							@if (meetingRoom.hotel?.rating?.score) {
								<vp-rating
									[isHorizontal]="true"
									[numberOfRatings]="
										meetingRoom.hotel.rating.numberOfRatings
									"
									[score]="meetingRoom.hotel.rating.score"
								/>
							}
						</div>
					</div>
					<div class="column-gap flex-col lg:flex-row">
						<div class="flex-col lg:w-2/3">
							@if (meetingRoom.hotel) {
								<h2
									class="running-head running-head-center"
									data-test-class="hotel-header"
								>
									{{
										'storyblok.pages.hotel-information'
											| translate
									}}
								</h2>
								@if (meetingRoom.hotel.images?.length > 0) {
									<vp-mini-carousel
										[items]="hotelImages"
										aspectRatio="21:9"
									/>
								}
								<vp-tab-menu
									data-test-id="meeting-room-hotel-info"
								>
									@if (
										this.hotelStore.address().latitude &&
										this.hotelStore.address().longitude
									) {
										<vp-tab-menu-item
											[content]="mapRef"
											[label]="'global.map' | translate"
											name="map"
										/>
									}
								</vp-tab-menu>
								<ng-template #mapRef>
									<vp-map
										[lat]="
											this.hotelStore.address().latitude
										"
										[long]="
											this.hotelStore.address().longitude
										"
									/>
								</ng-template>
							}
						</div>
						<div class="lg:w-1/3"></div>
					</div>
				</div>
			</section>
		} @else {
			@defer {
				<sb-meeting-room-detail-request-form
					[iconGridItems]="iconGridItems"
					[image]="
						meetingRoom.images.length > 0
							? meetingRoom.images[0]
							: null
					"
					[language]="language"
					[meetingroomName]="meetingRoom.name"
					(formClosed)="onFormClosed($event)"
				/>
			}
		}
	}
</div>
