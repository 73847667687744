<section
	[editableInStoryblok]="_editable"
	data-test-class="items-slider-section"
>
	<div class="section-inner">
		<div class="section-spacing pb-0">
			<sb-columns [colOne]="title" component="columnOne" />
		</div>
		@if (itemSliderItems().length > 0) {
			<div class="section-spacing xl:px-0 max-lg:px-0">
				<vp-items-slider [items]="itemSliderItems()" [uid]="_uid" />
			</div>
		}
	</div>
</section>
