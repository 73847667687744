var w = Object.defineProperty;
var S = (l, e, t) => e in l ? w(l, e, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: t
}) : l[e] = t;
var c = (l, e, t) => S(l, typeof e != "symbol" ? e + "" : e, t);
class A extends Error {
  constructor(e) {
    super(e), this.name = "AbortError";
  }
}
function x(l, e, t) {
  if (!Number.isFinite(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!Number.isFinite(t)) throw new TypeError("Expected `interval` to be a finite number");
  const r = [];
  let s = [],
    i = 0,
    n = !1;
  const o = async () => {
      i++;
      const h = r.shift();
      if (h) try {
        const f = await l(...h.args);
        h.resolve(f);
      } catch (f) {
        h.reject(f);
      }
      const u = setTimeout(() => {
        i--, r.length > 0 && o(), s = s.filter(f => f !== u);
      }, t);
      s.includes(u) || s.push(u);
    },
    a = (...h) => n ? Promise.reject(new Error("Throttled function is already aborted and not accepting new promises")) : new Promise((u, f) => {
      r.push({
        resolve: u,
        reject: f,
        args: h
      }), i < e && o();
    });
  return a.abort = () => {
    n = !0, s.forEach(clearTimeout), s = [], r.forEach(h => h.reject(() => new A("Throttle function aborted"))), r.length = 0;
  }, a;
}
class m {
  constructor() {
    c(this, "isCDNUrl", (e = "") => e.includes("/cdn/"));
    c(this, "getOptionsPage", (e, t = 25, r = 1) => ({
      ...e,
      per_page: t,
      page: r
    }));
    c(this, "delay", e => new Promise(t => setTimeout(t, e)));
    c(this, "arrayFrom", (e = 0, t) => Array.from({
      length: e
    }, t));
    c(this, "range", (e = 0, t = e) => {
      const r = Math.abs(t - e) || 0,
        s = e < t ? 1 : -1;
      return this.arrayFrom(r, (i, n) => n * s + e);
    });
    c(this, "asyncMap", async (e, t) => Promise.all(e.map(t)));
    c(this, "flatMap", (e = [], t) => e.map(t).reduce((r, s) => [...r, ...s], []));
    /**
     * @method escapeHTML
     * @param  {string} string text to be parsed
     * @return {string} Text parsed
     */
    c(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        r = /[&<>"']/g,
        s = new RegExp(r.source);
      return e && s.test(e) ? e.replace(r, i => t[i]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {object} params
   * @param  {string} prefix
   * @param  {boolean} isArray
   * @return {string} Stringified object
   */
  stringify(e, t, r) {
    const s = [];
    for (const i in e) {
      if (!Object.prototype.hasOwnProperty.call(e, i)) continue;
      const n = e[i],
        o = r ? "" : encodeURIComponent(i);
      let a;
      typeof n == "object" ? a = this.stringify(n, t ? t + encodeURIComponent(`[${o}]`) : o, Array.isArray(n)) : a = `${t ? t + encodeURIComponent(`[${o}]`) : o}=${encodeURIComponent(n)}`, s.push(a);
    }
    return s.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {string} regionCode region code, could be eu, us, cn, ap or ca
   * @return {string} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      r = "api-us.storyblok.com",
      s = "app.storyblokchina.cn",
      i = "api-ap.storyblok.com",
      n = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return r;
      case "cn":
        return s;
      case "ap":
        return i;
      case "ca":
        return n;
      default:
        return t;
    }
  }
}
const P = function (l, e) {
    const t = {};
    for (const r in l) {
      const s = l[r];
      e.includes(r) && s !== null && (t[r] = s);
    }
    return t;
  },
  C = l => l === "email",
  E = () => ({
    singleTag: "hr"
  }),
  L = () => ({
    tag: "blockquote"
  }),
  j = () => ({
    tag: "ul"
  }),
  O = l => ({
    tag: ["pre", {
      tag: "code",
      attrs: l.attrs
    }]
  }),
  N = () => ({
    singleTag: "br"
  }),
  H = l => ({
    tag: `h${l.attrs.level}`
  }),
  U = l => ({
    singleTag: [{
      tag: "img",
      attrs: P(l.attrs, ["src", "alt", "title"])
    }]
  }),
  M = () => ({
    tag: "li"
  }),
  z = () => ({
    tag: "ol"
  }),
  q = () => ({
    tag: "p"
  }),
  I = l => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": l.attrs.name,
        emoji: l.attrs.emoji
      }
    }]
  }),
  F = () => ({
    tag: "b"
  }),
  V = () => ({
    tag: "s"
  }),
  J = () => ({
    tag: "u"
  }),
  B = () => ({
    tag: "strong"
  }),
  W = () => ({
    tag: "code"
  }),
  K = () => ({
    tag: "i"
  }),
  G = l => {
    if (!l.attrs) return {
      tag: ""
    };
    const e = new m().escapeHTML,
      t = {
        ...l.attrs
      },
      {
        linktype: r = "url"
      } = l.attrs;
    if (delete t.linktype, t.href && (t.href = e(l.attrs.href || "")), C(r) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const s in t.custom) t[s] = t.custom[s];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  Y = l => ({
    tag: [{
      tag: "span",
      attrs: l.attrs
    }]
  }),
  Q = () => ({
    tag: "sub"
  }),
  X = () => ({
    tag: "sup"
  }),
  Z = l => ({
    tag: [{
      tag: "span",
      attrs: l.attrs
    }]
  }),
  D = l => {
    var t;
    return (t = l.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${l.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  ee = l => {
    var t;
    return (t = l.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${l.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  te = {
    nodes: {
      horizontal_rule: E,
      blockquote: L,
      bullet_list: j,
      code_block: O,
      hard_break: N,
      heading: H,
      image: U,
      list_item: M,
      ordered_list: z,
      paragraph: q,
      emoji: I
    },
    marks: {
      bold: F,
      strike: V,
      underline: J,
      strong: B,
      code: W,
      italic: K,
      link: G,
      styled: Y,
      subscript: Q,
      superscript: X,
      anchor: Z,
      highlight: D,
      textStyle: ee
    }
  },
  se = function (l) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      r = new RegExp(t.source);
    return l && r.test(l) ? l.replace(t, s => e[s]) : l;
  };
let $ = !1;
class re {
  constructor(e) {
    c(this, "marks");
    c(this, "nodes");
    e || (e = te), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }, r = !0) {
    if (!$ && r && (console.warn("Warning ⚠️: The RichTextResolver class is deprecated and will be removed in the next major release. Please use the `@storyblok/richtext` package instead. https://github.com/storyblok/richtext/"), $ = !0), e && e.content && Array.isArray(e.content)) {
      let s = "";
      return e.content.forEach(i => {
        s += this.renderNode(i);
      }), t.optimizeImages ? this.optimizeImages(s, t.optimizeImages) : s;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
      The "content" field must be an array of nodes as the type ISbRichtext.
      ISbRichtext:
        content?: ISbRichtext[]
        marks?: ISbRichtext[]
        attrs?: any
        text?: string
        type: string
        
        Example:
        {
          content: [
            {
              content: [
                {
                  text: 'Hello World',
                  type: 'text'
                }
              ],
              type: 'paragraph'
            }
          ],
          type: 'doc'
        }`), "";
  }
  optimizeImages(e, t) {
    let r = 0,
      s = 0,
      i = "",
      n = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (i += `width="${t.width}" `, r = t.width), typeof t.height == "number" && t.height > 0 && (i += `height="${t.height}" `, s = t.height), (t.loading === "lazy" || t.loading === "eager") && (i += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (i += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (n += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (n += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-F]{6}/gi) || t.filters.fill === "transparent") && (n += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (n += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (n += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (n += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (n += `:rotate(${t.filters.rotate})`), n.length > 0 && (n = `/filters${n}`))), i.length > 0 && (e = e.replace(/<img/g, `<img ${i.trim()}`));
    const o = r > 0 || s > 0 || n.length > 0 ? `${r}x${s}${n}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${o}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var u, f;
      const h = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|bmp)/g);
      if (h && h.length > 0) {
        const g = {
          srcset: (u = t.srcset) == null ? void 0 : u.map(d => {
            if (typeof d == "number") return `//${h}/m/${d}x0${n} ${d}w`;
            if (typeof d == "object" && d.length === 2) {
              let b = 0,
                _ = 0;
              return typeof d[0] == "number" && (b = d[0]), typeof d[1] == "number" && (_ = d[1]), `//${h}/m/${b}x${_}${n} ${b}w`;
            }
            return "";
          }).join(", "),
          sizes: (f = t.sizes) == null ? void 0 : f.map(d => d).join(", ")
        };
        let k = "";
        return g.srcset && (k += `srcset="${g.srcset}" `), g.sizes && (k += `sizes="${g.sizes}" `), a.replace(/<img/g, `<img ${k.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(s => {
      const i = this.getMatchingMark(s);
      i && i.tag !== "" && t.push(this.renderOpeningTag(i.tag));
    });
    const r = this.getMatchingNode(e);
    return r && r.tag && t.push(this.renderOpeningTag(r.tag)), e.content ? e.content.forEach(s => {
      t.push(this.renderNode(s));
    }) : e.text ? t.push(se(e.text)) : r && r.singleTag ? t.push(this.renderTag(r.singleTag, " /")) : r && r.html ? t.push(r.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), r && r.tag && t.push(this.renderClosingTag(r.tag)), e.marks && e.marks.slice(0).reverse().forEach(s => {
      const i = this.getMatchingMark(s);
      i && i.tag !== "" && t.push(this.renderClosingTag(i.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let i = `<${s.tag}`;
        if (s.attrs) {
          for (const n in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, n)) {
            const o = s.attrs[n];
            o !== null && (i += ` ${n}="${o}"`);
          }
        }
        return `${i}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(r => r.constructor === String ? `</${r}>` : `</${r.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const v = re;
class ie {
  constructor(e) {
    c(this, "baseURL");
    c(this, "timeout");
    c(this, "headers");
    c(this, "responseInterceptor");
    c(this, "fetch");
    c(this, "ejectInterceptor");
    c(this, "url");
    c(this, "parameters");
    c(this, "fetchOptions");
    this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t ?? {}, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      r = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(s => {
      r.data = s;
    }));
    for (const s of e.headers.entries()) t[s[0]] = s[1];
    return r.headers = {
      ...t
    }, r.status = e.status, r.statusText = e.statusText, r;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      r = null;
    if (e === "get") {
      const a = new m();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else r = JSON.stringify(this.parameters);
    const s = new URL(t),
      i = new AbortController(),
      {
        signal: n
      } = i;
    let o;
    this.timeout && (o = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${s}`, {
        method: e,
        headers: this.headers,
        body: r,
        signal: n,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(o);
      const h = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(h)) : this._statusHandler(h);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((r, s) => {
      if (t.test(`${e.status}`)) return r(e);
      const i = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      s(i);
    });
  }
}
const ne = ie,
  T = "SB-Agent",
  R = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let y = {};
const p = {};
class ae {
  /**
   *
   * @param config ISbConfig interface
   * @param pEndpoint string, optional
   */
  constructor(e, t) {
    c(this, "client");
    c(this, "maxRetries");
    c(this, "retriesDelay");
    c(this, "throttle");
    c(this, "accessToken");
    c(this, "cache");
    c(this, "helpers");
    c(this, "resolveCounter");
    c(this, "relations");
    c(this, "links");
    // TODO: Remove on v7.x.x
    c(this, "richTextResolver");
    c(this, "resolveNestedRelations");
    c(this, "stringifiedStoriesCache");
    let r = e.endpoint || t;
    if (!r) {
      const n = new m().getRegionURL,
        o = e.https === !1 ? "http" : "https";
      e.oauthToken ? r = `${o}://${n(e.region)}/v1` : r = `${o}://${n(e.region)}/v2`;
    }
    const s = new Headers();
    s.set("Content-Type", "application/json"), s.set("Accept", "application/json"), e.headers && (e.headers.constructor.name === "Headers" ? e.headers.entries().toArray() : Object.entries(e.headers)).forEach(([o, a]) => {
      s.set(o, a);
    }), s.has(T) || (s.set(T, R.defaultAgentName), s.set(R.defaultAgentVersion, R.packageVersion));
    let i = 5;
    e.oauthToken && (s.set("Authorization", e.oauthToken), i = 3), e.rateLimit && (i = e.rateLimit), e.richTextSchema ? this.richTextResolver = new v(e.richTextSchema) : this.richTextResolver = new v(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = x(this.throttledRequest.bind(this), i, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new m(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new ne({
      baseURL: r,
      timeout: e.timeout || 0,
      headers: s,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let r = "";
      return t.attrs.body && t.attrs.body.forEach(s => {
        r += e(s.component, s);
      }), {
        html: r
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = p[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, r, s, i) {
    const n = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, r, s));
    return this.cacheResponse(e, n, void 0, i);
  }
  get(e, t, r) {
    t || (t = {});
    const s = `/${e}`,
      i = this.factoryParamOptions(s, t);
    return this.cacheResponse(s, i, void 0, r);
  }
  async getAll(e, t, r, s) {
    const i = (t == null ? void 0 : t.per_page) || 25,
      n = `/${e}`.replace(/\/$/, ""),
      o = r ?? n.substring(n.lastIndexOf("/") + 1),
      a = 1,
      h = await this.makeRequest(n, t, i, a, s),
      u = h.total ? Math.ceil(h.total / i) : 1,
      f = await this.helpers.asyncMap(this.helpers.range(a, u), g => this.makeRequest(n, t, i, g + 1, s));
    return this.helpers.flatMap([h, ...f], g => Object.values(g.data[o]));
  }
  post(e, t, r) {
    const s = `/${e}`;
    return Promise.resolve(this.throttle("post", s, t, r));
  }
  put(e, t, r) {
    const s = `/${e}`;
    return Promise.resolve(this.throttle("put", s, t, r));
  }
  delete(e, t, r) {
    t || (t = {});
    const s = `/${e}`;
    return Promise.resolve(this.throttle("delete", s, t, r));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, r) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, r);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, r) {
    const s = e[t];
    s && s.fieldtype === "multilink" && s.linktype === "story" && typeof s.id == "string" && this.links[r][s.id] ? s.story = this._cleanCopy(this.links[r][s.id]) : s && s.linktype === "story" && typeof s.uuid == "string" && this.links[r][s.uuid] && (s.story = this._cleanCopy(this.links[r][s.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? JSON.parse(this.stringifiedStoriesCache[t] || JSON.stringify(this.relations[e][t])) : t;
  }
  /**
   * Resolves a field's value by replacing UUIDs with their corresponding story references
   * @param jtree - The JSON tree object containing the field to resolve
   * @param treeItem - The key of the field to resolve
   * @param resolveId - The unique identifier for the current resolution context
   *
   * This method handles both single string UUIDs and arrays of UUIDs:
   * - For single strings: directly replaces the UUID with the story reference
   * - For arrays: maps through each UUID and replaces with corresponding story references
   */
  _resolveField(e, t, r) {
    const s = e[t];
    typeof s == "string" ? e[t] = this.getStoryReference(r, s) : Array.isArray(s) && (e[t] = s.map(i => this.getStoryReference(r, i)).filter(Boolean));
  }
  /**
   * Inserts relations into the JSON tree by resolving references
   * @param jtree - The JSON tree object to process
   * @param treeItem - The current field being processed
   * @param fields - The relation patterns to resolve (string or array of strings)
   * @param resolveId - The unique identifier for the current resolution context
   *
   * This method handles two types of relation patterns:
   * 1. Nested relations: matches fields that end with the current field name
   *    Example: If treeItem is "event_type", it matches patterns like "*.event_type"
   *
   * 2. Direct component relations: matches exact component.field patterns
   *    Example: "event.event_type" for component "event" and field "event_type"
   *
   * The method supports both string and array formats for the fields parameter,
   * allowing flexible specification of relation patterns.
   */
  _insertRelations(e, t, r, s) {
    if (Array.isArray(r) ? r.find(o => o.endsWith(`.${t}`)) : r.endsWith(`.${t}`)) {
      this._resolveField(e, t, s);
      return;
    }
    const n = e.component ? `${e.component}.${t}` : t;
    (Array.isArray(r) ? r.includes(n) : r === n) && this._resolveField(e, t, s);
  }
  /**
   * Recursively traverses and resolves relations in the story content tree
   * @param story - The story object containing the content to process
   * @param fields - The relation patterns to resolve
   * @param resolveId - The unique identifier for the current resolution context
   */
  iterateTree(e, t, r) {
    const s = (i, n = "") => {
      if (!(!i || i._stopResolving)) {
        if (Array.isArray(i)) i.forEach((o, a) => s(o, `${n}[${a}]`));else if (typeof i == "object") for (const o in i) {
          const a = n ? `${n}.${o}` : o;
          (i.component && i._uid || i.type === "link") && (this._insertRelations(i, o, t, r), this._insertLinks(i, o, r)), s(i[o], a);
        }
      }
    };
    s(e.content);
  }
  async resolveLinks(e, t, r) {
    let s = [];
    if (e.link_uuids) {
      const i = e.link_uuids.length,
        n = [],
        o = 50;
      for (let a = 0; a < i; a += o) {
        const h = Math.min(i, a + o);
        n.push(e.link_uuids.slice(a, h));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: o,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(",")
      })).data.stories.forEach(u => {
        s.push(u);
      });
    } else s = e.links;
    s.forEach(i => {
      this.links[r][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, r) {
    let s = [];
    if (e.rel_uuids) {
      const i = e.rel_uuids.length,
        n = [],
        o = 50;
      for (let a = 0; a < i; a += o) {
        const h = Math.min(i, a + o);
        n.push(e.rel_uuids.slice(a, h));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: o,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(u => {
        s.push(u);
      });
    } else s = e.rels;
    s && s.length > 0 && s.forEach(i => {
      this.relations[r][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, r) {
    var i, n;
    let s = [];
    if (this.links[r] = {}, this.relations[r] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (s = t.resolve_relations.split(",")), await this.resolveRelations(e, t, r)), t.resolve_links && ["1", "story", "url", "link"].includes(t.resolve_links) && ((i = e.links) != null && i.length || (n = e.link_uuids) != null && n.length) && (await this.resolveLinks(e, t, r)), this.resolveNestedRelations) for (const o in this.relations[r]) this.iterateTree(this.relations[r][o], s, r);
    e.story ? this.iterateTree(e.story, s, r) : e.stories.forEach(o => {
      this.iterateTree(o, s, r);
    }), this.stringifiedStoriesCache = {}, delete this.links[r], delete this.relations[r];
  }
  async cacheResponse(e, t, r, s) {
    const i = this.helpers.stringify({
        url: e,
        params: t
      }),
      n = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e !== "/cdn/spaces/me") {
      const o = await n.get(i);
      if (o) return Promise.resolve(o);
    }
    return new Promise(async (o, a) => {
      var h;
      try {
        const u = await this.throttle("get", e, t, s);
        if (u.status !== 200) return a(u);
        let f = {
          data: u.data,
          headers: u.headers
        };
        if ((h = u.headers) != null && h["per-page"] && (f = Object.assign({}, f, {
          perPage: u.headers["per-page"] ? Number.parseInt(u.headers["per-page"]) : 0,
          total: u.headers["per-page"] ? Number.parseInt(u.headers.total) : 0
        })), f.data.story || f.data.stories) {
          const g = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(f.data, t, `${g}`);
        }
        return t.version === "published" && e !== "/cdn/spaces/me" && (await n.set(i, f)), f.data.cv && t.token && p[t.token] !== f.data.cv && (await this.flushCache(), p[t.token] = f.data.cv), o(f);
      } catch (u) {
        if (u.response && u.status === 429 && (r = typeof r > "u" ? 0 : r + 1, r < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, r).then(o).catch(a);
        a(u);
      }
    });
  }
  throttledRequest(e, t, r, s) {
    return this.client.setFetchOptions(s), this.client[e](t, r);
  }
  cacheVersions() {
    return p;
  }
  cacheVersion() {
    return p[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (p[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (p[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(y[e]);
          },
          getAll() {
            return Promise.resolve(y);
          },
          set(e, t) {
            return y[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return y = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
export { v as RichtextResolver, te as RichtextSchema, T as STORYBLOK_AGENT, R as STORYBLOK_JS_CLIENT_AGENT, ne as SbFetch, m as SbHelpers, ae as default };