<section
	[class]="cn('section-spacing section-gap-wide px-0 lg:pb-0')"
	[editableInStoryblok]="_editable"
	data-test-class="storytelling-section"
>
	<div class="px-3 lg:p-0">
		<sb-columns [colOne]="titleWithFigures" component="columnOne" />
	</div>
	<div class="section-gap-wide flex flex-col items-center">
		@for (
			storytelling of storytellings;
			track storytelling;
			let index = $index,
				odd = $odd
		) {
			<ng-container
				[ngTemplateOutlet]="storytellingRef"
				[ngTemplateOutletContext]="{
					extraImage: vpExtraImages[index],
					image: vpImages[index],
					isFullscreen: false,
					storytelling: storytelling,
					position: odd ? 'right' : 'left',
				}"
			/>
		}
		@if (storytellingFullscreen?.length > 0) {
			@for (
				storytelling of storytellingFullscreen;
				track storytelling;
				let index = $index
			) {
				<ng-container
					[ngTemplateOutlet]="storytellingRef"
					[ngTemplateOutletContext]="{
						image: vpImagesFullscreen[index],
						isFullscreen: true,
						storytelling: storytelling,
						position:
							storytellings.length % 2 !== 0 ? 'right' : 'left',
					}"
				/>
			}
		}
	</div>
</section>

<ng-template
	#storytellingRef
	let-extraImage="extraImage"
	let-image="image"
	let-isFullscreen="isFullscreen"
	let-position="position"
	let-storytelling="storytelling"
>
	<vp-storytelling
		[editableInStoryblok]="storytelling._editable"
		[extraImage]="extraImage"
		[image]="image"
		[isFullscreen]="isFullscreen"
		[position]="position"
		[video]="storytelling.video?.[0]"
		class="w-full"
	>
		<header>
			<h3 class="heading-group">
				{{ storytelling.title }}
			</h3>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ storytelling.overline }}
			</p>
		</header>
		@for (blok of storytelling.content; track $index) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
					class="hidden"
				/>
			}
		}
	</vp-storytelling>
</ng-template>
