import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslatePipe } from '@ngx-translate/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [NgClass, TranslatePipe],
	selector: 'vp-read-more',
	templateUrl: './read-more.html',
})
export class ReadMoreComponent {
	@Input() isDisabled = false;

	isOpen = false;

	toggleIsOpen() {
		this.isOpen = !this.isOpen;
	}
}
