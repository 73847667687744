import {
	CommonModule,
	isPlatformBrowser,
	NgOptimizedImage,
} from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	HostListener,
	Inject,
	Input,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';

import {
	ImageAspectRatio,
	ImageInterface,
} from '@valk-nx/components/ui-image/src/lib/image.interface';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { ImageQueryModule } from '@valk-nx/core/lib/pipes/image-query/image-query.module';

@Component({
	selector: 'vp-mini-carousel',
	templateUrl: './mini-carousel.html',
	imports: [
		AngularSvgIconModule,
		CommonModule,
		ImageQueryModule,
		NgOptimizedImage,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	host: { class: 'flex w-full' },
})
export class MiniCarouselComponent implements AfterViewInit {
	@Input() config: Partial<SwiperOptions> = {};
	@Input({ required: true }) items: ImageInterface[] = [];
	@Input({ required: true }) aspectRatio: ImageAspectRatio = '16:9';
	@Input() dynamicHeight = 0;
	@Input() className = '';

	carouselItems: ImageInterface[] = [];
	swiperConfig: SwiperOptions = {
		loop: this.items.length > 1,
		preventClicks: false,
		spaceBetween: 12,
		speed: 500,
	};
	swiper: SwiperContainer | null = null;
	@ViewChild('swiper') swiperRef: ElementRef | undefined;

	isCustomImages: boolean[] = [];
	height = 0;

	constructor(
		public readonly cd: ChangeDetectorRef,
		@Inject(PLATFORM_ID) readonly platformId: string,
	) {
		register();
	}

	ngAfterViewInit() {
		this.isCustomImages = new Array(this.items.length).fill(true);
		this.initializeSwiper();
		this.initializeItems();
	}

	@Debounce(300)
	initializeItems() {
		if (isPlatformBrowser(this.platformId)) {
			const width = this.swiper?.offsetWidth as number;

			setTimeout(() => {
				this.carouselItems = this.items.map((item) => {
					const aspectRatio = this.aspectRatio.split(':');
					this.height =
						this.dynamicHeight ||
						Math.round(
							(width / parseInt(aspectRatio[0], 10)) *
								parseInt(aspectRatio[1], 10),
						);
					return {
						src: item.src,
						altText: item.altText,
						height: this.height,
						width,
					};
				});
				this.cd.detectChanges();
			});
		}
	}

	setImageFallback(index: number) {
		this.isCustomImages[index] = false;

		this.carouselItems[index] = {
			...this.carouselItems[index],
			src: '/assets/images/application-icons/icon-transparent-96x96.png',
		};
	}

	initializeSwiper() {
		this.swiper = this.swiperRef?.nativeElement;

		this.swiperConfig = {
			...this.swiperConfig,
			...this.config,
			keyboard: { enabled: true, onlyInViewport: true },
			navigation: false,
		};

		// istanbul ignore next
		if (this.swiper && isPlatformBrowser(this.platformId)) {
			Object.assign(this.swiper, this.swiperConfig);
			this.swiper.initialize();
		}
	}

	slideNext() {
		this.swiper!.swiper.slideNext(this.swiperConfig.speed);
	}

	slidePrev() {
		this.swiper!.swiper.slidePrev(this.swiperConfig.speed);
	}

	@HostListener('window:resize')
	@Debounce(300)
	setImageWidth() {
		this.initializeItems();
		this.cd.detectChanges();
	}
}
