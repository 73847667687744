import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';
import { Observable } from 'rxjs';

import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { CarouselWithAvailabilityWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-availability-widget/src/lib/carousel-with-availability-widget';
import { CarouselWithBookingWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-booking-widget/src/lib/carousel-with-booking-widget';
import { StoryblokIntroductionSectionComponent } from '@valk-nx/storyblok/components/introduction-section/src/lib/introduction-section';
import { StoryblokItemsSliderComponent } from '@valk-nx/storyblok/components/items-slider-section/src/lib/items-slider-section';
import { StoryblokMasonryComponent } from '@valk-nx/storyblok/components/masonry-section/src/lib/masonry-section';
import { NewsletterComponent as SbNewsletterComponent } from '@valk-nx/storyblok/components/newsletter/src/lib/newsletter';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { StoryblokStaticSectionComponent } from '@valk-nx/storyblok/components/static-section/src/lib/static-section';
import { StoryblokStoryTellingComponent } from '@valk-nx/storyblok/components/storytelling-section/src/lib/storytelling-section';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-reference',
	templateUrl: './reference.component.html',
	imports: [CommonModule, DynamicModule, StoryblokDirective],
})
export class ReferenceComponent
	extends StoryblokRootDirective
	implements OnInit
{
	components: typeof Components = Components;

	@Input({ required: true }) reference!: string[];
	story$!: Observable<ISbStoryData>;

	constructor(private readonly sbService: StoryblokService) {
		super();
	}
	ngOnInit(): void {
		this.story$ = this.sbService.storyByUUID$(this.reference[0]);
	}
}

export const Components = {
	carousel: SbCarouselComponent,
	carouselWithAvailabilityWidget: CarouselWithAvailabilityWidgetComponent,
	carouselWithWidget: CarouselWithBookingWidgetComponent,
	introductionSection: StoryblokIntroductionSectionComponent,
	itemsSliderSection: StoryblokItemsSliderComponent,
	masonrySection: StoryblokMasonryComponent,
	newsletter: SbNewsletterComponent,
	reference: ReferenceComponent,
	section: SectionComponent,
	storytellingSection: StoryblokStoryTellingComponent,
	staticData: StoryblokStaticSectionComponent,
};
