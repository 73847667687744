import { DecimalPipe, NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslatePipe, TranslateService } from '@ngx-translate/core';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

/**
 * Social proof component
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-social-proof',
	imports: [DecimalPipe, NgClass, TranslatePipe],
	templateUrl: './social-proof.html',
})
export class SocialProofComponent extends BaseDirective {
	@Input() numberOfRatings = 0;
	@Input() alignment: 'left' | 'right' = 'left';
	@Input() size: 'sm' | 'base' | 'lg' = 'base';
	@Input() showGradeBackGround = true;
	@Input() set rating(value: number) {
		this.grade = value;

		const score = this.grade * 10;

		if (score >= 88) {
			this.ratingText = 'amazing';
		} else if (score >= 84) {
			this.ratingText = 'fantastic';
		}
	}

	ratingText = 'great';
	grade = 8;

	constructor(public override translate: TranslateService) {
		super(translate);
	}
}
