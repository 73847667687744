import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [CommonModule],
	selector: 'vp-rating-svg',
	templateUrl: './rating-svg.html',
})
export class RatingSVGComponent extends BaseDirective {
	score = input.required<string>();

	constructor(public override translate: TranslateService) {
		super(translate);
	}
}
