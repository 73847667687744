import { Pipe, PipeTransform } from '@angular/core';

import { MenuItem } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Pipe({
	name: 'menuItems',
	pure: true,
	standalone: false,
})
export class MenuItemsPipe implements PipeTransform {
	transform(items: MenuItem[]): {
		text: string;
		url: string;
		target: '' | '_blank' | '_self';
		icon: string;
		title: string;
	}[] {
		if (items.length === 0) return [];
		return items.map((item) => ({
			text: item.title,
			url: item.link.cached_url,
			target: item.link.target || '_self',
			icon: item.icon || 'assets/icons/logo-valk-white.svg',
			title: item.title,
		}));
	}
}
