<div [ngClass]="maxWidth" class="mx-auto">
	<vp-read-more [isDisabled]="!isReadMore">
		<div
			[class]="listStyle"
			[editableInStoryblok]="_editable"
			[innerHTML]="renderedRichText()"
			class="richtext"
		></div>
	</vp-read-more>
</div>
