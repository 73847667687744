import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import dayjs from 'dayjs';
import { Observable, take, tap } from 'rxjs';

import { RibbonColor } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { RouteHelper } from '@valk-nx/router-store/router.helper';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-hotel-by-deal-card`,
	templateUrl: './hotel-by-deal-card.component.html',
	standalone: false,
})
export class HotelByDealCardComponent {
	@Input() isHighlightedHotel = false;
	@Input({ required: true }) hotelName: string;
	@Input({ required: true }) image: PhotoInterface;
	@Input({ required: true }) ribbonText: string;
	@Input({ required: true }) ribbonColor: RibbonColor = 'yellow';
	@Input({ required: true }) usps: string[];
	@Input({ required: true }) price: number;
	@Input({ required: true }) tags: string[];
	@Input({ required: true }) rating: number;
	@Input({ required: true }) numberOfRatings: number;
	@Input() currency = 'EUR';

	@Input() set numberOfStars(numberOfStars: number) {
		this.stars = Array(numberOfStars).fill(1);
	}

	@Input() ctaUrl: string;

	@Input({ required: true }) validFrom: Date;
	@Input({ required: true }) validUntil: Date;

	get isDealAvailable() {
		return !this.isFutureDeal && !this.isPastDeal;
	}

	get isPastDeal() {
		return dayjs().isAfter(this.validUntil);
	}

	get isFutureDeal() {
		return dayjs().isBefore(this.validFrom);
	}

	get disabled() {
		return !this.isDealAvailable || this.price == 0;
	}

	stars = [];
	isMobile$: Observable<boolean>;
	isTablet$: Observable<boolean>;

	constructor(private readonly viewport: ViewPortService) {
		this.isMobile$ = this.viewport.isMobile$;
		this.isTablet$ = this.viewport.isTablet$;
	}

	navigateOnMobileClick() {
		this.isMobile$
			.pipe(
				take(1),
				tap((isMobile) => {
					if (isMobile && this.ctaUrl) {
						RouteHelper.redirectToExternalUrl(this.ctaUrl);
					}
				}),
			)
			.subscribe();
	}
}
