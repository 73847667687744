import {
	AsyncPipe,
	CommonModule,
	isPlatformBrowser,
	isPlatformServer,
} from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	numberAttribute,
	OnInit,
	Output,
	PLATFORM_ID,
} from '@angular/core';

import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import {
	BehaviorSubject,
	debounceTime,
	filter,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';

import { DateRangePickerComponent } from '@valk-nx/components/ui-date-range-picker/src/lib/date-range-picker';
import { DateRangePickerWithStepperComponent } from '@valk-nx/components/ui-date-range-picker-with-stepper/src/lib/date-range-picker-with-stepper';
import { LabelPosition } from '@valk-nx/components/ui-label/src/lib/label.interface';
import { OccupancySelectorComponent } from '@valk-nx/components/ui-occupancy-selector/src/lib/occupancy-selector';
import { OccupancyRestrictions } from '@valk-nx/components/ui-occupancy-selector/src/lib/occupancy-selector.interface';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { RouteHelper } from '@valk-nx/router-store/router.helper';
import {
	AvailabilityDealsRequest,
	AvailabilityDealsResponse,
} from '@valk-nx/services/availability-deal/src/lib/availability-deal.interface';
import { AvailabilityDealService } from '@valk-nx/services/availability-deal/src/lib/availability-deal.service';

export interface WidgetData {
	numRooms: number;
	numNights: number;
	selectedArrivalDate: string;
	selectedDepartureDate: string;
	price: number;
	currency: string;
	rooms: OccupancyInterface[];
}

/*
 * @deprecated use the AvailabilityWidgetComponent for future references of a calendar widget
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-booking-widget`,
	templateUrl: './booking-widget.component.html',
	imports: [
		CommonModule,
		AsyncPipe,
		OccupancySelectorComponent,
		DateRangePickerComponent,
		DateRangePickerWithStepperComponent,
		TranslatePipe,
	],
})
export class BookingWidgetComponent implements OnInit {
	@Input() calendarType: 'stepper' | 'range' = 'stepper';
	@Input({ required: true }) dealGUID: string;
	@Input({ required: true }) hotelGUID: string;
	@Input() maxRooms = 5;
	@Input({ required: true }) defaultAdults = 2;
	@Input({ required: true }) occupancyRestrictions: OccupancyRestrictions = {
		adults: {
			min: 1,
			max: 6,
		},
		children: {
			min: 2,
			max: 3,
		},
		infants: {
			min: 3,
			max: 8,
		},
	};
	@Input() showPrices = true;
	@Input() currency = 'EUR';
	@Input({ required: true }) minNights: number;
	@Input({ required: true }) maxNights: number;
	@Input() maxDate = dayjs().add(1, 'year').toDate();
	@Input() minDate = dayjs().subtract(1, 'day').toDate();
	@Input({ required: true }) bookingtoolUrl: string;
	@Input({ required: true }) validFrom: Date;
	@Input({ required: true }) validUntil: Date;
	@Input({ transform: numberAttribute }) initialNumberOfNights: number;
	@Input({ transform: toDate }) initialArrivalDate: Date;
	@Input() initialOccupancy: OccupancyInterface[];
	@Output() bookingWidgetButtonClicked = new EventEmitter<WidgetData>();

	get isDealAvailable() {
		return !this.isFutureDeal && !this.isPastDeal;
	}

	get isPastDeal() {
		return dayjs().isAfter(this.validUntil);
	}

	get isFutureDeal() {
		return dayjs().isBefore(this.validFrom);
	}

	get unavailableCtaUrl(): string {
		const lang = this.translate.currentLang || this.translate.defaultLang;
		return lang === 'nl' ? '/' : '/' + lang;
	}

	labelPositions = LabelPosition;

	occupancy: OccupancyInterface[];

	updateAvailability$ = new BehaviorSubject<AvailabilityDealsRequest>(
		undefined,
	);
	isLoading = false;
	isServer = false;
	jumpToFirstAvailableDate = true;

	availability$: Observable<AvailabilityDealsResponse>;
	firstDateInCalendar: string = dayjs().format('YYYY-MM-DD');
	isOccupancyOpen = false;
	isDatePickerOpen = false;

	arrivalDate: Date;
	departureDate: Date;
	numberOfNights: number;
	selectedPrice: number;

	occupancyPopoverOffset: [number, number] = [-24, 40];
	datepickerPopoverOffset: [number, number] = [0, 40];

	constructor(
		private readonly availability: AvailabilityDealService,
		private readonly translate: TranslateService,
		@Inject(PLATFORM_ID) readonly platformId: string,
	) {
		this.isServer = isPlatformServer(this.platformId);
		this.availability$ = this.updateAvailability$.pipe(
			debounceTime(300),
			filter((request) => !!request),
			switchMap((request) =>
				this.availability.getDealAvailability(request),
			),
			tap(() => (this.isLoading = false)),
			shareReplay(1),
		);
	}

	ngOnInit() {
		if (this.initialArrivalDate) {
			this.firstDateInCalendar = dayjs(this.initialArrivalDate)
				.startOf('month')
				.format('YYYY-MM-DD');
		}
		this.getAvailability();
	}

	onChooseDatesEmit(event: Event) {
		this.isOccupancyOpen = false;
		this.isDatePickerOpen = true;

		this.getAvailability();

		event.stopPropagation();
	}

	onDatePickerOpened(isOpen: boolean) {
		this.isDatePickerOpen = isOpen;
	}

	onOccupancyOpenEmit(isOpen: boolean) {
		this.isOccupancyOpen = isOpen;
	}

	onOccupancyEmit(occupancy: OccupancyInterface[]) {
		this.occupancy = occupancy;
		this.getAvailability();
	}

	recalculateAvailabilityForToday() {
		this.firstDateInCalendar = dayjs()
			.startOf('month')
			.format('YYYY-MM-DD');
		this.getAvailability();
	}

	onMonthChange(newFirstDate: string) {
		this.firstDateInCalendar = newFirstDate;

		this.jumpToFirstAvailableDate = false;
		this.getAvailability();
	}

	getAvailability() {
		if (isPlatformBrowser(this.platformId)) {
			const request: AvailabilityDealsRequest = {
				dealGUIDs: [this.dealGUID],
				hotelGUIDs: [this.hotelGUID],
				startDate: this.firstDateInCalendar,
				endDate: dayjs(this.firstDateInCalendar)
					.add(2, 'month')
					.format('YYYY-MM-DD'),
				occupancies: this.occupancy,
			};
			if (this.calendarType === 'stepper' && this.numberOfNights) {
				request.nights = this.numberOfNights;
			}
			this.isLoading = true;
			this.updateAvailability$.next(request);
		}
	}

	updateModel(event) {
		this.arrivalDate = event.arrivalDate;
		this.departureDate = event.departureDate;
		this.numberOfNights = event.numberOfNights;
		this.selectedPrice = event.price;
	}

	goToBookingtool() {
		if (this.bookingtoolUrl) {
			this.emitWidgetData();

			const arrivalString = this.arrivalDate
				? `&arrival=${dayjs(this.arrivalDate).format('YYYY-MM-DD')}`
				: '';
			const departureString = this.departureDate
				? `&departure=${dayjs(this.departureDate).format('YYYY-MM-DD')}`
				: '';
			const occupancyString = this.occupancy
				? `&occupancy=${JSON.stringify(this.occupancy)}`
				: '';
			const url = `${this.bookingtoolUrl}/?dealGuid=${this.dealGUID}${arrivalString}${departureString}${occupancyString}`;
			RouteHelper.redirectToExternalUrl(url);
		}
	}

	emitWidgetData() {
		const widgetData: WidgetData = {
			numRooms: this.occupancy?.length | 0,
			numNights: this.numberOfNights,
			selectedArrivalDate: dayjs(this.arrivalDate).format('YYYY-MM-DD'),
			selectedDepartureDate: dayjs(this.departureDate).format(
				'YYYY-MM-DD',
			),
			price: this.selectedPrice,
			currency: this.currency,
			rooms: this.occupancy,
		};
		this.bookingWidgetButtonClicked.emit(widgetData);
	}
}
