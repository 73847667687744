<div class="relative mb-3" data-test-class="items-slider">
	<ng-container [ngTemplateOutlet]="sliderNav" />
	<div [id]="'items-slider-' + uid()">
		<swiper-container #swiper class="relative flex flex-row" init="false">
			@for (item of items(); track item; let i = $index) {
				<swiper-slide lazy="true">
					<vp-item-slide [item]="item" />
				</swiper-slide>
			}
		</swiper-container>
	</div>
	<ng-container
		[ngTemplateOutlet]="sliderNav"
		[ngTemplateOutletContext]="{ isRight: true }"
	/>
	<div
		class="pagination absolute -bottom-6 left-1/2 flex -translate-x-1/2 flex-row items-center gap-2 justify-self-center"
		data-test-id="pagination"
	></div>
</div>
<ng-template #sliderNav let-isRight="isRight">
	<div
		[ngClass]="{
			'lg:flex': items().length > 3,
			'slide-prev -left-8': !isRight,
			'slide-next -right-8': isRight,
		}"
		class="absolute top-1/2 z-10 hidden size-16 -translate-y-1/2 cursor-default rounded-full bg-white"
	></div>
	<button
		[attr.aria-label]="isRight ? 'Next slide' : 'Previous slide'"
		[attr.data-test-id]="isRight ? 'slide-next' : 'slide-prev'"
		[ngClass]="{
			'lg:flex': items().length > 3,
			'slide-prev -left-6': !isRight,
			'slide-next -right-6': isRight,
		}"
		class="absolute top-1/2 z-10 hidden size-12 -translate-y-1/2 items-center justify-center rounded-full bg-primary-100 hover:bg-primary-125 active:bg-primary-150 disabled:cursor-not-allowed disabled:bg-primary-100 disabled:opacity-50"
		type="button"
	>
		<svg-icon
			[src]="
				isRight
					? 'assets/icons/icon-chevron-right.svg'
					: 'assets/icons/icon-chevron-left.svg'
			"
			class="block size-5 text-white"
		/>
	</button>
</ng-template>
