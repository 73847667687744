import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of } from 'rxjs';

import { AlertPosition } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';
import { AlertService } from '@valk-nx/components/ui-alert/src/lib/services/alert.service';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
import { StoreHelper } from '@valk-nx/helpers/lib/store/store.helper';

import { AvailabilityDatesInterface } from './availability-dates.interface';

//Env variable
export const AVAILABILITY_DATES_SERVICE_URL = new InjectionToken<string[]>(
	'availability-dates_service-url',
);

export const AVAILABILITY_DATES_API_COOKIES = new InjectionToken<string[]>(
	'availability-dates_api-cookies',
);

@Injectable({ providedIn: 'root' })
export class AvailabilityDatesService {
	constructor(
		private readonly alertService: AlertService,
		private readonly translate: TranslateService,
		private readonly http: HttpClient,
		@Inject(AVAILABILITY_DATES_SERVICE_URL)
		private readonly availabilityUrl: string,
		@Inject(AVAILABILITY_DATES_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	postAvailabilityDates(
		hotel: string,
		endDate: string,
		startDate: string,
		occupancies: { adults: number; children: number; infants: number }[],
		accommodationTypes: string[],
		dealGUID: string,
		packageGUID: string,
	) {
		const dealGUIDs = dealGUID ? { dealGUIDs: [dealGUID] } : null;
		const packageGUIDs = packageGUID
			? { packageGUIDs: [packageGUID] }
			: null;

		const headers = new HttpHeaders();
		const body = {
			accommodationTypes,
			endDate,
			hotel,
			occupancies,
			startDate,
			...dealGUIDs,
			...packageGUIDs,
		};
		return this.http.post<HALResponse<AvailabilityDatesInterface>>(
			`${this.availabilityUrl}availability/dates`,
			body,
			{ ...this.apiCookies, headers, observe: 'response' },
		);
	}

	getAvailabilityDates(
		hotelGUID: string,
		endDate: string,
		startDate: string,
		occupancies: { adults: number; children: number; infants: number }[],
		accommodationTypes: string[],
		dealGUID: string,
		packageGUID: string,
		roomGUID: string,
	) {
		const headers = new HttpHeaders();

		const qParams = StoreHelper.createQueryParamString({
			accommodationTypes,
			endDate,
			hotelGUID,
			occupancies,
			startDate,
			dealGUID,
			packageGUID,
			roomGUID,
		});
		return this.http
			.get<HALResponse<AvailabilityDatesInterface>>(
				`${this.availabilityUrl}availability/dates${qParams}`,
				{
					...this.apiCookies,
					headers,
					observe: 'body',
					responseType: 'json',
				},
			)
			.pipe(
				map((response) => response.data),
				catchError(() => {
					const content = this.translate.instant(
						'availability-widget.retrieve-availability-error',
					);
					this.alertService.error({
						closeable: true,
						duration: 5000,
						emphasized: false,
						hasElevation: true,
						position: AlertPosition.TopCenter,
						content,
					});
					return of({});
				}),
			);
	}
}
