import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Inject,
	inject,
	Injectable,
	PLATFORM_ID,
	Provider,
} from '@angular/core';

import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import {
	AVAILABILITY_DATES_API_COOKIES,
	AVAILABILITY_DATES_SERVICE_URL,
} from '@valk-nx/availability-dates-store/availability-dates.service';
import { environment } from '@valk-nx/content/environments/environment';
import {
	ASSETS_ROOT,
	fallbackLanguage,
	HOTEL_SLUG,
	LANGUAGE,
	MAPS_SERVICE_KEY,
	RECAPTCHA_V2_SITE_KEY,
} from '@valk-nx/core/lib/core';
import {
	HOTEL_API_COOKIES,
	HOTEL_SERVICE_URL,
} from '@valk-nx/hotel-store/hotels.service';
import {
	POSTALCODE_API_COOKIES,
	POSTALCODE_SERVICE_URL,
} from '@valk-nx/postal-code-store/lib/postal-code.service';
import {
	AVAILABILITY_DEAL_API_COOKIES,
	AVAILABILITY_DEAL_SERVICE_URL,
} from '@valk-nx/services/availability-deal/src/lib/availability-deal.service';
import {
	FORM_API_COOKIES,
	FORM_SERVICE_URL,
} from '@valk-nx/services/form/src/lib/form.service';
import { GTM_CODE } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';
import {
	NEWSLETTER_API_COOKIES,
	NEWSLETTER_SUBSCRIBE_SERVICE_URL,
} from '@valk-nx/services/newsletter/src/lib/newsletter.service';
import { SHOW_COOKIE_BANNER } from '@valk-nx/storyblok/components/wrapper/src/lib/wrapper.component';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import {
	CONTENT_API_COOKIES,
	CONTENT_SERVICE_URL,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import {
	GIFTCARD_API_COOKIES,
	GIFTCARD_SERVICE_URL,
	POLLING_TIME,
} from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { HOST } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import {
	STORYBLOK_ACCESS_TOKEN,
	STORYBLOK_ENDPOINT,
	USE_TRANSFER_STATE,
} from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

import { APP_CONFIG, Config } from './config';

export function provideConfiguration(): Provider {
	const getApiCookies = () => {
		return inject(ConfigService)?.host.includes('localhost')
			? environment.apiCookies
			: false;
	};
	const getApiUrl = () => {
		const nonLocalApiUrl = isPlatformBrowser(inject(PLATFORM_ID))
			? '/api/v1/'
			: `${inject(ConfigService).host}/api/v1/`;

		return inject(ConfigService)?.host.includes('localhost')
			? environment.apiUrl
			: nonLocalApiUrl;
	};

	return [
		ConfigService,
		{
			provide: STORYBLOK_ACCESS_TOKEN,
			useFactory: () => {
				return inject(ConfigService).storyblokAccessToken;
			},
		},
		{
			provide: STORYBLOK_ENDPOINT,
			useFactory: () => {
				return inject(ConfigService).storyblokEndpoint;
			},
		},
		{
			provide: USE_TRANSFER_STATE,
			useFactory: () => environment.useTransferState,
		},
		{
			provide: ASSETS_ROOT,
			useFactory: () => {
				return '/assets';
			},
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: () => {
				return inject(ConfigService).recaptchaKey;
			},
		},
		{
			provide: RECAPTCHA_V2_SITE_KEY,
			useFactory: () => {
				return inject(ConfigService).recaptchaV2Key;
			},
		},
		{
			provide: GTM_CODE,
			useFactory: () => {
				return inject(ConfigService).gtmCode;
			},
		},
		{
			provide: AVAILABILITY_DATES_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: AVAILABILITY_DATES_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: AVAILABILITY_DEAL_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: AVAILABILITY_DEAL_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: CONTENT_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: CONTENT_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: FORM_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: FORM_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: GIFTCARD_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: GIFTCARD_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: HOTEL_SLUG,
			useFactory: () => {
				return inject(ConfigService).hotelSlug;
			},
		},
		{
			provide: HOTEL_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: HOTEL_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: HOST,
			useFactory: () => {
				return inject(ConfigService).host;
			},
		},
		{
			provide: LANGUAGE,
			useFactory: () => {
				return inject(ConfigService).language;
			},
		},
		{
			provide: MAPS_SERVICE_KEY,
			useFactory: () => {
				return inject(ConfigService).mapsKey;
			},
		},
		{
			provide: BOOKINGTOOL,
			useFactory: () => {
				return inject(ConfigService).bookingtool;
			},
		},
		{
			provide: NEWSLETTER_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: POSTALCODE_API_COOKIES,
			useFactory: () => getApiCookies(),
		},
		{
			provide: POSTALCODE_SERVICE_URL,
			useFactory: () => getApiUrl(),
		},
		{
			provide: NEWSLETTER_SUBSCRIBE_SERVICE_URL,
			useFactory: () => getApiUrl() + 'newsletter/subscribe',
		},
		{
			provide: POLLING_TIME,
			useFactory: () => {
				return inject(ConfigService).pollingTime;
			},
		},
		{
			provide: SHOW_COOKIE_BANNER,
			useFactory: () => {
				return environment.production;
			},
		},
	];
}

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	constructor(
		@Inject(APP_CONFIG) private readonly config: Config,
		@Inject(DOCUMENT) private readonly document: Document,
	) {
		this.setDocumentTheme();
	}

	setDocumentTheme() {
		if (this.config.theme) {
			this.document.documentElement.classList.add(this.config.theme);
		}
	}

	get apiCookies() {
		return environment.apiCookies;
	}

	get bookingtool() {
		return this.config.bookingToolUrl;
	}

	get environment() {
		return this.config.environment;
	}

	get gtmCode() {
		return this.config.gtmCode;
	}

	get host() {
		return this.config.host;
	}

	get hotelSlug() {
		return this.config.hotelSlug;
	}

	get language() {
		return this.config.defaultLanguage?.toLowerCase() || fallbackLanguage;
	}

	get mapsKey() {
		return this.config.mapsKey;
	}

	get pollingTime() {
		return this.config.pollingTime;
	}

	get recaptchaKey() {
		return this.config.recaptchaKey;
	}

	get recaptchaV2Key() {
		return this.config.recaptchaV2Key;
	}

	get storyblokAccessToken() {
		return this.config.storyblokAccessToken;
	}

	get storyblokEndpoint() {
		return this.config.storyblokEndpoint;
	}
}
