<div class="mx-auto max-w-screen-md">
	<vp-sheet
		[isLoading]="false"
		[sheetWidth]="'third'"
		[triggerId]="'sb-sheet-trigger-' + _uid"
	>
		<div #sheetTrigger>
			{{ description }}
			<button
				[id]="'sb-sheet-trigger-' + _uid"
				class="text-link inline-block cursor-pointer"
				data-test-id="sb-sheet-trigger"
				vp-sheet-trigger
			>
				{{ linkText }}
			</button>
		</div>
		<vp-sheet-header>
			<h2 data-test-id="sb-sheet-content-title">{{ sheetTitle }}</h2>
		</vp-sheet-header>
		<vp-sheet-content
			[id]="'sb-sheet-content-' + _uid"
			class="markdown"
			data-test-id="sb-sheet-content"
			tabindex="0"
		>
			<sb-richtext [content]="sheetContent" />
		</vp-sheet-content>
	</vp-sheet>
</div>
