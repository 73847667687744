import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnChanges,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { TooltipModule } from '@valk-nx/components/ui-tooltip/src';
import { ReceiptSummaryExtraComponent } from '@valk-nx/compositions/ui-receipt-summary/src/lib/components/extra/extra.component';
import {
	Item,
	ItemWithExtras,
} from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.interface';
import { isItemWithExtras } from '@valk-nx/compositions/ui-receipt-summary/src/lib/type-guards/type-guards';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';
import { PricePipe } from '@valk-nx/core/lib/pipes/price/price.pipe';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

@Component({
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [
		AngularSvgIconModule,
		CommonModule,
		SharedModule,
		TooltipModule,
		ReceiptSummaryExtraComponent,
		PricePipe,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	selector: `vp-receipt-summary-item`,
	templateUrl: './item.component.html',
})
export class ReceiptSummaryItemComponent implements OnChanges {
	@Input({ required: true }) item: Item | ItemWithExtras;
	@Input({ required: true }) currency: string;
	@Input({ required: true }) index: number;
	@Input({ required: true }) totalNumberOfItems: number;
	@Input({ required: true }) someItemHasExtras: boolean;

	itemWithExtras?: ItemWithExtras;

	ngOnChanges() {
		if (isItemWithExtras(this.item)) {
			this.itemWithExtras = this.item;
		}
	}
}
