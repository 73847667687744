import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Inject,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { delay, Subject, takeUntil } from 'rxjs';

import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { Language, RECAPTCHA_V2_SITE_KEY } from '@valk-nx/core/lib/core';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { GiftcardService } from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

import { form as formFields } from './activate-giftcard-form';

@Component({
	selector: 'sb-activate-giftcard',
	templateUrl: './activate-giftcard.html',
	imports: [
		AlertComponent,
		CommonModule,
		StoryblokDirective,
		TranslatePipe,
		RecaptchaNotificationComponent,
		SharedFormlyModule,
	],
	providers: [TagManagerFacade],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActivateGiftcardComponent
	extends StoryblokRootDirective
	implements AfterViewInit
{
	@ViewChild('activateForm') activateForm!: NgForm;

	formFields = formFields;
	form = new FormGroup<{
		cardNumber: FormControl;
		activationCode: FormControl;
	}>({
		cardNumber: new FormControl(''),
		activationCode: new FormControl(''),
	});

	activationSuccess = false;

	giftcardSubmitted = false;
	giftcardSubmitting = false;
	destroyQueryParams$ = new Subject<void>();

	language: Language;
	recaptchaVersion: 'v2' | 'v3' = 'v3';

	get showSummarySuccess() {
		return (
			this.giftcardSubmitted &&
			this.activationSuccess &&
			this.form.value.cardNumber
		);
	}

	get showSummaryWarning() {
		return (
			this.giftcardSubmitted &&
			!this.activationSuccess &&
			this.form.value.cardNumber &&
			this.form.value.activationCode
		);
	}

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly giftcardService: GiftcardService,
		private readonly translate: TranslateService,
		private readonly tagManagerFacade: TagManagerFacade,
		@Inject(RECAPTCHA_V2_SITE_KEY) readonly recaptchaV2Key: string,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;
	}

	ngAfterViewInit(): void {
		const cardNumber = this.form.controls['cardNumber'].value;
		if (!cardNumber) {
			this.activatedRoute.queryParams
				.pipe(takeUntil(this.destroyQueryParams$), delay(0))
				.subscribe((params) => {
					const { cardnumber } = params;
					this.form.setValue({
						cardNumber: cardnumber || '',
						activationCode: '',
					});
					if (cardnumber) {
						this.destroyQueryParams$.next();
					}
				});
		}
	}

	onSubmit(activateRequest: {
		cardNumber: string;
		activationCode: string;
	}): Promise<boolean | null> {
		this.giftcardSubmitting = true;
		this.tagManagerFacade.formSubmit('giftcard_activation');
		return this.giftcardService
			.activateCard(
				activateRequest.cardNumber,
				activateRequest.activationCode,
				this.form.get('recaptcha')?.value,
			)
			.then((success) => {
				this.giftcardSubmitted = true;
				this.giftcardSubmitting = false;
				this.activationSuccess = success;
				return success;
			})
			.catch((error: Error) => {
				const status = parseInt(error.message, 10);
				this.giftcardSubmitted =
					status < 500 && status !== 0 && status !== 403;
				this.giftcardSubmitting = false;
				this.useRecaptchaV2(status);
				return null;
			});
	}

	resetGiftcardForm(): void {
		this.activationSuccess = false;
		this.giftcardSubmitted = false;

		this.form.reset();
		this.activateForm.resetForm();
	}

	changeGiftCardForm(): void {
		this.giftcardSubmitted = false;
	}

	useRecaptchaV2(status: number | undefined) {
		if (
			status === 403 &&
			this.recaptchaVersion === 'v3' &&
			this.recaptchaV2Key
		) {
			this.recaptchaVersion = 'v2';
			this.formFields = [
				...this.formFields,
				{
					id: 'recaptcha',
					key: 'recaptcha',
					props: {
						errorMode: 'handled',
						required: true,
						siteKey: this.recaptchaV2Key,
						size: 'default',
					},
					type: 'recaptchaV2',
				},
			];
		}
	}
}
