<div class="mx-auto max-w-screen-md">
	<div
		[attr.data-test-id]="subject?.trim()?.toLowerCase()"
		[editableInStoryblok]="_editable"
		class="card card-form"
	>
		@if (requestSubmitted) {
			<vp-alert
				[content]="successContent"
				[emphasized]="true"
				[title]="successTitle"
				data-test-id="alert"
				type="dialog--success"
			/>
		}
		<form
			#formRef="ngForm"
			[formGroup]="formGroup"
			[ngClass]="{
				hidden: requestSubmitted,
			}"
			(ngSubmit)="onSubmit()"
		>
			<formly-form
				[fields]="formFields"
				[form]="formGroup"
				[model]="model"
				[options]="options"
			/>
			<button
				[ngClass]="{
					'btn-is-loading': requestSubmitting,
				}"
				class="btn btn-primary mt-6 w-full md:mt-8"
				data-test-id="request-button"
				type="submit"
			>
				{{ buttonText }}
			</button>
			<div class="mt-3 md:mt-4">
				<vp-recaptcha-notification [currentLanguage]="language" />
			</div>
		</form>
		@if (requestSubmitted) {
			<div class="mt-4 md:mt-6">
				@for (key of parsedFormData; track key) {
					<span class="block font-bold"> {{ key.label }} </span>
					<span class="block whitespace-pre-line">
						{{ key.value }}
					</span>
				}
				<button
					(click)="resetForm()"
					class="text-link mt-6 md:mt-8"
					data-test-id="summary-action"
				>
					{{ 'storyblok.giftcard.send-another-request' | translate }}
				</button>
			</div>
		}
	</div>
</div>
