import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { firstValueFrom } from 'rxjs';

import { AlertService } from '@valk-nx/components/ui-alert/src/lib/services/alert.service';
import { NewsletterComponent as UINewsletter } from '@valk-nx/components/ui-newsletter/src/lib/newsletter';
import { NewsletterService } from '@valk-nx/services/newsletter/src/lib/newsletter.service';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { alertErrorSettings } from '@valk-nx/storyblok-services/src/lib/globals';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

@Component({
	selector: 'sb-newsletter',
	templateUrl: './newsletter.html',
	imports: [CommonModule, UINewsletter, MarkdownModule, StoryblokDirective],
})
export class NewsletterComponent extends StoryblokRootDirective {
	@Input({ required: true }) title!: string;
	@Input() overline = '';

	newsletterSubmitting = false;
	newsletterSubmitError = true;

	constructor(
		private readonly translate: TranslateService,
		private readonly newsletterService: NewsletterService,
		private readonly alertService: AlertService,
		private readonly tagManagerFacade: TagManagerFacade,
	) {
		super();
	}

	public onSubmit(
		emailAddress: string,
	): Promise<{ data: Record<string, never> } | undefined> {
		this.newsletterSubmitting = true;

		return firstValueFrom(
			this.newsletterService.newsletterSubscribe(emailAddress),
		)
			.then((result) => {
				this.tagManagerFacade.newsletterSubmit(emailAddress);
				this.newsletterSubmitting = false;
				this.newsletterSubmitError = false;
				return result;
			})
			.catch(() => {
				this.alertService.error({
					...alertErrorSettings,
					content: this.translate.instant('global.service.error'),
				});
				this.newsletterSubmitting = false;
				this.newsletterSubmitError = true;
				return undefined;
			});
	}
}
