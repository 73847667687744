import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface PageSettingsInterface {
	hideMenu: boolean;
	hideFooter: boolean;
	negativeHeader: boolean;
}

const initialState: PageSettingsInterface = {
	hideMenu: false,
	hideFooter: false,
	negativeHeader: false,
};

export const PageSettingsStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),

	withMethods((store) => ({
		updateState(data: Partial<PageSettingsInterface>): void {
			patchState(store, (state) => ({ ...state, ...data }));
		},
	})),
);
