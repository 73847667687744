import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { RibbonComponent } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';
import { ImageGridComponent } from '@valk-nx/compositions/ui-image-grid/src/lib/image-grid.component';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';

import { DealCardComponent } from './deal-card.component';

@NgModule({
	declarations: [DealCardComponent],
	exports: [DealCardComponent],
	imports: [
		AngularSvgIconModule.forRoot(),
		CommonModule,
		MarkdownModule.forRoot({}),
		PricingComponent,
		ImageComponent,
		RibbonComponent,
		SharedModule,
		TagComponent,
		ImageGridComponent,
		TranslateDirective,
		TranslatePipe,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DealCardModule {}
