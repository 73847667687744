<div
	[ngClass]="
		cn(
			extraImage?.src &&
				!isFullscreen &&
				!video &&
				position === 'left' &&
				'lg:mt-10 xl:mt-16',
			extraImage?.src &&
				!isFullscreen &&
				!video &&
				position === 'right' &&
				'lg:mb-24 xl:mb-32',
			!isFullscreen && 'section-inner-loose'
		)
	"
	class="relative"
	data-test-class="storytelling"
>
	@if (extraImage?.src && !isFullscreen && !video) {
		<vp-image
			[image]="{
				src: extraImage.src,
				width: 192,
				height: 192,
			}"
			[ngClass]="
				cn(
					position === 'left' && 'lg:-top-24 lg:right-24 xl:-top-32',
					position === 'right' &&
						'lg:-bottom-24 lg:left-24 xl:-bottom-32'
				)
			"
			[sources]="[
				{ min: sizes.lg, max: sizes.xl, width: 192, height: 192 },
				{ min: sizes.xl, max: null, width: 256, height: 256 },
			]"
			class="absolute z-20 hidden lg:block"
		/>
	}
	<div
		[ngClass]="
			cn(
				position === 'left' && !isFullscreen && 'lg:mr-8',
				position === 'right' && !isFullscreen && 'lg:ml-8',
				isFullscreen && 'justify-center lg:min-h-[40rem]',
				!isFullscreen && 'lg:min-h-[32rem] lg:flex-row xl:min-h-[36rem]'
			)
		"
		class="relative flex h-full flex-col"
	>
		<div
			[class]="
				cn(
					position === 'left' && !isFullscreen && 'ml-auto',
					!isFullscreen && 'lg:max-w-[calc(100%-288px)]',
					video && 'max-w-screen w-full overflow-hidden'
				)
			"
			class="relative inset-0 lg:absolute"
		>
			@if (image && image.src !== '') {
				<vp-image
					[className]="
						cn(
							'aspect-square size-full object-cover leading-[0] md:aspect-video'
						)
					"
					[image]="{
						src: image.src,
						width: 0,
						height: 0,
					}"
					[sources]="[
						{ min: null, max: sizes.sm, width: 640, height: 640 },
						{
							min: sizes.sm,
							max: sizes.md,
							width: 768,
							height: 768,
						},
						{
							min: sizes.md,
							max: sizes.lg,
							width: 1024,
							height: 800,
						},
						{
							min: sizes.lg,
							max: sizes.xl,
							width: 1280,
							height: 1000,
						},
						{
							min: sizes.xl,
							max: sizes.xxl,
							width: 1536,
							height: 1200,
						},
						{
							min: sizes.xxl,
							max: null,
							width: 5120,
							height: 4000,
						},
					]"
				/>
			} @else if (video) {
				<vp-video
					[ngClass]="{
						'lg:absolute lg:top-1/2 lg:-translate-y-1/2':
							isFullscreen,
					}"
					[showControls]="video.showControls"
					[vimeoId]="+video.vimeoId"
					class="block h-[26rem] w-[46rem] -translate-x-[17%] sm:h-[46rem] sm:w-[81.5rem] md:h-auto md:w-full md:translate-x-0"
				/>
			}
		</div>
		<div
			[ngClass]="
				cn(
					position === 'left' && 'items-end justify-start',
					position === 'right' && 'items-start justify-end',
					!isFullscreen && 'lg:mx-8 lg:p-0',
					isFullscreen &&
						'section-inner mx-auto items-center lg:mx-8 lg:px-0 lg:py-0 xl:mx-auto xl:px-8'
				)
			"
			class="relative z-10 -mt-16 flex w-full px-3 lg:my-16"
		>
			<div
				class="card card-lg flex h-fit max-w-3xl flex-col gap-4 lg:card-xl lg:max-w-lg"
			>
				<ng-content />
			</div>
		</div>
	</div>
</div>
