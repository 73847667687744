import buildInfo from '@valk-nx/build-info.json';

const clusterBffUrl = () => {
	const API_URL = 'api/v1/';
	const CLUSTER_BFF_VERSION = 'v1';

	if (typeof process !== 'undefined') {
		const APP_ENV = process.env['APP_ENV'] || null;

		if (APP_ENV) {
			if (APP_ENV.startsWith('UAT')) {
				return `https://k8s-development.vandervalkonline.com/content-bff/${APP_ENV.toLocaleLowerCase()}/${CLUSTER_BFF_VERSION}/`;
			} else {
				return `https://k8s.vandervalkonline.com/content-bff/${CLUSTER_BFF_VERSION}/`;
			}
		}
	}

	return API_URL;
};

/* istanbul ignore next */
export const environment = {
	apiCookies: false,
	apiUrl: clusterBffUrl(),
	name: 'valk-nx-content',
	production: false,
	useTransferState: true,
	version: buildInfo?.version || 'test',
};

export const storeVersion = '1';
