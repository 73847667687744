import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	input,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';

import Player from '@vimeo/player';

@Component({
	selector: 'vp-video',
	template: `<div
		#playerContainer
		aria-label="video player"
		data-test-class="video-player"
	></div>`,
})
export class VideoComponent implements AfterViewInit {
	vimeoId = input.required<number>();
	showControls = input<boolean>(false);

	@ViewChild('playerContainer', { static: true })
	playerContainer!: ElementRef;

	player!: Player;

	constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.vimeoId()) {
				console.error('VimeoPlayerComponent: vimeoId is required');
				return;
			}

			this.player = new Player(this.playerContainer.nativeElement, {
				id: this.vimeoId(),
				airplay: false,
				background: !this.showControls(),
				byline: false,
				cc: false,
				chromecast: false,
				pip: false,
				portrait: false,
				quality_selector: false,
				responsive: true,
				speed: false,
				title: false,
				transcript: false,
				vimeo_logo: false,
			} as Partial<Player.Options>);
		}
	}
}
