import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Placement } from '@popperjs/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Observable } from 'rxjs';

import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { DropdownOption } from '@valk-nx/components/ui-dropdown/src/lib/dropdown.interface';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import {
	IconPosition,
	InputInterface,
} from '@valk-nx/components/ui-input/src/lib/input.interface';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

import { OccupancyRestrictions, Range } from './occupancy-selector.interface';

type OccupancyTypes = 'adults' | 'children' | 'infants';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	imports: [
		AngularSvgIconModule,
		CommonModule,
		ClickedModule,
		DropdownComponent,
		InputComponent,
		LabelComponent,
		LetDirective,
		PopoverModule,
		TranslatePipe,
	],
	selector: `vp-occupancy-selector`,
	templateUrl: './occupancy-selector.html',
})
export class OccupancySelectorComponent implements OnInit {
	@Input() isLoading = false;
	@Input() isPopoverOpen = false;
	@Input() popoverAlignment: Placement = 'bottom-start';
	@Input() boundaryTargetId = '';
	@Input() defaultAdults = 2;
	@Input() popoverOffset: [number, number] = [0, 0];
	@Input() initialOccupancy: OccupancyInterface[] = [
		{ adults: 2, children: 0, infants: 0 },
	];
	@Input()
	set occupancyRestrictions(occupancyRestrictions: OccupancyRestrictions) {
		this.selectableData = {
			adults: this.generateOccupancyList(occupancyRestrictions.adults),
			children: this.generateOccupancyList(
				occupancyRestrictions.children,
			),
			infants: this.generateOccupancyList(occupancyRestrictions.infants),
		};
		this._occupancyRestrictions = occupancyRestrictions;
	}
	get occupancyRestrictions(): OccupancyRestrictions {
		return this._occupancyRestrictions;
	}

	@Input() maxRooms = 5;

	@Output() occupancyChanged = new EventEmitter<OccupancyInterface[]>();
	@Output() emitChooseDates = new EventEmitter<Event>();
	@Output() emitIsPopoverOpen = new EventEmitter<boolean>();

	private _occupancyRestrictions: OccupancyRestrictions = {
		adults: {
			min: 1,
			max: 2,
		},
		children: {
			min: 0,
			max: 2,
		},
		infants: {
			min: 0,
			max: 2,
		},
	};

	selectableData: Record<OccupancyTypes, DropdownOption[]> = {
		adults: [],
		children: [],
		infants: [],
	};

	excludeFromClosingRegex: RegExp | string = /ng-option/;
	occupancy: OccupancyInterface[] = [];
	masterInputConfig: InputInterface = {
		autocomplete: 'off',
		defaultStyle: true,
		disabled: false,
		hasError: false,
		icon: '',
		iconPosition: IconPosition.Left,
		initialValue: '',
		isValid: false,
		name: 'occupancy-selector',
		placeholder: '',
		readonly: true,
		required: false,
		type: 'text',
	};

	isSmallTablet$: Observable<boolean>;

	constructor(
		private readonly translate: TranslateService,
		private readonly viewport: ViewPortService,
	) {
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
	}

	ngOnInit(): void {
		if (
			this.initialOccupancy &&
			this.initialOccupancy.length <= this.maxRooms
		) {
			const { adults, children, infants } = this._occupancyRestrictions;
			this.occupancy = this.initialOccupancy.map((occupancy) => {
				return {
					adults: this.limitOccupancy(
						occupancy.adults,
						adults,
						this.defaultAdults,
					),
					children: this.limitOccupancy(
						occupancy.children,
						children,
						children.min,
					),
					infants: this.limitOccupancy(
						occupancy.infants,
						infants,
						infants.min,
					),
				};
			});
		} else {
			this.occupancy = [this.createRoomOccupancy()];
		}
		this.occupancyChanged.emit(this.occupancy);
	}

	private limitOccupancy(
		numOccupants: number,
		occupancyRange: Range,
		defaultValue: number,
	) {
		return numOccupants >= occupancyRange.min &&
			numOccupants <= occupancyRange.max
			? numOccupants
			: defaultValue;
	}

	generateOccupancyList(range: Range): DropdownOption[] {
		return Array.from({ length: range.max - range.min + 1 }, (_, index) => {
			const value = (range.min + index).toString();
			return {
				value,
				label: value,
				selectLabel: value,
			};
		});
	}

	onAddRoom(event: Event) {
		this.occupancy.push(this.createRoomOccupancy());
		this.occupancyChanged.emit(this.occupancy);
		event.stopPropagation();
	}

	onRemoveRoom(event: Event, index: number) {
		this.occupancy = [
			...this.occupancy.slice(0, index),
			...this.occupancy.slice(index + 1),
		];
		this.occupancyChanged.emit(this.occupancy);
		event.stopPropagation();
	}

	popoverStateChanged(isOpen: boolean) {
		this.isPopoverOpen = isOpen;
		this.emitIsPopoverOpen.emit(isOpen);
	}

	selectDropdownValue(value: string, type: OccupancyTypes, index: number) {
		this.occupancy[index][type] = +value;
		this.occupancyChanged.emit(this.occupancy);
	}

	get roomLabel(): string {
		const translationKey =
			this.occupancy.length === 1 ? 'global.room' : 'global.rooms';

		return `${this.occupancy.length} ${this.translate.instant(
			translationKey,
		)}`;
	}

	get personLabel(): string {
		const sum = (totalSoFar: number, value: number) => totalSoFar + value;

		const amountOfPersons = this.occupancy
			.map((room) => Object.values(room).reduce(sum, 0))
			.reduce(sum, 0);

		const translationKey =
			amountOfPersons === 1 ? 'global.person' : 'global.persons';

		return `${amountOfPersons} ${this.translate.instant(translationKey)}`;
	}

	private createRoomOccupancy(): OccupancyInterface {
		const {
			defaultAdults,
			occupancyRestrictions: { adults, children, infants },
		} = this;

		const limit = (value: number, min: number, max: number): number =>
			Math.min(Math.max(value, min), max);
		return {
			adults: limit(defaultAdults, adults.min, adults.max),
			children: children.min,
			infants: infants.min,
		};
	}
}
